import React from 'react';
import * as i18n from 'i18next';
import { Button, Col, Container } from 'reactstrap';
import navigationMethods from '../../utils/navigationUtils';
import {
  ALLOWED_SPECIALISTS_PER_USER,
  LANGUAGE_CODE_KEY,
  RELATIVE_PAGE_PATHS,
} from '../../utils/constants';
import { connect } from 'react-redux';
import MenuItem from '../common/MenuItem';
import { checkForNewVersion, getSpecialists } from '../../actions';
import { isFamilyPhysician } from '../../utils/commonUtils';
import { Link } from '@mui/material';
import { Box } from '@mui/system';

class SpecialistsScreen extends React.Component {
  componentDidMount() {
    this.props.getSpecialists();
    this.props.checkForNewVersion();
  }

  renderSpecialists = () => {
    const specialists = [...this.props.specialists];
    if (specialists[0] && specialists[0].isFamilyPhysician) {
      specialists.shift();
    }
    return specialists.map((specialist, index) => {
      return (
        <MenuItem
          onClick={() => {
            navigationMethods.navigate(
              RELATIVE_PAGE_PATHS.SPECIALISTS + specialist.id
            );
          }}
          title={specialist.name}
          key={index}
        />
      );
    });
  };

  renderGP = () => {
    const specialists = [...this.props.specialists];
    if (specialists[0] && specialists[0].isFamilyPhysician) {
      return (
        <MenuItem
          onClick={() => {
            navigationMethods.navigate(
              RELATIVE_PAGE_PATHS.SPECIALISTS + specialists[0].id
            );
          }}
          title={specialists[0].name}
        />
      );
    }
  };

  renderSectionTitle = (title) => {
    return (
      <div className="section-title">
        <p className="header mainGreenColor header-small">{title}</p>
        <hr />
      </div>
    );
  };

  renderAddSpecialistsButton = () => {
    if (this.props.specialists.length < ALLOWED_SPECIALISTS_PER_USER) {
      return (
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={8}
          className="flexContainerColumn centred"
        >
          <Button
            onClick={() => {
              navigationMethods.navigate(RELATIVE_PAGE_PATHS.ADD_SPECIALISTS);
            }}
            className="greenBtn header next uppercase"
          >
            {i18n.t('specialistsScreen:buttons.addSpecialist')}
          </Button>
        </Col>
      );
    }
  };

  render() {
    const specialists = [...this.props.specialists];
    const isFamilyPhysicianAvailable = isFamilyPhysician(specialists);

    return (
      <Container className="noPadding">
        <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
          <div className="flexContainerColumn flexContainerCentered profile-form fix-padding mb-5">
            <div className="menu-items" hidden={!isFamilyPhysicianAvailable}>
              {this.renderSectionTitle(i18n.t('specialistsScreen:GP'))}
              {this.renderGP()}
            </div>
            <div className="menu-items">
              {this.renderSectionTitle(i18n.t('specialistsScreen:specialists'))}
              {this.renderSpecialists()}
            </div>
            {this.renderAddSpecialistsButton()}
            <Box marginTop={2}></Box>
            {localStorage.getItem(LANGUAGE_CODE_KEY) === 'bg' ? (
              <Link href="https://www.namama.bg/partners-care/" target="_blank">
                {i18n.t('specialistsScreen:findSpecialist')}
              </Link>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    specialists: state.specialists.specialists,
  };
};

export default connect(mapStateToProps, { getSpecialists, checkForNewVersion })(
  SpecialistsScreen
);
