import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as i18n from 'i18next';
import BackButtonSVG from './common/svgComponents/BackButton';
import { checkForNewVersion, getUsefulInfo } from '../actions';
import { connect } from 'react-redux';
import navigationMethods from '../utils/navigationUtils';
import { getChildMonths } from '../utils/commonUtils';
import { LANGUAGE_CODE_KEY, USEFUL_INFO_MAX_MONTH } from '../utils/constants';

class UsefulInfoScreen extends React.Component {
  state = { selectedMonth: 0 };

  componentDidMount() {
    const selectedMonth = this.getSelectedMonth();
    this.props.getUsefulInfo(selectedMonth);
    this.setState({ selectedMonth });

    this.props.checkForNewVersion();
  }

  getSelectedMonth() {
    let selectedMonth = 0;
    if (this.props.user) {
      const childDateOfBirth = this.props.user.childDateOfBirth;

      selectedMonth = getChildMonths(childDateOfBirth);
    }

    if (selectedMonth > USEFUL_INFO_MAX_MONTH) {
      selectedMonth = USEFUL_INFO_MAX_MONTH;
    }

    return selectedMonth;
  }

  renderUsefulInfo = () => {
    if (!this.props.usefulInfo.length) {
      return (
        <p className="noContent safe-header-padding">
          {i18n.t('usefulInfoScreen:noUsefulInfoForThisMonth')}
        </p>
      );
    }

    return this.props.usefulInfo.map((item, i) => {
      const image = item.image ? (
        <img alt="" src={navigationMethods.getSystemImageUrl(item.image)} />
      ) : null;
      const descriptionClassName = !item.image ? 'p-0' : '';
      return (
        <div key={i} className="useful-info-item">
          <h5>{i18n.t(`usefulInfoScreen:${item.name}`)}</h5>
          {image}
          <p className={descriptionClassName}>
            {i18n.t(`usefulInfoScreen:descriptions.${item.description}`)}
          </p>
          {localStorage.getItem(LANGUAGE_CODE_KEY) === 'bg' ? (
            <a href={item.url} target="_blank" rel="noreferrer">
              {i18n.t('usefulInfoScreen:readMore')}
            </a>
          ) : (
            <></>
          )}
        </div>
      );
    });
  };

  renderHeader = () => {
    const leftArrow =
      this.state.selectedMonth > 0 ? (
        <BackButtonSVG
          width={28}
          height={28}
          color={'#87ba3b'}
          svgClicked={() => {
            this.props.getUsefulInfo(this.state.selectedMonth - 1);
            this.setState({
              selectedMonth: this.state.selectedMonth - 1,
            });
          }}
        />
      ) : null;
    const rightArrow =
      this.state.selectedMonth < USEFUL_INFO_MAX_MONTH ? (
        <BackButtonSVG
          className="rotated-arrow"
          width={28}
          height={28}
          color={'#87ba3b'}
          svgClicked={() => {
            this.props.getUsefulInfo(this.state.selectedMonth + 1);
            this.setState({
              selectedMonth: this.state.selectedMonth + 1,
            });
          }}
        />
      ) : null;

    return (
      <Row className="primaryHeader align-items-end w-100 flex-nowrap">
        <Col xs={1} className="justify-content-start d-flex p-0">
          {leftArrow}
        </Col>
        <Col xs={10}>
          <span className="uppercase">
            {i18n.t('usefulInfoScreen:title')}
            {i18n.t('usefulInfoScreen:months.' + this.state.selectedMonth)}
          </span>
        </Col>
        <Col xs={1} className="justify-content-end d-flex p-0">
          {rightArrow}
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <Container className="noPadding ml-3 mr-3 mb-5 limited-width">
        <div className="light-background"></div>
        <Col xs={12} className="centred noPadding">
          <div className="flexContainerColumn flexContainerCentered profile-form fix-padding">
            {this.renderHeader()}
            <div className="useful-info-items">{this.renderUsefulInfo()}</div>
          </div>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usefulInfo: state.usefulInfo,
    user: state.authentication.userData,
  };
};

export default connect(mapStateToProps, { getUsefulInfo, checkForNewVersion })(
  UsefulInfoScreen
);
