import { Typography } from '@mui/material';
import i18n from 'i18next';
import { FC } from 'react';

import { TimerValue } from '../../../context/TrackingTimerProvider';
import RouterLink from '../../common/RouterLink';
import { RELATIVE_PAGE_PATHS } from '../../../utils/constants';
import { MODAL_TYPE } from '../../../models/modalTypes';

interface TimerVisualizerProps {
  hideEmpty?: boolean;
  enableLink?: boolean;
  fullInfo?: boolean;
  tracker?: string;
  value?: TimerValue;
  addInfo?: string;
}

export const TimerVisualizer: FC<TimerVisualizerProps> = ({
  hideEmpty = false,
  enableLink = false,
  fullInfo = false,
  addInfo,
  value,
  tracker,
}) => {
  const format = (digit: number) => {
    return ('0' + digit).slice(-2);
  };

  const formatTimer = (value?: TimerValue) => {
    if (!value) return '00:00';
    if (value['h'] > 0)
      return `${format(value['h'])}:${format(value['m'])}:${format(
        value['s']
      )}`;
    return `${format(value['m'])}:${format(value['s'])}`;
  };

  if (!value && hideEmpty) return null;

  const element = (
    <div>
      {fullInfo && tracker && (
        <Typography>
          {i18n.t(`trackers:trackingScreen:timer-visualizer.${tracker}`, {
            additionalInfo: addInfo
              ? i18n.t(`trackers:trackingScreen:tracking.${addInfo}`)
              : '',
          }) + ''}
        </Typography>
      )}
      <div className="timer">{formatTimer(value)}</div>
    </div>
  );

  if (enableLink) {
    return (
      <RouterLink
        setModal={
          tracker === RELATIVE_PAGE_PATHS.BREAST_FEEDING
            ? MODAL_TYPE.EDIT
            : MODAL_TYPE.DEFAULT
        }
        to={`/tracking/${tracker}`}
      >
        {element}
      </RouterLink>
    );
  }
  return element;
};
