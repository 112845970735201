import * as i18n from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container } from 'reactstrap';
import { getFormValues } from 'redux-form';
import {
  checkForNewVersion,
  checkIfExpiredStickersExist,
  getJournal,
  loadAllGoals,
} from '../../actions';
import { FORMS } from '../../utils/constants';
import JournalHeader from './JournalHeader';
import JournalItems from './JournalItems';
import TagFilter from './TagFilter';

class JournalScreen extends React.Component {
  state = { activeItemMenu: 0 };

  render() {
    return (
      <Container className="noPadding ml-3 mr-3 limited-width-journal mb-5">
        <Col xs={12} className="centred noPadding journal">
          <div
            className="flexContainerColumn flexContainerCentered profile-form fix-padding"
            onClick={this.onCloseItemMenu}
          >
            <JournalHeader />
            <TagFilter
              onTagSelectedCallback={(tagId) => this.onTagSelected(tagId)}
              initialValues={{
                name: i18n.t('stickers:journalScreen:all'),
              }}
            />
            <JournalItems
              onDotsClick={this.onDotsClick}
              activeItemMenu={this.state.activeItemMenu}
            />
          </div>
        </Col>
      </Container>
    );
  }

  componentDidMount() {
    if (!this.props.goals.length) {
      this.props.loadAllGoals();
    }
    const tag =
      this.props.formValues && this.props.formValues.tag
        ? this.props.formValues.tag
        : {};
    this.props.getJournal(tag.id);

    this.props.checkForNewVersion();
  }

  onDotsClick = (item) => {
    if (this.props.activeItemMenu === item.id) {
      this.setState({ activeItemMenu: 0 });
    } else {
      this.setState({ activeItemMenu: item.id });
    }
  };

  onTagSelected = (tagId) => {
    this.props.getJournal(tagId);
  };

  onCloseItemMenu = () => {
    if (this.state.activeItemMenu !== 0) {
      this.setState({ activeItemMenu: 0 });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    selectedTag: state.selectedTag,
    user: state.authentication.userData,
    journal: state.journal,
    goals: state.goals,
    formValues: getFormValues(FORMS.TAG_FORM)(state),
  };
};

export default connect(mapStateToProps, {
  getJournal,
  loadAllGoals,
  checkIfExpiredStickersExist,
  checkForNewVersion,
})(JournalScreen);
