import { TRACKERS_TYPE } from '../../utils/constants';
import DiaperSlider from './diapers/DiaperSlider';
import MultiValueMeasurement from './multi-value-measurement/MultiValueMeasurement';
import TrackerTimeValueInput from './shared/TrackerTimeValueInput';
import TrackerValueInput from './shared/TrackerValueInput';

const trackerBoundaries = {
  [TRACKERS_TYPE.FEEDING]: {
    min: 0,
    max: 300,
    step: 5,
  },
  [TRACKERS_TYPE.MEASUREMENT]: {
    min: 0,
    max: 20000,
    step: 10,
  },
  [TRACKERS_TYPE.CHANGE_DIAPERS]: {
    min: 0,
    max: 2,
    step: 1,
  },
  [TRACKERS_TYPE.TIME_VALUE]: {
    min: 0,
    max: 300,
    step: 10,
  },
};

const getSliderMinMax = (type) => {
  const _type = trackerBoundaries[type] ? type : TRACKERS_TYPE.FEEDING;
  return {
    min: trackerBoundaries[_type].min,
    max: trackerBoundaries[_type].max,
    step: trackerBoundaries[_type]['step'],
  };
};
const TrackerInput = ({ tracker, name }) => {
  if (!tracker) return null;

  switch (tracker.type.name) {
    case TRACKERS_TYPE.FEEDING:
      return (
        <TrackerValueInput
          name={name}
          measurementSymbol={tracker.symbol}
          {...getSliderMinMax(tracker.type.name)}
        />
      );
    case TRACKERS_TYPE.MEASUREMENT:
      return (
        <TrackerValueInput
          name={name}
          measurementSymbol={tracker.symbol}
          {...getSliderMinMax(tracker.type.name)}
        />
      );
    case TRACKERS_TYPE.TIME_VALUE:
      return (
        <TrackerTimeValueInput
          name={name}
          measurementSymbol={tracker.symbol}
          {...getSliderMinMax(tracker.type.name)}
        />
      );
    case TRACKERS_TYPE.CHANGE_DIAPERS:
      return (
        <DiaperSlider
          name={name}
          {...getSliderMinMax(tracker.type.name)}
          type={tracker.type.name}
          subType={tracker.name}
        />
      );
    case TRACKERS_TYPE.MULTI_VALUE_MEASUREMENT:
      return <MultiValueMeasurement subType={tracker.name} />;

    default:
      return null;
  }
};
export default TrackerInput;
