import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODE_KEY } from '../../../utils/constants';

const GrowthFooter = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div id="who-data">
        <p style={{ marginBottom: 0 }}>
          {t('analytics:growthCurves:footer.WHO-info')}
        </p>
        <p>
          <a
            style={{ color: '#4B4b4B' }}
            target="_blank"
            href="https://www.who.int/tools/child-growth-standards/standards"
            rel="noreferrer"
          >
            https://www.who.int/tools/child-growth-standards/standards
          </a>
        </p>
      </div>
      <div id="growth-footer">
        {localStorage.getItem(LANGUAGE_CODE_KEY) === 'bg' ? (
          <div id="view-more">
            <p>{t('analytics:growthCurves:footer.is-it-growing')}</p>
            <a
              target="_blank"
              href="https://www.namama.bg/category/rastej/rast-teglo-izmervaniia/"
              rel="noreferrer"
            >
              {t('analytics:shared.view-more')}
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default GrowthFooter;
