import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import * as i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import {
  SHOW_INFO_MODAL,
  SHOW_AGREEMENT_MODAL,
  SHOW_ERROR_MODAL,
  SHOW_SUCCESS_MODAL,
  SHOW_SHARE_MODAL,
  SHOW_DELETE_MODAL,
  SHOW_CONFIRM_DIALOG,
  SHOW_RED_CONFIRM_DIALOG,
  SHOW_PAID_PRODUCTS_MODAL,
  SHOW_SUBSCRIBE_MODAL,
  SHOW_LOCKED_TRACKER_MODAL,
} from '../../actions/actionTypes';
import FacebookButton from './FacebookButton';
import { store } from '../../index';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import navigationUtils from '../../utils/navigationUtils';

const ModalDialog = (props) => {
  const { isOpen, message, type, toggle, onDelete, onConfirm, onDeny } = props;
  const history = useHistory();

  const openSubscriptionPlans = () => {
    sessionStorage.setItem('startingPointUrl', window.location.href);
    return (window.location.href =
      window.location.origin + RELATIVE_PAGE_PATHS.SUBSCRIPTION_PLANS);
  };

  const redirectByResult = () => {
    switch (message) {
      case i18n.t('payments:successfulSubscription'):
        return (window.location.href = sessionStorage.getItem(
          'startingPointUrl'
        )
          ? sessionStorage.getItem('startingPointUrl')
          : `${
              window.location.origin + RELATIVE_PAGE_PATHS.SUBSCRIPTION_PLANS
            }`);
      default:
        return toggle();
    }
  };

  const renderButton = () => {
    switch (type) {
      case SHOW_SUCCESS_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button className="greenBtn header uppercase" onClick={toggle}>
              {i18n.t('modalDialog:accept')}
            </Button>
          </Col>
        );
      case SHOW_INFO_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button className="greenBtn header uppercase" onClick={toggle}>
              {i18n.t('modalDialog:accept')}
            </Button>
          </Col>
        );
      case SHOW_LOCKED_TRACKER_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button
              className="greenBtn header uppercase"
              onClick={() => {
                navigationUtils.navigate(RELATIVE_PAGE_PATHS.TRACKING);
                toggle();
              }}
            >
              {i18n.t('modalDialog:accept')}
            </Button>
          </Col>
        );
      case SHOW_AGREEMENT_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              className="redBtnOutline header"
              onClick={toggle}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:cancel')}
            </Button>
            <Button
              className="redBtn header"
              onClick={console.log('agree')}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:agree')}
            </Button>
          </Col>
        );
      case SHOW_PAID_PRODUCTS_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button
              className="greenBtn header uppercase"
              onClick={openSubscriptionPlans}
            >
              {i18n.t('payments:getPlan')}
            </Button>
          </Col>
        );
      case SHOW_SUBSCRIBE_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button
              className="greenBtn header uppercase"
              onClick={redirectByResult}
            >
              {i18n.t('modalDialog:accept')}
            </Button>
          </Col>
        );
      case SHOW_ERROR_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button className="redBtn header uppercase" onClick={toggle}>
              {i18n.t('modalDialog:accept')}
            </Button>
          </Col>
        );
      case SHOW_SHARE_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <FacebookButton
              disabled={false}
              selectedAddedSticker={
                store.getState().selectedSticker.selectedAddedSticker
              }
            />
          </Col>
        );
      case SHOW_DELETE_MODAL:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              className="greenBtn header danger secondary"
              onClick={toggle}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:cancel')}
            </Button>
            <Button
              className="redBtn header"
              onClick={onDelete}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:continue')}
            </Button>
          </Col>
        );
      case SHOW_CONFIRM_DIALOG:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              className="greenBtn header"
              onClick={onConfirm}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:yes')}
            </Button>
            <Button
              className="redBtn header"
              onClick={() => {
                if (onDeny) onDeny();
                else toggle();
              }}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:no')}
            </Button>
          </Col>
        );
      case SHOW_RED_CONFIRM_DIALOG:
        return (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="centred noPadding"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              className="redBtn header"
              onClick={onConfirm}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:yes')}
            </Button>
            <Button
              className="greenBtn header"
              onClick={() => {
                if (onDeny) onDeny();
                else toggle();
              }}
              style={{ width: '70%', margin: '10px 5px' }}
            >
              {i18n.t('modalDialog:no')}
            </Button>
          </Col>
        );
      default:
        return null;
    }
  };

  const renderHeaderText = () => {
    switch (type) {
      case SHOW_ERROR_MODAL:
        return (
          <div className="fullWidth centredText danger header errorModalHeader">
            {i18n.t('modalDialog:headers.error').toUpperCase()}
          </div>
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    return (
      <p className="flexContainerCentered centredText modalMsg">{message}</p>
    );
  };

  const renderModalId = () => {
    switch (type) {
      case SHOW_ERROR_MODAL:
        return 'errorModal';
      default:
        return null;
    }
  };
  const isLockedTrackerCase = type === SHOW_LOCKED_TRACKER_MODAL;
  const isSubscribeModalCase = type === SHOW_SUBSCRIBE_MODAL;

  return (
    <div className="flexContainerCentered modal">
      <Modal
        id={renderModalId()}
        isOpen={isOpen}
        toggle={toggle}
        backdrop={isLockedTrackerCase || isSubscribeModalCase ? 'static' : true}
        keyboard={isLockedTrackerCase || isSubscribeModalCase ? false : true}
      >
        <ModalHeader
          className="modalHeader"
          toggle={
            isLockedTrackerCase || isSubscribeModalCase ? undefined : toggle
          }
        />
        <ModalBody>
          {renderHeaderText()}
          {renderContent()}
          {renderButton()}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalDialog;
