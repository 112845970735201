export const CLEAR_REDUCERS = 'CLEAR_REDUCERS';

export const LOCAL_STORAGE_DATA_LOADED = 'LOCAL_STORAGE_DATA_LOADED';

export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGOUT = 'LOGOUT';

// General Alert message

export const SHOW_SUCCESS_MODAL = 'SHOW_SUCCESS_MODAL';
export const SHOW_INFO_MODAL = 'SHOW_INFO_MODAL';
export const SHOW_AGREEMENT_MODAL = 'SHOW_AGREEMENT_MODAL';
export const SHOW_LOCKED_TRACKER_MODAL = 'SHOW_LOCKED_TRACKER_MODAL';
export const SHOW_PAID_PRODUCTS_MODAL = 'SHOW_PAID_PRODUCTS_MODAL';
export const SHOW_SUBSCRIBE_MODAL = 'SHOW_SUBSCRIBE_MODAL';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const CLEAR_MODAL_STATE = 'CLEAR_MODAL_STATE';
export const SHOW_SHARE_MODAL = 'SHOW_SHARE_MODAL';
export const SHOW_DELETE_MODAL = 'SHOW_DELETE_MODAL';
export const SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG';
export const SHOW_RED_CONFIRM_DIALOG = 'SHOW_RED_CONFIRM_DIALOG';

export const REGISTER_PROFILE = 'REGISTER_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const SHOW_HAMBURGER_MENU = 'SHOW_HAMBURGER_MENU';
export const HIDE_HAMBURGER_MENU = 'HIDE_HAMBURGER_MENU';

export const LOAD_GOALS_SUCCESS = 'LOAD_GOALS_SUCCESS';
export const LOAD_SELECTED_TAG_STICKERS_SUCCESS =
  'LOAD_SELECTED_TAG_STICKERS_SUCCESS';
export const LOAD_JOURNAL_SUCCESS = 'LOAD_JOURNAL_SUCCESS';
export const SET_JOURNAL_TOKEN_STATUS_SUCCESS =
  'SET_JOURNAL_TOKEN_STATUS_SUCCESS';
export const EXPIRED_STICKERS_EXIST = 'EXPIRED_STICKERS_EXIST';
export const GET_EXPIRED_STICKERS = 'GET_EXPIRED_STICKERS';
export const LOAD_ADDED_STICKER_SUCCESS = 'LOAD_ADDED_STICKER_SUCCESS';
export const DELETE_ADDED_STICKER_SUCCESS = 'DELETE_ADDED_STICKER_SUCCESS';
export const CLEAR_SELECTED_TAG = 'CLEAR_SELECTED_TAG';

export const ADD_STICKER_TO_JOURNAL_SUCCESS = 'ADD_STICKER_TO_JOURNAL_SUCCESS';
export const SELECT_GOAL_SUCCESS = 'SELECT_GOAL_SUCCESS';
export const SELECT_TAG_SUCCESS = 'SELECT_TAG_SUCCESS';
export const SELECT_STICKER_SUCCESS = 'SELECT_STICKER_SUCCESS';
export const CLEAR_SELECTED_GOAL = 'CLEAR_SELECTED_GOAL';

export const SAVE_PAID_FUNCTIONALITY_CREDENTIALS =
  'SAVE_PAID_FUNCTIONALITY_CREDENTIALS';

export const GET_USEFUL_INFO = 'GET_USEFUL_INFO';

export const LOAD_SPECIALISTS = 'LOAD_SPECIALISTS';
export const LOAD_SPECIALIST = 'LOAD_SPECIALIST';

export const LOAD_CONTACTS = 'LOAD_CONTACTS';
export const LOAD_CONTACT = 'LOAD_CONTACT';

export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';

export const START_NAV_LOADER = 'START_NAV_LOADER';
export const STOP_NAV_LOADER = 'STOP_NAV_LOADER';

export const LOAD_BREASTFEEDING = 'LOAD_BREASTFEEDING';

// Trackers
export const LOAD_TRACKERS = 'LOAD_TRACKERS';
export const LOAD_TRACKING_RECORDS = 'LOAD_TRACKING_RECORDS';
export const UPDATE_TRACKING_RECORD = 'UPDATE_TRACKING_RECORD';
export const CLEAR_TRACKING_RECORDS = 'CLEAR_TRACKING_RECORDS';
export const SELECT_TRACKER = 'SELECT_TRACKER';

export const ADD_EVENT_LISTENER = 'ADD_EVENT_LISTENER';

export const CLEAR_CHECKBOX = 'CLEAR_CHECKBOX';
export const UPDATE_CUSTOM_PRODUCTS = 'UPDATE_CUSTOM_PRODUCTS';
