import {
  COLOR_SCHEME,
  DEFAULT_LOCALE_CODE,
  GOALS,
  IMAGE_MAX_SIZE_IN_BYTES,
  LANGUAGE_CODE_KEY,
  RELATIVE_PAGE_PATHS,
  SELECTED_STICKER_IMAGE_DATA,
  SELECTED_STICKER_IN_LOCAL_STORAGE,
  SUPPORTED_IMAGE_FORMATS,
  TRACKER_SUBTYPES,
} from './constants';
import moment from './moment';
import navigationMethods from './navigationUtils';
import * as i18n from 'i18next';
import { languages } from '../i18next';

export const setSelectedStickerInLocalStorage = (selectedSticker) => {
  localStorage.setItem(
    SELECTED_STICKER_IN_LOCAL_STORAGE,
    JSON.stringify(selectedSticker)
  );
};

export const setGoalsInLocalStorage = (goals) => {
  localStorage.setItem(GOALS, JSON.stringify(goals));
};

export const setImageDataInLocalStorage = (imageData) => {
  localStorage.setItem(SELECTED_STICKER_IMAGE_DATA, JSON.stringify(imageData));
};

export const parseDate = (date) => {
  return moment(date).format('DD.MM.YYYY');
};

export const getColor = (color) => {
  return '#' + color;
};

export const getActiveColor = (selectedGoal, type) => {
  switch (type) {
    case 'borderColor':
      return '#' + selectedGoal.borderColor;
    case 'backgroundColor':
      return '#' + selectedGoal.backgroundColor;
    case 'expiredBorderColor':
      return COLOR_SCHEME.YELLOW;
    case 'expiredBackgroundColor':
      return COLOR_SCHEME.LIGHTER_YELLOW;
    case 'unActiveColor':
      return COLOR_SCHEME.GRAY;
    case 'unActiveTextColor':
      return COLOR_SCHEME.DARK_GREY;
    default:
      return COLOR_SCHEME.GRAY;
  }
};

export const isNoStickers = (stickers) => {
  let isNoStickersToAdd = true;
  stickers.forEach((sticker) => {
    if (sticker.length) {
      isNoStickersToAdd = false;
    }
  });
  return isNoStickersToAdd;
};

export const isAbilitiesTag = (tagName) =>
  tagName === i18n.t('tags:name.skills');

export const isProphylacticsTag = (tagName) =>
  tagName === i18n.t('tags:name.preventive');

export const isWorriesTag = (tagName) =>
  tagName === i18n.t('tags:name.concerns');

export const isCelebrationsTag = (tagName) =>
  tagName === i18n.t('tags:name.festiveMoments');

export const isCreativePerformancesTag = (tagName) =>
  tagName === i18n.t('tags:name.creativePerformances');

export const isCapturedMomentsTag = (tagName) =>
  tagName === i18n.t('tags:name.capturedMoments');

export const isShareButtonDisabled = (selectedAddedSticker) => {
  if (!selectedAddedSticker.stickerTemplate) {
    return true;
  }
  if (
    isWorriesTag(selectedAddedSticker.stickerTemplate.area.tag.name) ||
    selectedAddedSticker.hasOccurredAfterDeadline ||
    !selectedAddedSticker.photo
  ) {
    return true;
  }
  return false;
};

export const isEditStickerRoute = () => {
  const route = navigationMethods.getRoute();

  return route.split('/')[1] === RELATIVE_PAGE_PATHS.EDIT_STICKER.split('/')[1];
};

export const isReadOnlyRoute = () => {
  const route = navigationMethods.getRoute();

  return (
    route.split('/')[1] ===
      RELATIVE_PAGE_PATHS.STICKER_READ_ONLY.split('/')[1] ||
    route.split('/')[1] === RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY.split('/')[1]
  );
};

export const isExistingSticker = () => {
  const route = navigationMethods.getRoute().split('/');
  return (
    route[route.length - 2] ===
      RELATIVE_PAGE_PATHS.EDIT_STICKER.split('/')[1] ||
    route[route.length - 2] ===
      RELATIVE_PAGE_PATHS.STICKER_READ_ONLY.split('/')[1]
  );
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const isImageBelowMaxSize = (imgSize) => {
  return imgSize <= IMAGE_MAX_SIZE_IN_BYTES ? true : false;
};

export const getChildMonths = (childDateOfBirth) => {
  const formattedChildDateOfBirth = moment(childDateOfBirth);
  const isDateBeforeToday = formattedChildDateOfBirth.isBefore(moment(), 'day');
  const currDate = moment();

  let childMonths = 0;

  if (isDateBeforeToday) {
    childMonths = currDate.diff(formattedChildDateOfBirth, 'months');
  }

  return childMonths;
};

export const defaultServerValidationErrorCallback = () => {
  navigationMethods.goBack();
};

export const isSupportedImageFormat = (imgFormat) => {
  const format = imgFormat.split('/')[1].toUpperCase();
  return SUPPORTED_IMAGE_FORMATS.includes(format);
};

export const isFamilyPhysician = (specialists) => {
  // let isGPavailable = false;
  return (
    specialists.findIndex((specialist) => specialist.isFamilyPhysician) > -1
  );
  //     if (specialist.isFamilyPhysician) {
  //         isGPavailable = true;
  //     }
  // });
  // return isGPavailable;
};

export const isFullHDScreen = (width) => (width >= 1920 ? true : false);

export const userData = JSON.parse(localStorage.getItem('USER_DATA'));

export const getLocalStorageLanguage = () => {
  const englishLanguagePosition = 1;

  let currentLanguageCode =
    localStorage.getItem(LANGUAGE_CODE_KEY) ?? DEFAULT_LOCALE_CODE;

  currentLanguageCode = currentLanguageCode.includes('-')
    ? currentLanguageCode.split('-')[0]
    : currentLanguageCode;

  const currentLanguage =
    languages.find((l) => l.code === currentLanguageCode) ||
    languages[englishLanguagePosition];

  return currentLanguage;
};

// return prod relative db tracker_subtype id
export const getTrackerId = (name) => {
  // switch with local/dev values for testing :)
  switch (name) {
    case TRACKER_SUBTYPES.SLEEP:
      return 6; // dev value: 7 | prod value: 6
    case TRACKER_SUBTYPES.BREAST_FEEDING:
      return 3; // dev value: 3 | prod value: 3
    case TRACKER_SUBTYPES.CRY:
      return 11; // dev value: 25 | prod value: 11
    default:
      return name;
  }
};
