import navigationMethods from '../../../utils/navigationUtils';

interface ITrackerSeparator {
    trackerLink?: string;
    trackerIcon: string;
}

const TrackerSeparatorNoHover = (props: ITrackerSeparator) => {
    return (
        <>
            <div
                className="icon-separator"
            >
                <hr className="separator-line" style={{ marginRight: '10px' }} />
                <img
                    alt={'icon'}
                    src={navigationMethods.getSystemImageUrl(props.trackerIcon)}
                    width={'55px'}
                    height={'55px'}
                />
                <hr className="separator-line" style={{ marginLeft: '10px' }} />
            </div>
        </>
    );
};

export default TrackerSeparatorNoHover;
