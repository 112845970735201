import { Link } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import { MODAL_TYPE } from '../../models/modalTypes';

const RouterLink = ({ setModal, ...props }) => (
  <Link
    component={ReactRouterLink}
    onClick={() => {
      if (typeof setModal === 'function') {
        return props.to.slice(0, -3) === RELATIVE_PAGE_PATHS.BREAST_FEEDING
          ? setModal(MODAL_TYPE.EDIT)
          : setModal(MODAL_TYPE.DEFAULT);
      }
      return '';
    }}
    {...props}
  />
);

export default RouterLink;
