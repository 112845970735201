import React, { Fragment } from 'react';
import * as i18n from 'i18next';
import { connect } from 'react-redux';

class JournalHeader extends React.Component {
  render() {
    const { user } = this.props;

    if (!user) {
      return <Fragment />;
    }

    return (
      <div className="primaryHeader">
        <p>{i18n.t('stickers:journalScreen:theJournal')}</p>
        <span className="uppercase">
          {i18n.t('stickers:journalScreen:of')} {user.childName}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.authentication.userData };
};

export default connect(mapStateToProps)(JournalHeader);
