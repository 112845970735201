import axios from 'axios';
import { showErrorModal } from './commonActions';
import { getAxiosWithToken } from '../utils/webApi';

export const fetchTranslationFile = async (languageCode) => {
    try {
        const response = await getAxiosWithToken().get(`github/translation/${languageCode}`);
        const { fileName, fileContent, sha } = response.data;

        return { fileName, fileContent, sha, error: '' };
    } catch (error) {
        console.error(`Error fetching translation file: ${error.message}`);

        return { fileName: '', fileContent: '', sha: '', error: error.message };
    }
};

export const uploadFileToGitHub = async (fileContent, languageCode, dispatch) => {
    try {
        const payload = {
            fileContent,
            languageCode,
        };

        const response = await getAxiosWithToken().put('github/upload-file', { fileContent, languageCode });

        if (response.status === 200) {
            return { success: true, error: null };
        } else {
            throw new Error('Failed to upload file');
        }
    } catch (error) {
        console.error(`Error uploading file: ${error.message}`);
        dispatch(showErrorModal(`Грешка при качването на файла: ${error.message}`));
        return { success: false, error: error.message };
    }
};
