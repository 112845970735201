import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import AuthenticationReducer from './authenticationReducer';
import CommonReducer from './commonReducer';
import InfoModalReducer from './modalReducer';
import GoalsAndTagsReducer from './journal/goalsAndTagsReducer';
import SelectedTagReducer from './journal/selectedTagReducer';
import SelectedGoalReducer from './journal/selectedGoalReducer';
import SelectedTagStickersReducer from './journal/selectedTagStickersReducer';
import JournalReducer from './journalReducer';
import ExpiredStickersReducer from './journal/expiredStickersReducer';
import SelectedStickerReducer from './journal/selectedStickerReducer';
import SpecialistsReducer from './specialistsReducer';
import ContactsReducer from './contactsReducer';
import paidFunctionalityCredentialsReducer from './paidFunctionalityCredentialsReducer';
import loaderReducer from './loaderReducer';
import usefulInfoReducer from './usefulInfoReducer';
import breastfeedingReducer from './breastfeedingReducer';
import navLoaderReducer from './navLoaderReducer';
import trackingRecordsReducer from './trackingRecordsReducer';
import trackersReducer from './trackersReducer';
import selectedTrackerReducer from './selectedTrackerReducer';
import AddToHomeReducer from './addToHomeReducer';
import feedingReducer from './feedingReducer';

const rootReducer = combineReducers({
  form: formReducer,
  authentication: AuthenticationReducer,
  common: CommonReducer,
  goals: GoalsAndTagsReducer,
  selectedGoal: SelectedGoalReducer,
  selectedTag: SelectedTagReducer,
  stickers: SelectedTagStickersReducer,
  selectedSticker: SelectedStickerReducer,
  modal: InfoModalReducer,
  journal: JournalReducer,
  expiredStickers: ExpiredStickersReducer,
  paidFunctionalityCredentials: paidFunctionalityCredentialsReducer,
  specialists: SpecialistsReducer,
  contacts: ContactsReducer,
  usefulInfo: usefulInfoReducer,
  loader: loaderReducer,
  navLoader: navLoaderReducer,
  breastfeeding: breastfeedingReducer,
  trackingRecords: trackingRecordsReducer,
  trackers: trackersReducer,
  selectedTracker: selectedTrackerReducer,
  addToHomeReducer: AddToHomeReducer,
  feedingReducer: feedingReducer,
});

export default rootReducer;
