import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import '../../styles/adminTranslations.css';
import { useDispatch } from 'react-redux';
import { closeModal, showErrorModal, showSuccessModal } from '../../actions';
import { INSTRUCTIONS } from '../../utils/constants';
import { fetchTranslationFile, uploadFileToGitHub } from '../../actions/AdminTranslationModificationActions';

const AdminTranslationModification = ({
    selectedButton = '',
    handleLanguageButtonClick = () => { }
}) => {
    const [fileUrl, setFileUrl] = useState('');
    const [files, setFiles] = useState([{ id: 1, file: null, fileName: '', fileContent: null }]);
    const [fileContent, setFileContent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [sha, setSha] = useState('');
    const [error, setError] = useState('');
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [languageButtonFocused, setLanguageButtonFocused] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const dispatch = useDispatch();

    const allowedFileNames = ['bg.json', 'ro.json', 'de.json', 'en.json', 'ru.json'];

    const fileInputRefs = useRef([]);

    useEffect(() => {
        fileInputRefs.current = files.map((_, i) => fileInputRefs.current[i] || React.createRef());
    }, [files]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const languageButtonContainer = document.querySelector('.flag-button-container');
            const downloadButton = document.querySelector('.download-button');

            if (!languageButtonContainer.contains(event.target) && event.target !== downloadButton) {
                setLanguageButtonFocused(true);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    const handleButtonClick = async (languageCode) => {
        setShowDownloadButton(true);
        setSelectedLanguage(languageCode);
        handleLanguageButtonClick(languageCode);
        setError('');

        try {
            const { fileContent } = await fetchTranslationFile(languageCode);

            const blob = new Blob([fileContent], { type: 'application/json' });
            const url = URL.createObjectURL(blob);

            setFileUrl(url);
            setFileContent(fileContent);
        } catch (e) {
            setError(`Failed to fetch file for ${languageCode}: ${e.message}`);
        }
    };

    const handleFileUpload = async (file, fileName) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const fileContent = e.target.result;
                const languageCode = fileName.split('.')[0];
                try {
                    const response = await uploadFileToGitHub(fileContent, languageCode, dispatch);
                    if (response.error) {
                        throw new Error(response.error);
                    }
                } catch (e) {
                    console.error(`Failed to upload file for ${languageCode}: ${e.message}`);
                }
            };
            reader.readAsText(file);
        } else {
            console.error('Please select a valid JSON file.');
        }
    };

    const modalClose = () => {
        setShowModal(false);
    };
    const handleDownload = async (languageCode) => {
        try {

            console.log(languageCode)
            const { fileName, fileContent, sha, error } = await fetchTranslationFile(languageCode);

            if (error) {
                throw new Error(error);
            }

            const blob = new Blob([fileContent], { type: 'application/json' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = fileName || `${languageCode}.json`;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (e) {
            console.error(`Failed to fetch file for ${languageCode}: ${e.message}`);
        }
    };

    const handleUpload = () => {
        const message = 'Файлът бе успешно качен, промените ще бъдат отразени след 3-4 минути.'
        dispatch(showSuccessModal(message));


        return (
            <div>
                <Modal show={showModal} onClose={closeModal} />
            </div>
        );
    };

    const Modal = ({ show, onClose }) => {
        if (!show) {
            return null;
        }

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <h2>Файлът бе изтеглен успешно в папка "Downloads"</h2>
                    <button onClick={onClose}>OK</button>
                </div>
            </div>
        );
    };


    const handleFileChange = (event, index) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (allowedFileNames.includes(selectedFile.name)) {
                const newFiles = [...files];
                newFiles[index] = { ...newFiles[index], file: selectedFile, fileName: selectedFile.name };
                setFiles(newFiles);

                const reader = new FileReader();
                reader.onload = (e) => {
                    const newFilesWithContent = [...newFiles];
                    newFilesWithContent[index].fileContent = e.target.result;
                    setFiles(newFilesWithContent);
                };
                reader.readAsText(selectedFile);
            } else {
                dispatch(showErrorModal(`Невалиден Файл: ${selectedFile.name}. Следните файлове могат да бъдат качени: ${allowedFileNames.join(', ')}`));
            }
        }
    };

    const handleLanguageButtonFocus = () => {
        setLanguageButtonFocused(false);
    };

    const handleLanguageButtonBlur = (event) => {
        if (event.target.classList.contains('upload-button')) {
            return;
        }

        if (!event.target.closest('.flag-button-container')) {
            setLanguageButtonFocused(true);
        }
    };

    return (
        <div className='adminFlexContainerColumn adminFlexContainerCentered' style={{ margin: '24px 0px' }}>
            <h5 className="primaryHeaderDownload">
                <p>Изтегли актуални преводи</p>
            </h5>
            <div className='flag-button-container'>
                <Button
                    className={`flag-button ${selectedButton === 'bg' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('bg')}
                    onFocus={handleLanguageButtonFocus}
                    onBlur={handleLanguageButtonBlur}
                >
                    <span className='flag-icon flag-icon-bg'></span>
                </Button>
                <Button
                    className={`flag-button ${selectedButton === 'en' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('en')}
                    onFocus={handleLanguageButtonFocus}
                    onBlur={handleLanguageButtonBlur}
                >
                    <span className='flag-icon flag-icon-us'></span>
                </Button>
                <Button
                    className={`flag-button ${selectedButton === 'ro' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('ro')}
                    onFocus={handleLanguageButtonFocus}
                    onBlur={handleLanguageButtonBlur}
                >
                    <span className='flag-icon flag-icon-ro'></span>
                </Button>
                <Button
                    className={`flag-button ${selectedButton === 'de' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('de')}
                    onFocus={handleLanguageButtonFocus}
                    onBlur={handleLanguageButtonBlur}
                >
                    <span className='flag-icon flag-icon-de'></span>
                </Button>
                <Button
                    className={`flag-button ${selectedButton === 'ru' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('ru')}
                    onFocus={handleLanguageButtonFocus}
                    onBlur={handleLanguageButtonBlur}
                >
                    <span className='flag-icon flag-icon-ru'></span>
                </Button>
            </div>
            {error && <div className='error-message'>{error}</div>}
            {successMessage && <div className='success-message'>{successMessage}</div>}
            {showDownloadButton && !error && !languageButtonFocused && (
                <div className='file-content-container'>
                    <div className="button-container">
                        <button onClick={() => handleDownload(selectedLanguage)} className='download-button'>
                            Изтегли {selectedLanguage}.json
                        </button>
                    </div>
                </div>
            )}

            {showModal && <Modal show={showModal} onClose={modalClose} />}

            <h5 className="primaryHeaderUpload">
                <p>Актуализирай преводи</p>
            </h5>
            <div className='files-wr' data-count-files={files.length}>
                {files.map((file, index) => (
                    <div className='one-file' key={file.id}>
                        {!file.file ? (
                            <div className="button-container">
                                <button
                                    className='upload-button'
                                    onClick={() => {
                                        fileInputRefs.current[index].click();
                                    }}
                                >
                                    Прикачи Файл
                                </button>
                                <input
                                    ref={(el) => (fileInputRefs.current[index] = el)}
                                    name={`file-${file.id}`}
                                    id={`file-${file.id}`}
                                    type='file'
                                    accept='.json'
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, index)}
                                />
                            </div>
                        ) : (
                            <div className='file-info'>
                                <span className='file-name'>{file.fileName}</span>
                                <div className="button-container">
                                    <button
                                        onClick={() => {
                                            handleFileUpload(file.file, file.fileName);
                                            handleUpload();
                                        }}
                                        className='apply-button'
                                    >
                                        Приложи {file.fileName}
                                    </button>
                                    <button
                                        className='reupload-button'
                                        onClick={() => fileInputRefs.current[index].click()}
                                    >
                                        Прикачи Друг Файл
                                    </button>
                                    <input
                                        ref={(el) => (fileInputRefs.current[index] = el)}
                                        name={`file-${file.id}`}
                                        id={`file-${file.id}`}
                                        type='file'
                                        accept='.json'
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, index)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <a
                href="/TranslationInstructions.pdf"
                download="TranslationInstructions.pdf"
                className="download-instructions-button"
            >
                {INSTRUCTIONS}
            </a>
        </div>
    );
};

// Modal Component
const Modal = ({ show, onClose }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Файлът бе изтеглен успешно в папка "Downloads"</h2>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

AdminTranslationModification.propTypes = {
    selectedButton: PropTypes.string,
    handleLanguageButtonClick: PropTypes.func,
    selectedButton: PropTypes.string,
    handleLanguageButtonClick: PropTypes.func,
};

export default AdminTranslationModification;