import { ADD_EVENT_LISTENER } from '../actions/actionTypes';

const initialState = {
    beforeInstallPromptEvent: null
}

const addToHomeReducer = (state = initialState, action) => {
  var data = action.payload;
  switch (action.type) {
    case ADD_EVENT_LISTENER:
        return {
            ...state,
            beforeInstallPromptEvent: data
        };
    default:
      return state;
  }
};

export default addToHomeReducer;
