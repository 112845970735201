import { FormControl, FormHelperText, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export const TrackerDateTime = ({ name }) => {
  const { input, meta } = useField(name);
  const { t } = useTranslation();

  const handleChange = (field) => (event) => {
    if (field === 'date') {
      input.onChange(
        moment(
          `${event.target.value} ${moment(input.value).format('HH:mm')}`
        ).toISOString()
      );
    } else if (field === 'time') {
      input.onChange(
        moment(
          `${moment(input.value).format('YYYY-MM-DD')} ${event.target.value}`
        ).toISOString()
      );
    }
  };

  useEffect(() => {
    let date = new Date(input.value);
    if (date.getFullYear() < 2000) {
      input.onChange(moment(new Date()).format('YYYY-MM-DD HH:mm'));
    }
  }, [input.value]);

  return (
    <FormControl fullWidth>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width={'100%'}>
        <TextField
          fullWidth
          id="date"
          label={t('auth:fields:date')}
          type="date"
          value={moment(input.value).format('YYYY-MM-DD')}
          onChange={handleChange('date')}
          onBlur={input.onBlur}
          sx={{ minWidth: '175px' }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ max: moment().format('YYYY-MM-DD') }}
          error={meta.error && meta.touched}
        />
        <TextField
          fullWidth
          id="time"
          label={t('auth:fields:hour')}
          type="time"
          value={moment(input.value).format('HH:mm')}
          onChange={handleChange('time')}
          onBlur={input.onBlur}
          // sx={{ width: 150 }}
          InputLabelProps={{
            shrink: true,
          }}
          error={meta.error && meta.touched}
        />
      </Stack>
      <FormHelperText error={meta.error && meta.touched}>
        {meta.error && meta.touched && meta.error}
      </FormHelperText>
    </FormControl>
  );
};
