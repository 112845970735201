import moment from 'moment';
import { useMemo } from 'react';
import {
  LANGUAGE_CODE_KEY,
  RELATIVE_LINKS_PROD,
  TRACKERS_TYPE,
} from '../../utils/constants';
import navigationMethods from '../../utils/navigationUtils';
import formatMeasurement from '../../utils/trackerMeasurement';
import BreastfeedingSticker from '../common/svgComponents/BreastfeedingSticker';
import DiaperHeader from './diapers/DiaperHeader';
import FeedingHeader from './feeding/FeedingHeader';
import MeasurementHeader from './measurement/MeasurementHeader';
import ZeroRecordHeader from './shared/ZeroRecordHeader';
import { useTranslation } from 'react-i18next';

const TrackerHeader = ({ childBirthDate, tracker, lastRecord }) => {
  const { t } = useTranslation();

  const icon =
    tracker && tracker.image ? (
      <img
        alt="Icon"
        src={navigationMethods.getSystemImageUrl(tracker.image)}
        width={96}
        height={96}
        style={{
          backgroundColor: 'white',
          borderRadius: 8,
          border: '2px solid #87BA3B',
        }}
      />
    ) : (
      <BreastfeedingSticker />
    );

  const HeaderDetails = useMemo(() => {
    if (!lastRecord.type) return <ZeroRecordHeader />;

    const newMoment = (e) => {
      const language = localStorage.getItem(LANGUAGE_CODE_KEY);
      return moment(e).locale(language);
    };

    const props = {
      beforeText: newMoment(lastRecord.date).fromNow(),
      quantityText: formatMeasurement(
        lastRecord.quantity,
        tracker.symbol,
        lastRecord.type,
        lastRecord.subType,
        lastRecord.additionalInfo,
        false
      ),
    };

    switch (tracker.type.name) {
      case TRACKERS_TYPE.FEEDING:
        return <FeedingHeader {...props} />;
      case TRACKERS_TYPE.CHANGE_DIAPERS:
        return <DiaperHeader subType={tracker.name} {...props} />;
      case TRACKERS_TYPE.MEASUREMENT || TRACKERS_TYPE.MULTI_VALUE_MEASUREMENT:
        return <MeasurementHeader {...props} />;
      case TRACKERS_TYPE.MULTI_VALUE_MEASUREMENT:
        return <MeasurementHeader {...props} />;
      case TRACKERS_TYPE.TIME_VALUE:
        return <FeedingHeader {...props} />;
      default:
        return null;
    }
  }, [lastRecord, tracker]);

  const renderLink = () => {
    switch (tracker.id) {
      case RELATIVE_LINKS_PROD.BOTTLE_FORMULA:
      case RELATIVE_LINKS_PROD.BOTTLE_BREAST_MILK:
      case RELATIVE_LINKS_PROD.WET_DIAPER:
      case RELATIVE_LINKS_PROD.POO_DIAPER:
        return (
          <a
            href="/analytics?type=feeding"
            style={{ textDecoration: 'underline' }}
          >
            {t('trackers:trackingScreen:analyticsLink')}
          </a>
        );
      case RELATIVE_LINKS_PROD.WEIGHT_TRACKER:
        return (
          <a href="/analytics/#weight" style={{ textDecoration: 'underline' }}>
            {t('trackers:trackingScreen:seeGraph')}
          </a>
        );
      case RELATIVE_LINKS_PROD.HEIGHT_TRACKER:
        return (
          <a href="/analytics/#height" style={{ textDecoration: 'underline' }}>
            {t('trackers:trackingScreen:seeGraph')}
          </a>
        );
      case RELATIVE_LINKS_PROD.HEAD_CIRCUMFERENCE_TRACKER:
        return (
          <a
            href="/analytics/#circumference"
            style={{ textDecoration: 'underline' }}
          >
            {t('trackers:trackingScreen:seeGraph')}
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <div className="details">
      <div style={{ display: 'flex', alignItems: 'end' }}>
        {icon}
        <div style={{ marginLeft: '10px' }}>
          <div className="text">{HeaderDetails}</div>
          <div className="analytics-link">{renderLink()}</div>
        </div>
      </div>
    </div>
  );
};

export default TrackerHeader;
