import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { isExistingSticker } from '../../utils/commonUtils';
import * as i18n from 'i18next';

class StickerDescription extends React.Component {
  render() {
    let { selectedStickerTemplate } = this.props;

    const stickerText = selectedStickerTemplate
      ? selectedStickerTemplate.description
      : null;

    return (
      <Col xs={12} className="flexContainerColumn stickerDescription centred">
        <p className="noMargin">
          {i18n.t(`stickerTranslations:${stickerText}`)}
        </p>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedStickerTemplate: isExistingSticker()
      ? state.selectedSticker.selectedAddedSticker.stickerTemplate
      : state.selectedSticker.selectedStickerTemplate,
  };
};

export default connect(mapStateToProps)(StickerDescription);
