import { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { Button, Col, Container } from 'reactstrap';
import * as i18n from 'i18next';
import moment from 'moment';
import { Fields } from '../common/Fields';
import {
  DEFAULT_BACKEND_DATE_FORMAT,
  FORMS,
  RELATIVE_PAGE_PATHS,
} from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import {
  editProfile,
  showConfirmModal,
  closeModal,
  checkForNewVersion,
} from '../../actions';
import LanguageSelector from '../LanguageSelector';
import { getLocalStorageLanguage } from '../../utils/commonUtils';

const ProfileScreen = (props) => {
  const [language, setLanguage] = useState(getLocalStorageLanguage().code);
  const dispatch = useDispatch();
  const { handleSubmit, initialValues } = props;
  const user = useSelector((state) => state.authentication.userData);

  useEffect(() => {
    // Avoiding device keyboard
    const datePickers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );
    Array.from(datePickers).forEach((el) =>
      el.childNodes[0].setAttribute('readOnly', true)
    );

    dispatch(checkForNewVersion());
  }, [dispatch]);

  const onSubmit = (formValues) => {
    formValues.languageCode = language;
    const data = { ...formValues };
    data.id = user.id;
    data.isChildGirl = !!data.isChildGirl;
    if (
      moment(data.childDateOfBirth).format(DEFAULT_BACKEND_DATE_FORMAT) !==
      moment(initialValues.childDateOfBirth).format(DEFAULT_BACKEND_DATE_FORMAT)
    ) {
      dispatch(
        showConfirmModal({
          message: i18n.t('userProfileAction:profileScreen:confirmEditingUser'),
          onConfirm: () => {
            dispatch(closeModal());
            dispatch(
              editProfile(
                data,
                user.id,
                formValues.isChildBorn4WeeksBeforeDueDate
              )
            );
          },
        })
      );
    } else {
      dispatch(
        editProfile(data, user.id, formValues.isChildBorn4WeeksBeforeDueDate)
      );
    }
  };

  return (
    <Container className="noPadding">
      <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flexContainerColumn flexContainerCentered profile-form fix-padding"
        >
          <div
            className="flexContainerColumn flexContainerCentered"
            style={{ marginBottom: '3.5rem', height: '0' }}
          >
            <LanguageSelector language={language} setLanguage={setLanguage} />
          </div>

          <div className="flexContainerCentered">
            <p className="header mainGreenColor header-small uppercase">
              {i18n.t('userProfileAction:profileScreen:forParents')}
            </p>
          </div>

          <div className="flexContainerColumn flexContainerCentered">
            {Fields.commonFields.email({ disabled: true })}
            {Fields.commonFields.parentName({
              placeholder: i18n.t(
                'userProfileAction:profileScreen:fields.name'
              ),
            })}
            {Fields.commonFields.city()}
          </div>

          <div className="flexContainerCentered">
            <p className="header mainGreenColor uppercase">
              {i18n.t('userProfileAction:profileScreen:forChild')}
            </p>
          </div>

          <div className="flexContainerColumn flexContainerCentered">
            {Fields.commonFields.childName({
              placeholder: i18n.t(
                'userProfileAction:profileScreen:fields.name'
              ),
            })}
            {Fields.commonFields.childBirth()}
            {Fields.commonFields.isChildBorn4WeeksBeforeDueDate()}
            {Fields.commonFields.isChildGirl()}
          </div>

          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="flexContainerColumn centred"
          >
            <Button
              onClick={handleSubmit(onSubmit)}
              className="greenBtn header next uppercase"
            >
              {i18n.t('buttons:saveChanges')}
            </Button>
            <div className="text-buttons">
              <Link
                className="text-btn"
                to={RELATIVE_PAGE_PATHS.CHANGE_PASSWORD}
              >
                {i18n.t('userProfileAction:profileScreen:changePassword')}
              </Link>
              <Link
                className="text-btn danger d-block"
                to={RELATIVE_PAGE_PATHS.DELETE_PROFILE}
              >
                {i18n.t('userProfileAction:profileScreen:deleteProfile')}
              </Link>
            </div>
          </Col>
        </form>
      </Col>
    </Container>
  );
};

const profileForm = reduxForm({
  form: FORMS.PROFILE_FORM,
  validate: formUtils.validateProfile,
})(ProfileScreen);

const parseInitialValues = (values) => {
  const initialValues = { ...values };
  initialValues.childDateOfBirth = new Date(values.childDateOfBirth);
  initialValues.isChildGirl = values.isChildGirl ? '1' : '';
  return initialValues;
};

const mapStateToProps = (state) => ({
  initialValues: parseInitialValues(state.authentication.userData),
});

export default connect(mapStateToProps)(profileForm);
