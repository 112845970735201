import { Grid, Link, Typography } from '@mui/material';
import * as i18n from 'i18next';
import { orderBy } from 'lodash';
import { forwardRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { showPaidProductsModal } from '../../actions';
import { RELATIVE_PAGE_PATHS, TRACKERS_SUB_TYPE } from '../../utils/constants';
import navigationMethods from '../../utils/navigationUtils';
import TrackerItem from './TrackerItem';
import TrackerTotalHistory from './TrackerTotalHistory';

const TrackerListItem = forwardRef(({ tracker }, ref) => {
  let link = `/tracking/${tracker.id}`;

  if (tracker.name === TRACKERS_SUB_TYPE.BREAST_FEEDING) {
    link = RELATIVE_PAGE_PATHS.BREAST_FEEDING;
  }
  if (tracker.name === TRACKERS_SUB_TYPE.SLEEP) {
    link = '/tracking/sleep';
  }
  if (tracker.name === TRACKERS_SUB_TYPE.CRY) {
    link = RELATIVE_PAGE_PATHS.CRY;
  }

  return (
    <Grid item ref={ref}>
      <PaidLink to={link} isPaid={tracker.isPaid}>
        <TrackerItem
          name={i18n.t(`trackers:trackingScreen:trackers.${tracker.name}`)}
          icon={navigationMethods.getSystemImageUrl(tracker.smallImage)}
          isPaid={tracker.isPaid}
        />
      </PaidLink>
    </Grid>
  );
});

const TrackerList = () => {
  const trackers = useSelector((state) =>
    orderBy(state.trackers, (x) => x.type.id + x.order * 1000)
  );
  const records = useSelector((state) =>
    orderBy(state.trackingRecords, (x) => new Date(x.date), ['desc'])
  );

  return (
    <>
      {trackers.length === 0 ? (
        <Typography
          variant="body2"
          className="details"
          sx={{ marginBottom: 4 }}
        >
          {i18n.t('trackers:trackingScreen:paid-msg')}
        </Typography>
      ) : (
        <>
          <Typography
            variant="body2"
            className="details"
            sx={{
              marginBottom: 2,
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            {i18n.t('trackers:trackingScreen:main_container_mgs')}

            <br />
            <Link component={RouterLink} to={'/consult'}>
              {i18n.t('trackers:trackingScreen:share_with_text')}
            </Link>
          </Typography>
        </>
      )}
      <Grid container justifyContent="center">
        {trackers.map((tracker, i) => {
          return <TrackerListItem key={tracker.name} tracker={tracker} />;
        })}
      </Grid>
      <TrackerTotalHistory />
    </>
  );
};

const PaidLink = ({ to, isPaid, children }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const paidTrackersMsg = i18n.t(
        'trackers:trackingScreen:paid-trackers.not-paid-message'
      );
      dispatch(showPaidProductsModal(paidTrackersMsg));
    },
    [dispatch]
  );

  if (!isPaid)
    return (
      <Link component={RouterLink} to={to}>
        {children}
      </Link>
    );
  return <div onClick={handleClick}>{children}</div>;
};

export default TrackerList;
