import React from 'react';

const MainMenu = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 28.35 28.35">
      <title>икона_хамбургер_меню_сива</title>
      <rect x="6.38" y="7.79" width="15.6" height="1.62" rx="0.81" fill="#999" />
      <rect x="6.38" y="18.93" width="15.6" height="1.62" rx="0.81" fill="#999" />
      <rect x="6.38" y="13.36" width="15.6" height="1.62" rx="0.81" fill="#999" />
    </svg>
  );
};

export default MainMenu;