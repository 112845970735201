import React, { useEffect, useState } from 'react';
import {
  RELATIVE_LINKS_PROD,
  RELATIVE_PAGE_PATHS,
  USER_DATA_IN_LOCAL_STORAGE,
} from '../../utils/constants';
import AdSlot from './AdSlot';
import { useLocation } from 'react-router-dom';

type IAdSlotSpecificPage = {};

const AdSlotSpecificPage: React.FC<IAdSlotSpecificPage> = ({}) => {
  const [isSubscribedToPaidStickers, setIsSubscribedToPaidStickers] =
    useState(false);
  const location = useLocation();

  useEffect(() => {
    const user = localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE);
    if (user) {
      const userData = JSON.parse(user);
      setIsSubscribedToPaidStickers(userData.isSubscribedToPaidStickers);
    }
  }, []);

  if (!localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE)) {
    return null;
  }

  const adTypeComponent = () => {
    switch (location.pathname) {
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.MEDICINE}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-0"
            path="/21663792601/Medical_Tracker"
            size={[[300, 250]]}
          />
        );
      case RELATIVE_PAGE_PATHS.CRY:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-0"
            path="/21663792601/Cry_Tracker"
            size={[[300, 250]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.FEEDING}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-0"
            path="/21663792601/Feeding_Tracker"
            size={[[300, 250]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.WEIGHT_TRACKER}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-0"
            path="/21663792601/Weight_Tracker"
            size={[[300, 250]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.BATHING}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-0"
            path="/21663792601/Bathing_Tracker"
            size={[[300, 250]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.TEMPERATURE}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-0"
            path="/21663792601/Temperature_Tracker"
            size={[[300, 250]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.SLEEP}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-0"
            path="/21663792601/Sleep_Tracker"
            size={[[300, 250]]}
          />
        );
      case RELATIVE_PAGE_PATHS.HOME:
        return <></>;
      default:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-0"
            path="/21663792601/Denvenik_Banner"
            size={[[300, 250]]}
          />
        );
    }
  };

  return <>{!isSubscribedToPaidStickers && adTypeComponent()}</>;
};

export default AdSlotSpecificPage;
