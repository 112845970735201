import React from 'react';
import navigationMethods from '../../../../utils/navigationUtils';
import { SubType } from '../MultiValueMeasurement';
type SystemType = 'user' | 'system';
type ImageType = 'addbutton' | 'option';

interface IAddIcon {
  src: string;
  hasImage: boolean;
  subType: SubType;
  systemType: SystemType;
  imageType: ImageType;
}

const noImagePatterns = [
  navigationMethods.getUserImageUrl(''),
  navigationMethods.getSystemImageUrl(''),
];
const CategoryIcon: React.FC<IAddIcon> = ({
  src,
  hasImage,
  subType,
  systemType,
  imageType,
}) => {
  return (
    <div
      style={{
        height: 40,
        width: 40,
        // marginLeft:
        //   (systemType == 'system' &&
        //     subType == 'feeding' &&
        //     imageType == 'option') ||
        //   imageType == 'addbutton'
        //     ? 0
        //     : 20,
        // marginRight:
        //   (systemType == 'system' &&
        //     subType == 'feeding' &&
        //     imageType == 'option') ||
        //   imageType == 'addbutton'
        //     ? 0
        //     : 20,
      }}
    >
      {hasImage && !noImagePatterns.filter((pattern) => pattern == src).length && (
        <img
          style={{
            height: 40,
            width: 40,
          }}
          alt=""
          src={src}
        />
      )}
    </div>
  );
};

export default CategoryIcon;
