import { ITrackerRecord } from '../../../interfaces/ITrackerRecord.interface';
import { TRACKER_SUBTYPES } from '../../../utils/constants';
import {
  IFeedingAndDiapersTableRow,
  IFeedingAndDiapersWithQuantity,
  IRecordsForDate,
} from './IFeedingAndDiapersAnalytics.interface';
import { TrackerTypes } from './constants';
import { calculateChildAge, getChildBirthDate } from './utils';
import moment from 'moment';

const filterByDatePeriod = (
  startDate: Date,
  endDate: Date,
  records: ITrackerRecord[]
) => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  return records
    .filter((record) => {
      const recordDate = moment(record.date).startOf('day');
      return recordDate >= start && recordDate <= end;
    })
    .map((entry) => ({ ...entry, date: new Date(entry.date) }));
};

const existedRecord = (
  records: IRecordsForDate[],
  dateValue: Date
): IRecordsForDate | undefined =>
  records.find((record) =>
    moment(record.date).startOf('day').isSame(moment(dateValue).startOf('day'))
  );

const convertToTableFormat = (
  data: IRecordsForDate[],
  startingWeight: number
): IFeedingAndDiapersTableRow[] => {
  const tableDataWithSeparatedQuantities: IFeedingAndDiapersWithQuantity[] = [];

  let lastWeight = startingWeight || 0;
  data.forEach((entry) => {

    const feedingAndDiapersWithQuantity: IFeedingAndDiapersWithQuantity = {
      dateAndAge: `${moment(entry.date).format('DD/MM')}-${calculateChildAge(moment(entry.date))}`,
      weight: lastWeight,
      wetDiapers: 0,
      pooDiapers: 0,
      breastFeedings: 0,
      bottlesBreastMilk: 0,
      breastMilkQuantity: 0,
      bottlesFormula: 0,
      formulaQuantity: 0,
    };

    entry.records.forEach((record) => {
      switch (record.type) {
        case TrackerTypes.weight:
          feedingAndDiapersWithQuantity.weight = record.weightQuantity / 1000;
          lastWeight = feedingAndDiapersWithQuantity.weight;
          break;
        case TrackerTypes.wetDiaper:
          feedingAndDiapersWithQuantity.wetDiapers += record.quantity;
          break;
        case TrackerTypes.pooDiaper:
          feedingAndDiapersWithQuantity.pooDiapers += record.quantity;
          break;
        case TrackerTypes.breastFeeding:
          feedingAndDiapersWithQuantity.breastFeedings += record.quantity;
          break;
        case TrackerTypes.bottleBreastMilk:
          feedingAndDiapersWithQuantity.bottlesBreastMilk += record.quantity;
          feedingAndDiapersWithQuantity.breastMilkQuantity +=
            record.foodQuantity as number;
          break;
        case TrackerTypes.bottleFormula:
          feedingAndDiapersWithQuantity.bottlesFormula += record.quantity;
          feedingAndDiapersWithQuantity.formulaQuantity +=
            record.foodQuantity as number;
          break;
        default:
          break;
      }
    });
    tableDataWithSeparatedQuantities.push(feedingAndDiapersWithQuantity);
  });

  const tableData: IFeedingAndDiapersTableRow[] = [];
  tableDataWithSeparatedQuantities.forEach((entry) => {
    const tableRow: IFeedingAndDiapersTableRow = {
      dateAndAge: entry.dateAndAge,
      weight: entry.weight,
      wetDiapers: entry.wetDiapers,
      pooDiapers: entry.pooDiapers,
      breastFeedings: entry.breastFeedings,
      totalBreastMilk: `${entry.bottlesBreastMilk}/${entry.breastMilkQuantity}`,
      totalFormula: `${entry.bottlesFormula}/${entry.formulaQuantity}`,
    };
    tableData.push(tableRow);
  });
  return tableData;
};

/**
 *
 * @param startDate start date from calendar picker as string
 * @param endDate end date from calendar picker as string
 * @param records array with all records
 * @returns  Array with elements of type IFeedingAndDiapersTableRow with all records for every date between start and end date selected in the calendar.
 */
export const extractRecordsForTable = (
  startDate: Date,
  endDate: Date,
  records: ITrackerRecord[]
): IFeedingAndDiapersTableRow[] => {
  const filteredRecords = filterByDatePeriod(startDate, endDate, records);

  const startingWeight = getStartingWeightForPeriod(startDate, records);

  const tableRecords: IRecordsForDate[] = [];
  filteredRecords.forEach((record) => {
    const objectWithDate = existedRecord(tableRecords, record.date);
    objectWithDate
      ? //if object with specified date, add the record to it
      objectWithDate.records.push({
        type: record.subType,
        quantity: 1,
        foodQuantity:
          record.subType === TrackerTypes.bottleBreastMilk ||
            record.subType === TrackerTypes.bottleFormula
            ? record.quantity
            : null,
        weightQuantity:
          record.subType === TrackerTypes.weight ? record.quantity : null,
      })
      : //if no object with the specified date create one
      tableRecords.push({
        date: record.date.toISOString(),
        records: [
          {
            type: record.subType,
            quantity: 1,
            foodQuantity:
              record.subType === TrackerTypes.bottleBreastMilk ||
                record.subType === TrackerTypes.bottleFormula
                ? record.quantity
                : null,
            weightQuantity:
              record.subType === TrackerTypes.weight ? record.quantity : null,
          },
        ],
      });
  });

  return convertToTableFormat(tableRecords, startingWeight);
};

const getStartingWeightForPeriod = (
  startDate: Date,
  records: ITrackerRecord[]
) => {
  const recordsBeforeStartPeriod = filterByDatePeriod(
    getChildBirthDate().toDate(),
    startDate,
    records
  ).filter((record) => record.subType === TRACKER_SUBTYPES.WEIGHT);

  return (
    recordsBeforeStartPeriod[recordsBeforeStartPeriod.length - 1]?.quantity /
    1000 ?? 0
  );
};
