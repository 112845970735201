import { useEffect, useState } from 'react';
import { getAxiosWithToken } from '../../utils/webApi';
import { startLoader, stopLoader } from '../../actions/commonActions';
import DOMPurify from 'dompurify';
import { useDispatch } from 'react-redux';
import { LANGUAGE_CODE_KEY } from '../../utils/constants';

const TermsAndConditions: React.FC<string> = () => {
  const [termsAndConditions, setTermsAndConditions] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  const getTermsAndConditions = async () => {
    dispatch(startLoader());
    const response = await getAxiosWithToken().get('policies/all');
    const languageBasedPolicies = response.data.data.filter(
      (policies) =>
        policies.languageCode === localStorage.getItem(LANGUAGE_CODE_KEY)
    );

    dispatch(stopLoader());

    if (response.status === 200) {
      setTermsAndConditions(
        DOMPurify.sanitize(languageBasedPolicies[0].termsAndConditions)
      );
    }
  };
  return (
    <div
      id="terms-and-conditions"
      style={{ marginBottom: '80px' }}
      dangerouslySetInnerHTML={{ __html: termsAndConditions }}
    />
  );
};

export default TermsAndConditions;
