import React from 'react';
import navigationMethods from '../../../utils/navigationUtils';

interface ITrackerIcon {
  trackerIcon: string;
}

const TrackerIcon: React.FC<ITrackerIcon> = ({ trackerIcon }) => {
  return (
    <img
      alt={`icon-${trackerIcon}`}
      src={navigationMethods.getSystemImageUrl(`${trackerIcon}.svg`)}
      width={'35.78px'}
      height={'35.78px'}
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => {
        // hover logic
      }}
    />
  );
};

export default TrackerIcon;
