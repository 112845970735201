const DownloadJournal = ({ width, height }) => {
  const viewBoxValues = '0 0 164 166';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBoxValues}
    >
      <defs>
        <filter
          id="Rectangle_1973"
          x="0"
          y="0"
          width="164"
          height="164"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1" result="blur" />
          <feFlood floodOpacity="0.157" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Квадрат_с_картинка_-_изтегли_дневник"
        data-name="Квадрат с картинка - изтегли дневник"
        transform="translate(-106 -112)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 106, 112)"
          filter="url(#Rectangle_1973)"
        >
          <g
            id="Rectangle_1973-2"
            data-name="Rectangle 1973"
            transform="translate(3 2)"
            fill="#fff"
            stroke="#cacaca"
            strokeWidth="2"
          >
            <rect width="158" height="158" rx="11" stroke="none" />
            <rect x="1" y="1" width="156" height="156" rx="10" fill="none" />
          </g>
        </g>
        <g id="Гълъб" transform="translate(6.468 -375.284)">
          <g
            id="Group_1541"
            data-name="Group 1541"
            transform="translate(121.645 524.999)"
          >
            <path
              id="Path_1616"
              data-name="Path 1616"
              d="M2429.092-1403.1s-33.756,14.945-38,18.087c-5.722,4.239-11.648,12.59-5.527,19.4s15.687,4.89,20.438-.115C2412.157-1372.216,2429.092-1403.1,2429.092-1403.1Z"
              transform="translate(-2313.973 1412.836)"
              fill="#5caca3"
            />
            <path
              id="Path_1617"
              data-name="Path 1617"
              d="M2341.468-1379.334c-2.12-6.926,3.089-15.244,10.111-18.224a16.117,16.117,0,0,1,18.7,4.029c6.962,7.651,16.317,17.989,23.182,20.7,7.953,3.144,35.169-5.638,35.169-5.638s-4.777,23.748-18.36,33.879c-9.324,6.953-21.643,8.13-32.457,5.258-9.542-2.534-17.967-11.475-22.111-19.3S2343.587-1372.409,2341.468-1379.334Z"
              transform="translate(-2309.849 1412.429)"
              fill="#bde8d5"
            />
            <path
              id="Path_1618"
              data-name="Path 1618"
              d="M2416.477-1413.9s-29.41,22.312-32.816,26.348c-4.594,5.441-8.438,14.933-.915,20.146s16.391,1.149,19.863-4.816C2407.1-1379.943,2416.477-1413.9,2416.477-1413.9Z"
              transform="translate(-2313.532 1413.896)"
              fill="#67c1b6"
            />
            <g
              id="Group_1540"
              data-name="Group 1540"
              transform="translate(0 30.679)"
            >
              <path
                id="Path_1619"
                data-name="Path 1619"
                d="M2326.59-1379.414l-19.574,18.091a1.742,1.742,0,0,0-.128,2.426l30.046,34.318a1.742,1.742,0,0,0,2.542.085l26.082-26.056a1.742,1.742,0,0,0-.212-2.645l-36.555-26.352A1.742,1.742,0,0,0,2326.59-1379.414Z"
                transform="translate(-2306.458 1379.876)"
                fill="#c999cb"
              />
              <path
                id="Path_1620"
                data-name="Path 1620"
                d="M2331.2-1379.55l-2.908,24.291,39.2,2.1Z"
                transform="translate(-2308.603 1379.844)"
                fill="#c167b8"
              />
            </g>
            <path
              id="Path_1621"
              data-name="Path 1621"
              d="M2341.59-1375.822l-4.076,10.13a.639.639,0,0,0,.67.874l10.107-1.225a.641.641,0,0,0,.548-.5c.363-1.594,1-7.057-6.447-9.652A.643.643,0,0,0,2341.59-1375.822Z"
              transform="translate(-2309.503 1410.197)"
              fill="#ffd390"
            />
            <circle
              id="Ellipse_921"
              data-name="Ellipse 921"
              cx="1.209"
              cy="1.209"
              r="1.209"
              transform="translate(42.509 26.48)"
              fill="#3d3e40"
            />
          </g>
          <text
            id="_"
            data-name="@"
            transform="translate(151.423 596.214) rotate(40)"
            fill="#fff"
            fontSize="15"
            fontFamily="SegoeUI, Segoe UI"
          >
            <tspan x="-7.163" y="0">
              @
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export default DownloadJournal;
