import { FC } from 'react';
import { TRACKERS_SUB_TYPE } from '../../../utils/constants';
import { BreastFeedingRightButton } from '../breast-feeding/BreastFeedingRightButon';
import { SleepNightButton } from '../sleep/SleepNightButton';
import { BUTTON_SIDES, TrackingButtonProps } from './models';
import { useTrackingButtonProps } from './hooks/useTrackingButtonProps';

const buttonSide = BUTTON_SIDES.RIGHT;
export const RightButton: FC<TrackingButtonProps> = (props) => {
  const newProps = useTrackingButtonProps(buttonSide, props);
  const { tracker } = props;

  switch (tracker) {
    case TRACKERS_SUB_TYPE.SLEEP:
      return <SleepNightButton {...newProps} />;
    case TRACKERS_SUB_TYPE.BREAST_FEEDING:
      return <BreastFeedingRightButton {...newProps} />;
    case TRACKERS_SUB_TYPE.CRY:
      return <SleepNightButton {...newProps} />;
    default:
      return null;
  }
};
