import { useState, useEffect, Fragment } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  CANCELED,
  SELECTED_PLAN_ID,
  STARTING_POINT_URL,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import {
  showInfoModal,
  startLoader,
  stopLoader,
  showConfirmModal,
  closeModal,
} from '../../actions';
import { getAxiosWithToken } from '../../utils/webApi';
import * as i18n from 'i18next';
import './SubscriptionPlans.css';
import SubscriptionCard from './SubscriptionCard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const SubscriptionPlans = () => {
  const [prices, setPrices] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [isUnlockedExternally, setIsUnlockedExternally] = useState(false);
  const [isSubscriptionInRenewalPeriod, setIsSubscriptionInRenewalPeriod] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState(null);

  const loggedInUserEmail = useSelector(
    (state) => state.authentication.userData.email
  );
  const userId = useSelector((state) => state.authentication.userData.id);

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {

    sessionStorage.removeItem(SELECTED_PLAN_ID);

    getStripeCustomer();
    getAllSubscriptionPlans();
    getUserData();

  }, []);

  useEffect(() => {
    if (customer) {
      getUserActivePlan();
    }

  }, [customer]);

  useEffect(() => {
    setDefaultSelectedPlan();

  }, [prices, activeSubscription]);

  const getUserData = async () => {
    const { data } = await getAxiosWithToken().get(`user/${userId}`);
    setIsUnlockedExternally(data.data.isSubscribedToPaidStickers);
  };

  const setDefaultSelectedPlan = () => {
    if (activeSubscription) {
      setSelectedPlan(null);
      return;
    }

    const planWithDuration14 = prices.find(
      (price) => +price.product.metadata.for === 14
    );
    if (planWithDuration14) setSelectedPlan(planWithDuration14);
  };

  const getStripeCustomer = async () => {
    try {
      const { data } = await getAxiosWithToken().post(
        'billing/create-customer',
        { email: loggedInUserEmail }
      );
      setCustomer(data.customer);
    } catch (error) {
      console.error(error);
      setError(i18n.t('payments:errorLoadingPlans'));
    }
  };

  const getAllSubscriptionPlans = async () => {
    try {
      const { data } = await getAxiosWithToken().get(
        `billing/subscription-plans/${userId}`
      );

      if (data.prices.length > 0) {
        setPrices(
          data.prices.sort(
            (a, b) =>
              parseInt(a.product.metadata.sortKey, 10) -
              parseInt(b.product.metadata.sortKey, 10)
          )
        );
      } else {
        return <div>{i18n.t('payments:noPlansMsg')}</div>;
      }
    } catch (error) {
      console.error(error);
      setError(i18n.t('payments:errorLoadingPlans'));
    }
  };

  const getUserActivePlan = async () => {
    dispatch(startLoader());

    try {
      let expiryDate = null;
      const subscriptionResponse = await getAxiosWithToken().post(
        'billing/user-subscriptions',
        { email: loggedInUserEmail }
      );
      if (subscriptionResponse.data.subscriptions.length) {
        setActiveSubscription(subscriptionResponse.data.subscriptions[0]);
        expiryDate = moment(
          subscriptionResponse.data.subscriptions[0].cancelAt
        );
      } else {
        const packageResponse = await getAxiosWithToken().post(
          'billing/user-subscription-from-database',
          userId
        );
        expiryDate = moment(packageResponse.data.cancelAt);
        if (packageResponse.data) setActiveSubscription(packageResponse.data);
      }

      const currentDate = moment();
      const hoursUntilExpiry = expiryDate.diff(currentDate, 'hours');
      const daysUntilExpiry = hoursUntilExpiry / 24;
      setIsSubscriptionInRenewalPeriod(
        daysUntilExpiry >= 0 && daysUntilExpiry <= 7
      );
    } catch (error) {
      console.error(error);
      setError(i18n.t('payments:errorLoadingPlans'));
    } finally {
      dispatch(stopLoader());
    }
  };

  const createSubscription = async () => {
    dispatch(startLoader());

    if (isSubscriptionInRenewalPeriod && activeSubscription) {
      setSubscription({
        priceId: selectedPlan.id,
        customer,
        isSubscriptionInRenewalPeriod,
      });
    } else if (activeSubscription || isUnlockedExternally)
      dispatch(showInfoModal(i18n.t('payments:haveActivePlan')));
    else {
      setSubscription({
        priceId: selectedPlan.id,
        customer,
        isSubscriptionInRenewalPeriod,
      });
    }
    dispatch(stopLoader());
  };

  const cancelSubscription = () => {
    try {
      dispatch(
        showConfirmModal({
          message: i18n.t('payments:confirmCancelPlan'),
          onConfirm: async () => {
            dispatch(startLoader());

            const response = await getAxiosWithToken().post(
              `billing/cancel-subscription?user_id=${userId}`,
              { subscriptionId: activeSubscription.id ?? null }
            );
            if (
              response.data.subscription &&
              response.data.subscription.status === CANCELED
            ) {
              setActiveSubscription(null);
              setIsUnlockedExternally(false);
              sessionStorage.removeItem(STARTING_POINT_URL);
              dispatch(showInfoModal(i18n.t('payments:cancelPlanMsg')));
            }

            dispatch(closeModal());
            dispatch(stopLoader());
          },
          onDeny: () => {
            dispatch(closeModal());
          },
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(showInfoModal(i18n.t('payments:errorCancelSubscription')));
    }
  };

  if (subscription)
    history.push({
      pathname: '/payments/checkout',
      state: { subscription, userId },
    });

  if (error) return <div>{error}</div>;

  return (
    <div className="plans-container">
      <div className="info-text brd-rds10">
        <h4 className="info-header capital">
          {t('payments:unlockJournalTitle')
            .split('\n')
            .map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
        </h4>

        <ul className="info-list">
          <li>{t('payments:infoList.item1')}</li>
          <li>{t('payments:infoList.item2')}</li>
          <li>{t('payments:infoList.item3')}</li>
          <li>{t('payments:infoList.item4')}</li>
        </ul>
      </div>

      <div className="access-header">{t('payments:youReceive')}</div>

      <ul className="info-list info-list-desktop list-width">
        <li>{t('payments:infoList.item5')}</li>
        <li>{t('payments:infoList.item6')}</li>
        <li>{t('payments:infoList.item7')}</li>
        <li>{t('payments:infoList.item8')}</li>
        <li>{t('payments:infoList.item9')}</li>
        <li>{t('payments:infoList.item10')}</li>
      </ul>

      <div className="subscription-plans">
        <div className="plans-list">
          {prices &&
            prices.map((price) => {
              return (
                price.product.active && (
                  <SubscriptionCard
                    key={price.id}
                    isSelected={selectedPlan?.id === price.id}
                    plan={price}
                    withDescription={true}
                    onClick={() => setSelectedPlan(price)}
                  />
                )
              );
            })}
        </div>

        <Button
          variant="contained"
          disabled={!selectedPlan}
          onClick={createSubscription}
        >
          {selectedPlan
            ? +selectedPlan.product.metadata.for === 1
              ? i18n.t('payments:payMonthly')
              : i18n.t('payments:payFor', {
                  months: selectedPlan.product.metadata.for,
                })
            : i18n.t('payments:selectPlan')}
        </Button>
      </div>
      <hr />

      <h4 className="my-plan">{i18n.t('payments:myPlan')}</h4>
      {activeSubscription && (
        <div className="active-plan">
          <div className="active-plan-content">
            <SubscriptionCard
              plan={
                activeSubscription.id
                  ? activeSubscription.items[0].price
                  : activeSubscription.price
              }
              withDescription={false}
            />
            <div className="plan-expire-date">
              {i18n.t('payments:valid')}{' '}
              {moment(activeSubscription.cancelAt).format('DD/MM/YYYY')}
            </div>
          </div>
          <button className="cancel-btn" onClick={cancelSubscription}>
            {i18n.t('payments:cancelPlan')}
          </button>
        </div>
      )}
      {!activeSubscription && (
        <div className="no-subscription">
          {isUnlockedExternally
            ? i18n.t('payments:isUnlockedFromOtherSource')
            : i18n.t('payments:noActivePlan')}
        </div>
      )}
      <div className="user-comments user-comments-desktop">
        <h5 className="comment-header">{t('payments:whatParentsSay')}</h5>
        <p className="comment">
          {t('payments:clientComments.comment1')
            .split('\n')
            .map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
        </p>
        <div className="client">{t('payments:clientComments.client1')}</div>
        <hr />
        <p className="comment">
          {t('payments:clientComments.comment2')
            .split('\n')
            .map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
        </p>
        <div className="client">{t('payments:clientComments.client2')}</div>
        <hr />
        <p className="comment">
          {t('payments:clientComments.comment3')
            .split('\n')
            .map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
        </p>
        <div className="client">{t('payments:clientComments.client3')}</div>
        <hr />
        <p className="comment">
          {t('payments:clientComments.comment4')
            .split('\n')
            .map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
        </p>
        <div className="client">{t('payments:clientComments.client4')}</div>
      </div>
    </div>
  );
};

export default withRouter(SubscriptionPlans);
