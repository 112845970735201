import Lock from '../common/svgComponents/GoalsAndTagsIcons/Lock';

const TrackerItem = ({ name, icon, isPaid }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        marginTop: 3,
        width: 70,
      }}
    >
      <img alt={name} src={icon} width={'55px'} height={'55px'} />
      {isPaid && (
        <Lock
          style={{ position: 'absolute', borderRadius: '50%', height: '55px' }}
        />
      )}
    </div>
  );
};

export default TrackerItem;
