import React from 'react';
import { IProductDialog } from '../IProductDialogInterface.interface';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import navigationMethods from '../../../../utils/navigationUtils';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import {
  isImageBelowMaxSize,
  isSupportedImageFormat,
} from '../../../../utils/commonUtils';
import { STICKER_FILE_SIZE_LIMIT_IN_MB } from '../../../../utils/constants';
import { useDispatch } from 'react-redux';
import { showErrorModal, showSuccessModal } from '../../../../actions';
import { getAxiosWithToken } from '../../../../utils/webApi';
import { MODAL_DIALOG_STATE, useFormField } from '../utils';

const EditProductDialog: React.FC<IProductDialog> = ({
  currentCustomProductSelected,
  selectedCategoryId,
  setModalDialogState,
  loadCategoryItems,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { input: inputProduct } = useFormField('product');
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClose = () => {
    setImagePreviewUrl(null);
    setFile(null);
    setModalDialogState(MODAL_DIALOG_STATE.CLOSED);
  };

  const chooseFile = (event: React.MouseEvent) => {
    // event.preventDefault();
    if (inputRef.current && event.currentTarget.id != 'filechose_button') {
      inputRef.current.click();
    }
  };

  const onChangeFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const selectedFile = event.target.files?.[0]; // Use optional chaining

    if (selectedFile) {
      let reader = new FileReader();
      // Rest of your code
      if (selectedFile && selectedFile.size) {
        let maxSizeExceeded = isImageBelowMaxSize(selectedFile.size);

        if (!maxSizeExceeded) {
          const message = t('stickers:addStickerScreen:maxSizeExceeded', {
            mb: STICKER_FILE_SIZE_LIMIT_IN_MB,
          });
          handleClose();
          dispatch(showErrorModal(message));

          return;
        }
      }

      if (selectedFile && selectedFile.type) {
        let isImageFormatSupported = isSupportedImageFormat(selectedFile.type);

        if (!isImageFormatSupported) {
          const message = t(
            'stickers:addStickerScreen:imageFormatNotSupported'
          );
          handleClose();
          dispatch(showErrorModal(message));

          return;
        }
      }

      reader.onload = () => {
        //@ts-ignore
        setImagePreviewUrl(reader.result);
        setFile(selectedFile);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const onSubmit = async ({ product }: { product: string }, form: any) => {
    const formData = new FormData();

    const data = {
      productName: product,
      productCategoryItem: selectedCategoryId,
    };
    formData.append('json-data', JSON.stringify(data));
    if (file) {
      formData.append('photo-file', file);
    }

    const response = await getAxiosWithToken(true).put(
      `categoryitem/products/${currentCustomProductSelected?.id}`,
      formData
    );

    handleClose();
    if (response.data.success) {
      dispatch(
        showSuccessModal(t('trackers:productDialog:successfulEditedItem'))
      );
    } else if (response.data.code === 406) {
      const { userFriendlyMessage } = response.data;
      dispatch(showErrorModal(userFriendlyMessage));
    }
    loadCategoryItems();
    inputProduct.onChange('');
  };

  const uploadImgContent = imagePreviewUrl ? (
    <img
      style={{ height: 'inherit' }}
      alt="new-product-img"
      src={imagePreviewUrl}
      className="image-preview"
    />
  ) : (
    <img
      style={{ height: 'inherit' }}
      alt="product-img"
      src={navigationMethods.getUserImageUrl(
        currentCustomProductSelected?.photoThumbnail
      )}
      className="image-preview"
    />
  );

  return (
    <>
      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        onClose={handleClose}
        open={true}
      >
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              id="product-dialog-form"
            >
              <DialogContent style={{ padding: '20px 24px 0px 24px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '5px',
                  }}
                >
                  <div
                    className="itemUploadImg photo-container-holder"
                    onClick={chooseFile}
                    id="filechose_button"
                  >
                    {uploadImgContent}
                    <input
                      ref={inputRef}
                      className={'uploader'}
                      type="file"
                      onChange={(e) => {
                        onChangeFileUpload(e);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    color: '#6c757d',
                    fontSize: 12,
                    textAlign: 'center',
                    marginBottom: '15px',
                  }}
                >
                  {t('trackers:productDialog:notNecessaryUpload')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Field
                    name="product"
                    defaultValue={currentCustomProductSelected?.productName}
                  >
                    {({ input }) => (
                      <TextField
                        label={t('trackers:productDialog:textfieldLabel')}
                        variant="outlined"
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <DialogActions
                  style={{
                    padding: '15px 8px 15px 8px',
                    justifyContent: 'space-around',
                  }}
                >
                  <Button
                    variant="contained"
                    className="delete-button"
                    onClick={handleClose}
                  >
                    {t('trackers:productDialog:cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="save-button"
                  >
                    {t('trackers:productDialog:save')}
                  </Button>
                </DialogActions>
              </DialogContent>
            </form>
          )}
        </Form>
      </Dialog>
    </>
  );
};

export default EditProductDialog;
