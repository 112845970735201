import { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

interface IJournalMonthProps extends SVGProps<SVGSVGElement> {
  number: number;
  fill: string;
  afterOneYearOld: boolean;
  className: string;
}

const MonthNumber: React.FC<IJournalMonthProps> = ({
  number,
  fill,
  afterOneYearOld,
  className,
}) => {
  const viewBoxValues = '0 0 292.5 52';
  const transformNumber =
    number > 9 ? 'translate(166 239)' : 'translate(175 239)';

  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBoxValues}
      className={className}
    >
      <g
        id="_0_месеца_-_сиво"
        data-name="0 месеца - сиво"
        transform="translate(-43.5 -211)"
      >
        <g
          id="Кръгче_-_1_месец"
          data-name="Кръгче - 1 месец"
          transform="translate(8)"
        >
          <path
            id="Path_1742"
            data-name="Path 1742"
            d="M26.5,0A26.254,26.254,0,0,1,53,26,26.254,26.254,0,0,1,26.5,52,26.254,26.254,0,0,1,0,26,26.254,26.254,0,0,1,26.5,0Z"
            transform="translate(155 211)"
            fill={fill}
          />
          <text
            id="_0"
            data-name="0"
            transform={transformNumber}
            fill="#fff"
            fontSize="23"
            fontFamily="Nunito-Bold, Nunito"
            fontWeight="700"
          >
            <tspan x={afterOneYearOld ? -6 : number > 9 ? 1.5 : -1} y="0">
              {afterOneYearOld ? number + '+' : number}
            </tspan>
          </text>

          <text
            id="svgText"
            transform="translate(182 250)"
            fill="#fff"
            fontSize="8"
            fontFamily="Nunito-Regular, Nunito"
          >
            <tspan
              x={
                afterOneYearOld
                  ? '-8.000'
                  : number === 1
                  ? '-12.412'
                  : '-13.412'
              }
              y="0"
            >
              {!afterOneYearOld
                ? number === 1
                  ? t('stickers:journalScreen.month')
                  : t('stickers:journalScreen.months')
                : t('stickers:journalScreen.year')}
            </tspan>
          </text>

          <line
            id="Line_75"
            data-name="Line 75"
            x2="103"
            transform="translate(224.5 237.5)"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="Line_74"
            data-name="Line 74"
            x2="103"
            transform="translate(36 237.5)"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default MonthNumber;
