import React, { useState, useEffect } from 'react'; // Added useState and useEffect import
import PoopDiaperFull from '../../common/icons/PoopDiaperFull';
import PoopDiaperLow from '../../common/icons/PoopDiaperLow';
import PoopDiaperMedium from '../../common/icons/PoopDiaperMedium';
import TrackerSlider from '../shared/TrackerSlider';
import * as i18n from 'i18next';
import { Box } from '@mui/system';

const getMarks = () => [
  {
    value: 0,
    label: i18n.t('trackers:trackingScreen:change-diapers.marks.0'),
  },
  {
    value: 1,
    label: i18n.t('trackers:trackingScreen:change-diapers.marks.1'),
  },
  {
    value: 2,
    label: i18n.t('trackers:trackingScreen:change-diapers.marks.2'),
  },
];

const icons = {
  'poo-diaper': [
    <PoopDiaperLow fontSize="inherit" />,
    <PoopDiaperMedium fontSize="inherit" />,
    <PoopDiaperFull fontSize="inherit" />,
  ],
};

const images = (subType, id) => {
  if (icons[subType]) return icons[subType][id];
};

const DiaperSlider = ({ subType, ...rest }) => {
  const [marks, setMarks] = useState(getMarks());

  useEffect(() => {
    const handleLanguageChange = () => {
      setMarks(getMarks());
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <TrackerSlider
      marks={marks}
      min={0}
      max={2}
      step={1}
      subType={subType}
      {...rest}
    >
      {(value) => (
        <Box lineHeight="1.15" fontSize="64px">
          {images(subType, value)}
        </Box>
      )}
    </TrackerSlider>
  );
};

export default DiaperSlider;
