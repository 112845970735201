import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ADD_EVENT_LISTENER } from '../actions/actionTypes';
import 'dotenv/config';
import banner from '../images/icons/big_home_icon.png';
import safariTabicon from '../images/icons/safari-tab-icon.png';
import safariAddicon from '../images/icons/safari-add-icon.png';
import { useTranslation } from 'react-i18next';

export default function HomeScreenComponent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    let popupCountLimit =
      process.env.REACT_APP_SHOWING_ADD_SHORTCUT_POPUP_COUNT_LIMIT;
    let popUpCounter = localStorage.getItem('popUpCounter')
      ? Number(localStorage.getItem('popUpCounter'))
      : Number(localStorage.setItem('popUpCounter', 0));

    let deferredPrompt;
    const a2hsBtn = document.querySelector('.add-button');
    const pwaiOSText = document.getElementById('pwa-ios-text');
    const mainPWAcontainer = document.getElementById('main-pwa-container');
    const pwaContainer = document.getElementById('pwa-container');
    const closeA2hsBtn = document.getElementById('close-a2hs-Btn');

    const isIOSdevice =
      !!navigator.platform &&
      /iPad|iPhone|iPod/.test(
        navigator.userAgent || navigator.vendor || navigator.platform
      );
    const isiPadDevice =
      navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;

    const isAndroidDevice = /(android)/i.test(navigator.userAgent);
    const isPWAInstalled = window.matchMedia(
      '(display-mode: standalone)'
    ).matches;

    if (
      (isIOSdevice || isiPadDevice) &&
      !isPWAInstalled &&
      popUpCounter < popupCountLimit
    ) {
      mainPWAcontainer.style.display = 'flex';
      a2hsBtn.style.display = 'none';
      pwaiOSText.style.display = 'block';

      if (isiPadDevice) {
        pwaContainer.style.width = '50%';
      }
    } else if (isAndroidDevice && !isPWAInstalled) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        dispatch({ type: ADD_EVENT_LISTENER, payload: e });
        // Stash the event so it can be triggered later.
        if (popUpCounter < popupCountLimit) {
          deferredPrompt = e;
          mainPWAcontainer.style.display = 'flex';

          // Update UI to notify the user they can add to home screen
          a2hsBtn.addEventListener('click', (e) => {
            // hide our user interface that shows our A2HS button
            mainPWAcontainer.style.display = 'none';
            // Show the prompt
            deferredPrompt.prompt();
          });
        }
      });
    }

    // Event for closing Add To Home Screen banner
    closeA2hsBtn.addEventListener('click', (e) => {
      mainPWAcontainer.style.display = 'none';
      localStorage.setItem('popUpCounter', popUpCounter + 1);
    });
  }, []);

  return (
    <div id="main-pwa-container" className="flexContainerColumn centred">
      <div
        id="pwa-container"
        className="flexContainerColumn flexContainerCentered"
      >
        <div className="card">
          <button className="close" id="close-a2hs-Btn">
            <span aria-hidden="true">×</span>
          </button>
          <div className="pwa-header">
            <img id="pwa-banner-logo" alt="" src={banner} />
            <p id="pwa-header-text">{t('homeScreenButton:addToPhoneScreen')}</p>
          </div>
          <div className="card-body">
            <p id="pwa-a2hs-text">{t('homeScreenButton:addToScreenInfo')}</p>
            <p id="pwa-ios-text">
              {t('homeScreenButton:push')}
              <img id="safari-tab-icon" alt="" src={safariTabicon} />
              {t('homeScreenButton:chooseOption')}
              <b>{t('homeScreenButton:addToHomeScreen')}</b>
              <img id="safari-add-icon" alt="" src={safariAddicon} />.
            </p>
            <button className="greenBtn header next uppercase btn btn-secondary add-button">
              {t('homeScreenButton:add')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
