import { CLEAR_REDUCERS, LOAD_TRACKING_RECORDS, CLEAR_TRACKING_RECORDS, UPDATE_TRACKING_RECORD } from '../actions/actionTypes';

const initialState = [];

const trackingRecordsReducer = (state = [...initialState], action) => {
  switch (action.type) {
    case LOAD_TRACKING_RECORDS:
      const newRecords = action.payload.filter(record => !state.some(existing => existing.id === record.id));
      return [...state, ...newRecords];
    case UPDATE_TRACKING_RECORD:
      return state.map(record =>
        record.id === action.payload.id ? { ...record, ...action.payload } : record
      );
    case CLEAR_TRACKING_RECORDS:
      return state.filter(record => record.id !== action.payload);
    case CLEAR_REDUCERS:
      state = [...initialState];
      return state;
    default:
      return state;
  }
};

export default trackingRecordsReducer;
