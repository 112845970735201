import navigationMethods from '../../../utils/navigationUtils';

const LogoDMT = () => {
  return (
    <img
      style={{ margin: '24px 0 10px 0' }}
      width={125}
      height={125}
      src={navigationMethods.getSystemImageUrl('home-screen-logo.svg')}
      alt="home-logo"
    />
  );
};

export default LogoDMT;
