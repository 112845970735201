import navigationMethods from '../../../utils/navigationUtils';
import navigationUtils from '../../../utils/navigationUtils';

interface ITrackerSeparator {
  trackerLink?: string;
  trackerIcon: string;
  trackerType?: string;
}

const TrackerSeparator = (props: ITrackerSeparator) => {
  return (
    <>
      <div className="icon-separator">
        <hr
          className="separator-line"
          style={{
            marginRight: '10px',
            backgroundColor: `${props.trackerType ? '#83BBE1' : '#87ba3b'}`,
          }}
        />
        <img
          alt={'icon'}
          src={navigationMethods.getSystemImageUrl(props.trackerIcon)}
          width={'55px'}
          height={'55px'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (props.trackerLink) {
              navigationUtils.navigate(props.trackerLink);
            }
          }}
        />
        <hr
          className="separator-line"
          style={{
            marginLeft: '10px',
            backgroundColor: `${props.trackerType ? '#83BBE1' : '#87ba3b'}`,
          }}
        />
      </div>
    </>
  );
};

export default TrackerSeparator;
