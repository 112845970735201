import * as i18n from 'i18next';
import moment from 'moment';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container } from 'reactstrap';
import { getFormValues } from 'redux-form';
import { getAddedSticker, loadSticker } from '../../actions';
import {
  getActiveColor,
  isCelebrationsTag,
  isExistingSticker,
  isShareButtonDisabled,
  isWorriesTag,
  scrollToTop,
} from '../../utils/commonUtils';
import {
  DEFAULT_BACKEND_DATE_FORMAT,
  EMPTY_STRING,
  FORMS,
  FOUR_WEEKS_BEFORE_DUE_DATE,
  LANGUAGE_CODE_KEY,
  RELATIVE_PAGE_PATHS,
} from '../../utils/constants';
import FacebookButton from '../common/FacebookButton';
import StickerDateAndDescription from './StickerDateAndDescription';
import StickerSection from './StickerSection';

class AddStickerScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unActiveColor: null,
      unActiveTextColor: null,
      borderColor: null,
      backgroundColor: null,
      expiredBorderColor: null,
      expiredBackgroundColor: null,
    };
  }

  componentDidMount() {
    scrollToTop();
    // Avoiding device keyboard
    const datePickers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );
    Array.from(datePickers).forEach((el) =>
      el.childNodes[0].setAttribute('readOnly', true)
    );
  }

  componentWillMount() {
    // Note: The sticker ID (for the AddedSticker) will be part of the query string
    // only when we are editing the sticker. If we are adding a new sticker there
    // will be no ID passed in the URL. The sticker data (StickerTemplate)
    // will be available through the Redux store instead
    const stickerId = this.props.match.params.id;
    const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
    if (isExistingSticker()) {
      this.props.getAddedSticker(stickerId);
    } else {
      this.props.loadSticker(stickerId, languageCode);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedStickerTemplate.area) {
      const selectedGoal = this.props.selectedStickerTemplate.area.tag.goal;

      if (
        this.props.selectedStickerTemplate !== prevProps.selectedStickerTemplate
      ) {
        this.setState({
          borderColor: getActiveColor(selectedGoal, 'borderColor'),
          backgroundColor: getActiveColor(selectedGoal, 'backgroundColor'),
          unActiveColor: getActiveColor(selectedGoal, 'unActiveColor'),
          unActiveTextColor: getActiveColor(selectedGoal, 'unActiveTextColor'),
          expiredBorderColor: getActiveColor(
            selectedGoal,
            'expiredBorderColor'
          ),
          expiredBackgroundColor: getActiveColor(
            selectedGoal,
            'expiredBackgroundColor'
          ),
        });
      }
    }
  }

  renderInfoSection() {
    const { selectedStickerTemplate } = this.props;

    const formValues = { ...this.props.formValues };
    const enteredDate = formValues.entered;
    const isStickerExpired = selectedStickerTemplate.isExpired ? true : false;
    const expirationDate = this.calculateExpirationDate();

    const infoBoxColor = enteredDate
      ? { backgroundColor: this.state.backgroundColor }
      : isStickerExpired
      ? { backgroundColor: this.state.expiredBackgroundColor }
      : {};

    const tagName = this.getTagName();

    let linkPresent = false;
    let text = EMPTY_STRING;
    if (isWorriesTag(tagName)) {
      text = i18n.t('stickers:addStickerScreen:infoBoxTexts.worriesTagSticker');
      linkPresent = true;
    } else if (isCelebrationsTag(tagName)) {
      text = i18n.t(
        'stickers:addStickerScreen:infoBoxTexts.celebrationsTagSticker'
      );
    } else if (isStickerExpired) {
      if (!formValues.entered) {
        text = i18n.t(
          'stickers:addStickerScreen:infoBoxTexts.expiredStickerInfo'
        );
        linkPresent = true;
      } else if (expirationDate >= formValues.entered) {
        text = i18n.t('stickers:addStickerScreen:infoBoxTexts.addedSticker');
        linkPresent = false;
      } else {
        text = i18n.t('stickers:addStickerScreen:infoBoxTexts.expiredSticker');
        linkPresent = true;
      }
    } else {
      // TODO [LOW priority]: Refactor because of the "else if" above
      let expiredSticker = false;
      // We aren't checking if the sticker is expired if there is no entered date for
      // the sticker event yet
      if (selectedStickerTemplate.daysToExpire !== null && enteredDate) {
        expiredSticker = moment(enteredDate).isAfter(expirationDate, 'day');
      }

      const addedSticker = isExistingSticker();
      if (expiredSticker) {
        text = i18n.t('stickers:addStickerScreen:infoBoxTexts.expiredSticker');
        linkPresent = true;
      } else if (addedSticker) {
        text = i18n.t('stickers:addStickerScreen:infoBoxTexts.addedSticker');
      } else if (enteredDate) {
        text = i18n.t('stickers:addStickerScreen:infoBoxTexts.addedSticker');
      } else {
        text = i18n.t('stickers:addStickerScreen:infoBoxTexts.newSticker');
      }
    }

    let linkTag = null;
    if (linkPresent) {
      linkTag = (
        <Link
          to={RELATIVE_PAGE_PATHS.SPECIALISTS}
          className="boldText"
          style={{ color: this.state.color }}
        >
          {i18n.t('stickers:addStickerScreen:infoBoxTexts.pediatriciansLink')}
        </Link>
      );
    }

    return (
      <Col
        xs={12}
        className="stickerInfoSection flexColumnCentered centred"
        style={infoBoxColor}
      >
        <p className="stickerInfoSectionText centredText noMargin mainTextColor">
          {text}
          <br />
          {linkTag}
        </p>
      </Col>
    );
  }

  calculateExpirationDate() {
    const { user, selectedStickerTemplate } = this.props;
    const isChildBorn4WeeksBeforeDueDate = user.isChildBorn4WeeksBeforeDueDate;

    let expirationDate = moment(
      user.childDateOfBirth,
      DEFAULT_BACKEND_DATE_FORMAT
    )
      .add(selectedStickerTemplate.daysToExpire, 'days')
      .toDate();

    if (isChildBorn4WeeksBeforeDueDate) {
      expirationDate = moment(expirationDate, DEFAULT_BACKEND_DATE_FORMAT)
        .add(FOUR_WEEKS_BEFORE_DUE_DATE, 'days')
        .toDate();
    }

    return expirationDate;
  }

  getTagName = () => {
    const { selectedTag, selectedStickerTemplate } = this.props;

    if (selectedTag && selectedTag.name) {
      return selectedTag.name;
    } else {
      return selectedStickerTemplate &&
        selectedStickerTemplate.area &&
        selectedStickerTemplate.area.tag
        ? selectedStickerTemplate.area.tag.name
        : EMPTY_STRING;
    }
  };

  renderShareButton = () => {
    return (
      <Col xs={12} className="flexContainerColumn centred">
        <FacebookButton
          disabled={isShareButtonDisabled(this.props.selectedAddedSticker)}
          selectedAddedSticker={this.props.selectedAddedSticker}
        />
      </Col>
    );
  };

  reloadData(stickerId) {
    this.props.getAddedSticker(stickerId);
  }

  render() {
    if (
      this.props.loader &&
      !Object.keys(this.props.selectedStickerTemplate).length
    ) {
      return <Fragment />;
    }
    return (
      <Container className="noPadding limited-width add-sticker">
        <Col xs={12} className="centred noPadding">
          <div className="flexContainerColumn flexContainerCentered profile-form fix-padding">
            {this.renderInfoSection()}
            <StickerSection
              {...this.state}
              selectedAddedSticker={this.props.selectedAddedSticker}
              reloadData={(stickerId) => this.reloadData(stickerId)}
            />
            <Col xs={12} className="centred noPadding">
              <StickerDateAndDescription {...this.state} />
              {this.renderShareButton()}
            </Col>
            <br />
          </div>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues(FORMS.STICKER_FORM)(state),
    selectedTag: isExistingSticker()
      ? state.selectedSticker.selectedAddedSticker.stickerTemplate.area.tag
      : state.selectedSticker.selectedStickerTemplate.area.tag,
    selectedStickerTemplate: isExistingSticker()
      ? state.selectedSticker.selectedAddedSticker.stickerTemplate
      : state.selectedSticker.selectedStickerTemplate,
    selectedAddedSticker: isExistingSticker()
      ? state.selectedSticker.selectedAddedSticker
      : {},
    user: state.authentication.userData,
    loader: state.loader,
  };
};

export default connect(mapStateToProps, {
  getAddedSticker,
  loadSticker,
})(AddStickerScreen);
