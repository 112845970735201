import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_DATA_IN_LOCAL_STORAGE } from '../../../utils/constants';

interface IUser {
  childDateOfBirth: string;
  childName: string;
  motherOrFatherName: string;
}

const BirthInfo = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<IUser>();

  useEffect(() => {
    const user = localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE);
    if (user) {
      const currentUser = JSON.parse(user);
      setUserInfo({
        childDateOfBirth: currentUser.childDateOfBirth,
        childName: currentUser.childName,
        motherOrFatherName: currentUser.motherOrFatherName,
      });
    }
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div id="birth-info">
        <span
          style={{
            display: 'block',
            fontSize: '13px',
            lineHeight: '18px',
            color: '#4A4A4A',
          }}
        >
          {t('analytics:shared:birth-box.birth')}
        </span>
        <span style={{ fontSize: '14px', color: '#4A4A4A' }}>
          {userInfo?.childDateOfBirth.split('-').reverse().join('.')}
        </span>
      </div>
    </div>
  );
};

export default BirthInfo;
