import React from 'react';
import * as i18n from 'i18next';
import { reduxForm } from 'redux-form';
import { Button, Col, Container } from 'reactstrap';
import {
  FORMS,
  LANGUAGE_CODE_KEY,
  RELATIVE_PAGE_PATHS,
} from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import { connect } from 'react-redux';
import { Fields } from '../common/Fields';
import navigationMethods from '../../utils/navigationUtils';
import {
  addSpecialist,
  getSpecialist,
  getSpecialists,
  updateSpecialist,
  deleteSpecialist,
  showDeleteModal,
  closeModal,
  showInfoModal,
  showRedConfirmModal,
  shareJournalThroughEmail,
  showConfirmModal,
  handleStopSharingJournalClick,
  handleShareJournalClick,
  setJournalTokenActiveStatusToFalse,
  setJournalTokenActiveStatusToTrue,
} from '../../actions';

class SpecialistDetailsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isJournalShared: null,
      emailMessageShown: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
      if (!this.props.specialists.length) {
        await this.props.getSpecialists();
      }
      await this.props.getSpecialist(id, languageCode);
      this.setState({
        isJournalShared: this.props.currentSpecialist.isJournalShared,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentSpecialist !== prevProps.currentSpecialist) {
      this.setState({
        isJournalShared: this.props.currentSpecialist.isJournalShared,
      });
    }
  }

  handleSubmit = (formValues) => {
    const id = this.props.match.params.id;
    const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
    if (id) {
      this.props.updateSpecialist(id, formValues, languageCode);
    } else {
      this.props.addSpecialist(formValues, languageCode);
    }
  };

  onDelete = () => {
    const id = this.props.match.params.id;
    const message = i18n.t('specialistsScreen:confirmDelete');
    this.props.showDeleteModal({
      message,
      onDelete: () => {
        this.props.deleteSpecialist(id);
        this.props.closeModal();
      },
    });
  };

  renderDeleteButton = () => {
    if (this.props.match.params.id) {
      return (
        <Button
          onClick={this.onDelete}
          className="greenBtn header next uppercase secondary danger mt-3"
        >
          {i18n.t('buttons:delete')}
        </Button>
      );
    }
  };

  renderShareButton = () => {
    const { currentSpecialist } = this.props;
    const id = this.props.match.params.id;
    const isJournalShared = this.state.isJournalShared;
    const shareButton = i18n.t('specialistsScreen:buttons:shareJournal');
    const stopSharingButton = i18n.t(
      'specialistsScreen:buttons:stopsharingJournal'
    );

    if (id) {
      if (!currentSpecialist.email && !this.state.emailMessageShown) {
        this.props.showInfoModal(i18n.t('contactsScreen:addEmail'));
        this.setState({ emailMessageShown: true });
        return null;
      }

      const buttonText = isJournalShared ? stopSharingButton : shareButton;
      const buttonClass = isJournalShared
        ? 'redBtn header next uppercase mt-3'
        : 'greenBtn header next uppercase mt-3';

      if (id && currentSpecialist.email) {
        return (
          <Button
            onClick={() => {
              const message = isJournalShared
                ? i18n.t('contactsScreen:confirmStopSharingJournal')
                : i18n.t('contactsScreen:confirmShareJournal');
              if (!isJournalShared) {
                this.props.showConfirmModal({
                  message,
                  onConfirm: () => {
                    this.props.shareJournalThroughEmail(
                      currentSpecialist.email
                    );
                    this.props.handleShareJournalClick(
                      this.props.match.params.id
                    );
                    this.setState({ isJournalShared: true });
                  },
                });
              } else {
                this.props.showRedConfirmModal({
                  message,
                  onConfirm: () => {
                    this.props.handleStopSharingJournalClick(
                      this.props.match.params.id
                    );
                    this.props.setJournalTokenActiveStatusToFalse(
                      this.props.currentSpecialist.email
                    );
                    this.setState({ isJournalShared: false });
                  },
                });
              }
            }}
            className={buttonClass}
          >
            {buttonText}
          </Button>
        );
      }
      return null;
    }
  };

  render() {
    const id = parseInt(this.props.match.params.id);

    const specialistNumber =
      this.props.specialists.findIndex((specialist) => specialist.id === id) +
      1;

    const isNewSpecialist =
      navigationMethods.getRoute() === RELATIVE_PAGE_PATHS.ADD_SPECIALISTS;
    const title = isNewSpecialist
      ? i18n.t('specialistsScreen:addSpecialist')
      : i18n.t('specialistsScreen:specialist', {
          word1: specialistNumber,
        });
    const buttonName = isNewSpecialist
      ? i18n.t('specialistsScreen:buttons.add')
      : i18n.t('buttons:saveChanges');

    if (this.state.loading) {
      return <div></div>;
    }

    return (
      <Container className="noPadding">
        <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
          <form
            onSubmit={this.props.handleSubmit(this.handleSubmit)}
            className="flexContainerColumn flexContainerCentered profile-form fix-padding"
          >
            <div className="flexContainerCentered">
              <p className="header mainGreenColor header-small uppercase">
                {title}
              </p>
            </div>

            <div className="flexContainerColumn flexContainerCentered mt-3">
              {Fields.specialists.name()}
              {Fields.specialists.specialty()}
              <div className="mt-4">
                {Fields.commonFields.email()}
                {Fields.specialists.phone()}
                {Fields.specialists.address({
                  className: 'specialists-address input-container',
                  rows: 10,
                })}
                {Fields.specialists.isFamilyPhysician()}
              </div>
            </div>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={8}
              className="flexContainerColumn centred mt-5 mb-5"
            >
              <Button
                onClick={this.props.handleSubmit(this.handleSubmit)}
                className="greenBtn header next uppercase"
              >
                {buttonName}
              </Button>
              {this.renderShareButton()}
              {this.renderDeleteButton()}
            </Col>
          </form>
        </Col>
      </Container>
    );
  }
}

const specialistForm = reduxForm({
  form: FORMS.SPECIALIST_FORM,
  validate: formUtils.validateSpecialists,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SpecialistDetailsScreen);

const mapStateToProps = (state, props) => {
  const id = props.match.params.id;
  return {
    initialValues: id ? state.specialists.specialist : {},
    specialists: state.specialists.specialists,
    currentSpecialist: id ? state.specialists.specialist : {},
  };
};

export default connect(mapStateToProps, {
  setJournalTokenActiveStatusToTrue,
  setJournalTokenActiveStatusToFalse,
  handleStopSharingJournalClick,
  handleShareJournalClick,
  addSpecialist,
  getSpecialist,
  getSpecialists,
  updateSpecialist,
  deleteSpecialist,
  showDeleteModal,
  closeModal,
  showInfoModal,
  shareJournalThroughEmail,
  showConfirmModal,
  showRedConfirmModal,
})(specialistForm);
