import { getAxiosWithToken } from '../../utils/webApi';
import {
  LOAD_GOALS_SUCCESS,
  LOAD_SELECTED_TAG_STICKERS_SUCCESS,
  SELECT_TAG_SUCCESS,
} from '../actionTypes';
import { GOALS } from '../../utils/constants';
import { setGoalsInLocalStorage } from '../../utils/commonUtils';
import { startLoader, stopLoader } from '../commonActions';
import * as i18n from 'i18next';

const translateGoals = (goalsArray) => {
  const translatedGoals = [];

  for (const goal of goalsArray) {
    const translatedName = i18n.t(`goalsAndTagsScreen:goals.${goal.name}`);
    const translatedTags = [];

    for (const tag of goal.tags) {
      const translatedTagName = i18n.t(
        `goalsAndTagsScreen:tags.name.${tag.name}`
      );
      const translatedTagDescription = i18n.t(
        `goalsAndTagsScreen:tags.description.${tag.description}`
      );

      translatedTags.push({
        ...tag,
        name: translatedTagName,
        description: translatedTagDescription,
      });
    }

    translatedGoals.push({
      ...goal,
      name: translatedName,
      tags: translatedTags,
    });
  }

  return translatedGoals;
};

export const loadAllGoals = () => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().get('/goal/all');

  dispatch(stopLoader());

  const returnedData = response.data;
  const translatedGoals = translateGoals(returnedData.data);
  if (returnedData.success) {
    dispatch({ type: LOAD_GOALS_SUCCESS, payload: translatedGoals });
    setGoalsInLocalStorage(translatedGoals);
    return translatedGoals;
  }
  return false;
};

export const loadTagStickers = (id, languageCode) => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().post(`tag/${id}/stickers`, {
    id,
    languageCode,
  });

  dispatch(stopLoader());

  const returnedData = response.data;
  if (returnedData.success) {
    dispatch({
      type: LOAD_SELECTED_TAG_STICKERS_SUCCESS,
      payload: returnedData.data,
    });
  }
};

export const loadTag = (id, languageCode) => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().post(`/tag/get-tag/${id}`, {
    id,
    languageCode,
  });

  dispatch(stopLoader());

  const returnedData = response.data;
  if (returnedData.success) {
    dispatch({ type: SELECT_TAG_SUCCESS, payload: returnedData.data });
  }
};

export const loadGoalsFromLocalStorage = () => {
  const goals = JSON.parse(localStorage.getItem(GOALS));
  return { type: LOAD_GOALS_SUCCESS, payload: goals };
};
