import { useEffect, useRef, type SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { centerTextHorizontally } from '../../../utils/svgUtils';

export const SleepTrackerButtonStopDayIcon = (
  props: SVGProps<SVGSVGElement>
) => {
  const { t } = useTranslation();
  const textRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    const svgWidth = 150.24; // Assuming fixed SVG width based on viewBox
    centerTextHorizontally(textRef, svgWidth);
  }, [t]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 150.2 150.2"
      {...props}
    >
      <path
        fill="#F5FFE6"
        d="M13.8 8h124c3.3 0 6 2.7 6 6v122.6c0 3.3-2.7 6-6 6h-124c-3.3 0-6-2.7-6-6V14c0-3.3 2.6-6 6-6z"
      />
      <path
        fill="#FFF"
        d="M30.8 19h90c6.1 0 11 4.9 11 11v90c0 6.1-4.9 11-11 11h-90c-6.1 0-11-4.9-11-11V30c0-6.1 4.9-11 11-11z"
      />
      <path
        fill="#87BA3B"
        d="M120.8 131h-90c-6.1 0-11-4.9-11-11V30c0-6.1 4.9-11 11-11h90c6.1 0 11 4.9 11 11v90c0 6.1-5 11-11 11zm-90-110c-5 0-9 4-9 9v90c0 5 4 9 9 9h90c5 0 9-4 9-9V30c0-5-4-9-9-9h-90z"
      />
      <path
        fill="#87BA3B"
        d="M84 76.9H67.5c-2.6 0-4.8-2.1-4.8-4.8V55.7c0-2.6 2.1-4.8 4.8-4.8H84c2.6 0 4.8 2.1 4.8 4.8v16.5c0 2.6-2.1 4.7-4.8 4.7z"
      />
      <text
        ref={textRef}
        x="0"
        y="105"
        fontWeight="bold"
        fontFamily="Nunito"
        letterSpacing="0.5px"
        fill="#87BA3B"
      >
        {t('trackers:trackingScreen:tracking.DAY')}
      </text>
    </svg>
  );
};
