import {
    CLEAR_REDUCERS,
    LOAD_CONTACT,
    LOAD_CONTACTS,
    SET_JOURNAL_TOKEN_STATUS_SUCCESS
} from '../actions/actionTypes';

const initialState = { contacts: [], contact: {} };

const contactsReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case LOAD_CONTACTS:
            return { ...state, contacts: [...action.payload] };
        case LOAD_CONTACT:
            return { ...state, contact: { ...action.payload } };
        case CLEAR_REDUCERS:
            return { ...initialState };
        case SET_JOURNAL_TOKEN_STATUS_SUCCESS:
            return { ...state };
        default:
            return state;
    }
};

export default contactsReducer;
