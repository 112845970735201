import { SVGProps, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { centerTextHorizontally } from '../../../utils/svgUtils';

const RightStopButton = (props: SVGProps<SVGSVGElement>) => {
  const { color } = props;
  const { t } = useTranslation();

  const textRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    const svgWidth = 150.24; // Assuming fixed SVG width based on viewBox
    centerTextHorizontally(textRef, svgWidth);
  }, [t]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 150.2 150.2"
      {...props}
    >
      <rect
        id="Rectangle_1887-2"
        data-name="Rectangle 1887-2"
        x="7.76"
        y="8"
        width="136.04"
        height="134.57"
        rx="6"
        fill="#f5ffe6"
      />
      <g id="Rectangle_1888" data-name="Rectangle 1888">
        <rect x="19.76" y="19" width="112" height="112" rx="11" fill="#fff" />
        <path
          d="M120.76,131h-90a11,11,0,0,1-11-11V30a11,11,0,0,1,11-11h90a11,11,0,0,1,11,11v90A11,11,0,0,1,120.76,131Zm-90-110a9,9,0,0,0-9,9v90a9,9,0,0,0,9,9h90a9,9,0,0,0,9-9V30a9,9,0,0,0-9-9Z"
          fill="#87ba3b"
        />
      </g>
      <g id="Group_1906" data-name="Group 1906">
        <path
          fill="#87BA3B"
          d="M84 76.9H67.5c-2.6 0-4.8-2.1-4.8-4.8V55.7c0-2.6 2.1-4.8 4.8-4.8H84c2.6 0 4.8 2.1 4.8 4.8v16.5c0 2.6-2.1 4.7-4.8 4.7z"
        />
      </g>
      <text
        ref={textRef}
        x="0"
        y="105"
        fontWeight="bold"
        fontFamily="Nunito"
        fill={color ? color : '#cacaca'}
      >
        {t('trackers:breastfeedingScreen:buttons.right')}
      </text>
    </svg>
  );
};

export default RightStopButton;
