import React, { useEffect, useState } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import { Container, Col } from 'reactstrap';
import DownloadJournal from '../common/svgComponents/DownloadJournal';
import { checkForNewVersion } from '../../actions';
import { useTranslation } from 'react-i18next';
import { COLOR_SCHEME, JOURNAL_EMAIL } from '../../utils/constants';
import ReactLoading from 'react-loading';
import DownloadPDFButton from './pdf/DownloadPDFButton';
import { isAndroid, isIOS, isMobile } from 'mobile-device-detect';

interface IRequestJournalDownloadScreen {
  user: {
    childName: string;
  };
}

const RequestJournalDownloadScreen: React.FC<IRequestJournalDownloadScreen> = ({
  user,
}) => {
  useEffect(() => {
    checkForNewVersion();
  }, []);

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Container className="noPadding">
      <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
        <div className="flexContainerColumn flexContainerCentered fix-padding">
          {loading ? (
            <div className="spinner-modal">
              <ReactLoading
                type="spinningBubbles"
                color={COLOR_SCHEME.GREEN}
                height="auto"
                width={150}
              />
            </div>
          ) : (
            <>
              <p style={{ marginTop: 30 }} />
              <div className="flexContainerCentered">
                <DownloadJournal width={166} height={168} />
              </div>
              <div className="primaryHeader">
                <span className="uppercase">
                  {t('requestJournalDownloadScreen:for')} {user.childName}
                </span>
              </div>
              <div className="flexContainerColumn flexContainerCentered description">
                {isMobile || isAndroid || isIOS
                  ?
                  <>
                    {t('requestJournalDownloadScreen:descriptionMobileDevices')}
                  </>
                  :
                  <>
                    {t('requestJournalDownloadScreen:descriptionDesktopDevices')}
                    <a href={`mailto:${JOURNAL_EMAIL}`}>{JOURNAL_EMAIL}</a>
                  </>
                }
              </div>
              <Col
                xs={10}
                sm={10}
                md={10}
                lg={8}
                className="flexContainerColumn centred"
                style={{ margin: '40px 0px 12px 0px' }}
              >
                <DownloadPDFButton
                  isAdminPage={false}
                  setLoading={setLoading}
                />
              </Col>
            </>
          )}
        </div>
      </Col>
    </Container >
  );
};

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    user: state.authentication.userData,
  };
};

export default connect(mapStateToProps)(RequestJournalDownloadScreen);
