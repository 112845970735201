import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useField } from 'react-final-form';
import { TIME_OF_THE_DAY } from '../tracking/models';
import { useTranslation } from 'react-i18next';

export interface TimeOfTheDayFieldProps {
  name: string;
}
export const TimeOfTheDayField = ({ name }: TimeOfTheDayFieldProps) => {
  const { t } = useTranslation();
  const { input } = useField(name);
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="sleeping"
        row
        value={input.value}
        onBlur={input.onBlur}
        onChange={(e) => input.onChange(e.target.value)}
      >
        <Box
          display="flex"
          gridTemplateColumns={'1fr 1fr'}
          flexWrap="wrap"
          columnGap={1}
        >
          <FormControlLabel
            value={TIME_OF_THE_DAY.DAY}
            control={<Radio />}
            label={t('trackers:sleepTrackerScreen:day')}
          />
          <FormControlLabel
            value={TIME_OF_THE_DAY.NIGHT}
            control={<Radio />}
            label={t('trackers:sleepTrackerScreen:night')}
          />
          <FormControlLabel
            value={TIME_OF_THE_DAY.LULL}
            control={<Radio />}
            label={t('trackers:sleepTrackerScreen:lull')}
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
};
