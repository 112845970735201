import { CLEAR_CHECKBOX, UPDATE_CUSTOM_PRODUCTS } from '../actions/actionTypes';

const initialState = {
  clearCheckbox: false,
  userCategoryItems: null,
};

const feedingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CHECKBOX:
      return { ...state, clearCheckbox: !state.clearCheckbox };
    case UPDATE_CUSTOM_PRODUCTS:
      return { ...state, userCategoryItems: action.payload };
    default:
      return state;
  }
};

export default feedingReducer;
