import React from 'react';
import { useTranslation } from 'react-i18next';
import Read from './svgComponents/MenuBarIcons/Read';
import Add from './svgComponents/MenuBarIcons/Add';
import DetectTime from './svgComponents/MenuBarIcons/DetectTime';
import Diary from './svgComponents/MenuBarIcons/Diary';
import Analyses from './svgComponents/MenuBarIcons/Analyses';
import { useDispatch, useSelector } from 'react-redux';
import { hideHamburgerMenu, startNavLoader } from '../../actions';
import { Col } from 'reactstrap';
import navigationMethods from '../../utils/navigationUtils';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';

const FooterNavigationBar = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isHamburgerMenuShown = useSelector(
    (state) => state.common.showHamburgerMenu
  );

  const navigateTo = (path) => {
    navigationMethods.navigate(path);
    dispatch(hideHamburgerMenu());
  };

  const footerClass = className
    ? 'flexContainer footerNavBar ' + className
    : 'flexContainer footerNavBar';

  return (
    <Col className={footerClass}>
      <div
        onClick={() => {
          navigateTo(RELATIVE_PAGE_PATHS.USEFUL_INFO);
        }}
        style={{
          padding: '0px 12px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: 12,
        }}
      >
        <Read width={24} height={24} />
        <span>{t('footerNavigationBar:read')}</span>
      </div>
      <div
        onClick={() => {
          navigateTo(RELATIVE_PAGE_PATHS.HOME);
        }}
        style={{
          padding: '0px 12px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: 12,
        }}
      >
        <Add width={24} height={24} />
        <span>{t('footerNavigationBar:infostickers')}</span>
      </div>
      <div
        onClick={() => {
          navigateTo(RELATIVE_PAGE_PATHS.JOURNAL);
        }}
        style={{
          padding: '0px 12px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: 12,
        }}
      >
        <Diary width={24} height={24} />
        <span>{t('footerNavigationBar:journal')}</span>
      </div>
      <div
        onClick={() => {
          navigateTo(RELATIVE_PAGE_PATHS.TRACKING);
        }}
        style={{
          padding: '0px 12px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: 12,
        }}
      >
        <DetectTime width={24} height={24} />
        <span>{t('footerNavigationBar:detectTime')}</span>
      </div>
      <div
        onClick={() => {
          navigateTo(RELATIVE_PAGE_PATHS.ANALYTICS);
        }}
        style={{
          padding: '0px 12px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: 12,
        }}
      >
        <Analyses width={24} height={24.3} />
        <span>{t('footerNavigationBar:analyses')}</span>
      </div>
    </Col>
  );
};

export default FooterNavigationBar;
