import { startLoader, stopLoader } from './commonActions';
import { getAxiosWithToken } from '../utils/webApi';
import { LOAD_TRACKING_RECORDS, CLEAR_TRACKING_RECORDS } from './actionTypes';
import { TRACKER_BATCH_SIZE } from '../utils/constants';

export const addTrackingRecord =
  (subTypeId, date, quantity, additionalInfo) => async (dispatch) => {
    dispatch(startLoader());
    await getAxiosWithToken().post('trackers/records', {
      subTypeId,
      date,
      quantity,
      additionalInfo,
    });

    dispatch(getTrackingRecordsByType(subTypeId, 0));
    dispatch(stopLoader());
  };

export const updateTrackingRecord =
  (id, date, quantity, additionalInfo, notes) => async (dispatch) => {
    dispatch(startLoader());

    try {
      const response = await getAxiosWithToken().put(`trackers/records/${id}`, {
        id,
        date,
        quantity,
        additionalInfo,
        notes,
      });

      if (response.data.success) {
        const updatedRecord = response.data.data;
        dispatch({
          type: 'UPDATE_TRACKING_RECORD',
          payload: updatedRecord,
        });
      }
    } catch (error) {
      console.error('Error updating tracking record:', error);
    }
    dispatch(stopLoader());
  };

export const deleteTrackingRecord = (id) => async (dispatch) => {
  dispatch(startLoader());

  try {
    const response = await getAxiosWithToken().delete(`trackers/records/${id}`);

    if (response.data.success) {
      dispatch({
        type: CLEAR_TRACKING_RECORDS,
        payload: id,
      });
    }
  } catch (error) {
    console.error('Error deleting tracking record:', error);
  }
  dispatch(stopLoader());
};

export const getBatchRecords = (offset) => async (dispatch) => {
  try {
    const response = await getAxiosWithToken().get(
      `trackers/records?offset=${offset}&batchSize=${TRACKER_BATCH_SIZE}`
    );
    const returnedData = response.data;
    if (returnedData.success) {
      if (returnedData.data.length > 0) {
        dispatch({ type: LOAD_TRACKING_RECORDS, payload: returnedData.data });
      }
    }
  } catch (error) {
    console.error('Error fetching records in catch:', error);
  }
};

export const getAllTrackingRecords = (signal) => async (dispatch) => {
  let offset = 0;
  const batchSize = 300;
  let hasMoreRecords = true;

  while (hasMoreRecords) {
    try {
      const response = await getAxiosWithToken().get(
        `trackers/records?offset=${offset}&batchSize=${batchSize}`,
        { signal }
      );
      const returnedData = response.data;

      if (returnedData.success) {
        if (returnedData.data.length > 0) {
          dispatch({ type: LOAD_TRACKING_RECORDS, payload: returnedData.data });
        }

        if (returnedData.data.length < batchSize) {
          hasMoreRecords = false;
        } else {
          offset += batchSize;
        }
      } else {
        console.error('Error fetching records:', returnedData);
        hasMoreRecords = false;
      }
    } catch (error) {
      if (error.name === 'CanceledError') {
        console.log('Request canceled');
      }
      hasMoreRecords = false;
      console.error('Error fetching records in catch:', error);
      return;
    }
  }
};

export const getTrackingRecordsByType =
  (typeId, offset) => async (dispatch) => {
    try {
      const response = await getAxiosWithToken().get(
        `trackers/records/type?typeId=${typeId}&offset=${offset}&batchSize=${TRACKER_BATCH_SIZE}`
      );

      const returnedData = response.data;
      if (returnedData.success) {
        if (returnedData.data.length > 0) {
          dispatch({ type: LOAD_TRACKING_RECORDS, payload: returnedData.data });
        }
      }
    } catch (error) {
      console.error('Error fetching records by type:', error);
    }
  };
