import { SVGProps, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { centerTextHorizontally } from '../../../utils/svgUtils';

const RightPlayButtonDisabled = (props: SVGProps<SVGSVGElement>) => {
  const { t } = useTranslation();

  const textRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    const svgWidth = 150.24; // Assuming fixed SVG width based on viewBox
    centerTextHorizontally(textRef, svgWidth);
  }, [t]);

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150.24 150.24"
      {...props}
    >
      <g id="Rectangle_1888" data-name="Rectangle 1888">
        <path
          d="M121,131H31a11,11,0,0,1-11-11V30A11,11,0,0,1,31,19h90a11,11,0,0,1,11,11v90A11,11,0,0,1,121,131ZM31,21a9,9,0,0,0-9,9v90a9,9,0,0,0,9,9h90a9,9,0,0,0,9-9V30a9,9,0,0,0-9-9Z"
          fill={'#cacaca'}
        />
      </g>
      <path
        id="Polygon_9"
        data-name="Polygon 9"
        d="M88.41,60.37a4,4,0,0,1,1.33,5.5,3.94,3.94,0,0,1-1.33,1.33L73.09,76.56A4,4,0,0,1,67,73.15V54.41a4,4,0,0,1,4-4,4,4,0,0,1,2.09.59Z"
        fill="#cacaca"
      />
      <text
        ref={textRef}
        x="0"
        y="105"
        fontWeight="bold"
        fontFamily="Nunito"
        fill="#cacaca"
      >
        {t('trackers:breastfeedingScreen:buttons.right')}
      </text>
    </svg>
  );
};

export default RightPlayButtonDisabled;
