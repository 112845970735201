import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router';
import { getAllTrackers } from '../actions/trackerActions';
import { getBatchRecords } from '../actions/trackingRecordsActions';
import { RELATIVE_PAGE_PATHS } from '../utils/constants';
import TrackScreen from './TrackScreen';
import ScreenContainer from './common/Container';
import TrackerList from './tracker/TrackerList';
import { BreastFeedingTracker } from './tracker/breast-feeding/BreastFeedingTracker';
import { SleepTracker } from './tracker/sleep/SleepTracker';
import { CryingTracker } from './tracker/cry/CryingTracker';
import ManualLoader from './common/ManualLoader';

const TrackingScreen = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isTrackerDataLoaded, setIsTrackerDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(getAllTrackers());
    if (location.pathname === path) {
      dispatch(getBatchRecords(0));
    }
    setIsTrackerDataLoaded(true);
  }, [dispatch, location.pathname, path]);

  if (!isTrackerDataLoaded) {
    return <ManualLoader />;
  }
  return (
    <ScreenContainer>
      <Switch>
        <Route exact path={path}>
          <TrackerList />
        </Route>
        <Route exact path={`${RELATIVE_PAGE_PATHS.SLEEP}`}>
          <SleepTracker />
        </Route>
        <Route exact path={`${RELATIVE_PAGE_PATHS.SLEEP}/:recordId`}>
          <SleepTracker />
        </Route>
        <Route exact path={RELATIVE_PAGE_PATHS.BREAST_FEEDING}>
          <BreastFeedingTracker />
        </Route>
        <Route exact path={`${RELATIVE_PAGE_PATHS.BREAST_FEEDING}/:recordId`}>
          <BreastFeedingTracker />
        </Route>
        <Route exact path={RELATIVE_PAGE_PATHS.CRY}>
          <CryingTracker />
        </Route>
        <Route exact path={`${RELATIVE_PAGE_PATHS.CRY}/:recordId`}>
          <CryingTracker />
        </Route>
        <Route exact path={`${path}/:typeId`}>
          <TrackScreen />
        </Route>
        <Route exact path={`${path}/:typeId/:recordId`}>
          <TrackScreen />
        </Route>
      </Switch>
    </ScreenContainer>
  );
};

export default TrackingScreen;
