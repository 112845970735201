import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useField } from 'react-final-form';
import { CRY_TYPES } from '../tracking/models';
import { useTranslation } from 'react-i18next';

export interface ICryField {
  name: string;
}
export const CryField = ({ name }: ICryField) => {
  const { t } = useTranslation();
  const { input } = useField(name);
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="crying"
        row
        value={input.value}
        onBlur={input.onBlur}
        onChange={(e) => input.onChange(e.target.value)}
      >
        <Box display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={1}>
          <FormControlLabel
            value={CRY_TYPES.CRY_DAY}
            control={<Radio />}
            label={t('trackers:cryingScreen:DAY')}
          />
          <FormControlLabel
            value={CRY_TYPES.CRY_NIGHT}
            control={<Radio />}
            label={t('trackers:cryingScreen:NIGHT')}
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
};
