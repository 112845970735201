import { useMemo } from 'react';
import {
  type MRT_ColumnDef,
  MRT_Table,
  useMantineReactTable,
  MRT_TableInstance,
} from 'mantine-react-table';
import { ISleepTableData } from '../helper/ISleepAnalytics.interface';
import { useTranslation } from 'react-i18next';
import { secToHoursAndMinutes } from '../helper/sleepUtils';

interface ISleepTable {
  data: ISleepTableData[];
  footer: ISleepTableData;
}

export const SleepTable: React.FC<ISleepTable> = ({ data, footer }) => {
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<ISleepTableData>[]>(
    () => [
      {
        accessorKey: 'dateAndAge',
        header: t('analytics:sleepAnalytics:tableHeaders.date'),
        Header: ({}) => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:tableHeaders.dateAnd')}
            <br />
            {t('analytics:sleepAnalytics:tableHeaders.Age')}
          </div>
        ),
        Footer: () => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:periodAverage')}
          </div>
        ),
        size: 5,
        maxSize: 2,
        minSize: 1,
      },
      {
        accessorKey: 'nighttimeSleep',
        header: t('analytics:sleepAnalytics:tableHeaders.nighttimeSleep'),
        Header: ({}) => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:tableHeaders.nighttimeSleep')}
          </div>
        ),
        Footer: () => (
          <div className="header-cell-name">
            <div>{secToHoursAndMinutes(footer.nighttimeSleep)}</div>
          </div>
        ),
        Cell: (item) => defaultCellValue(item),
        size: 5,
        maxSize: 10,
      },
      {
        accessorKey: 'daytimeSleep',
        header: t('analytics:sleepAnalytics:tableHeaders.daytimeSleep'),
        Header: ({}) => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:tableHeaders.daytimeSleep')}
          </div>
        ),
        Footer: () => (
          <div className="header-cell-name">
            <div>{secToHoursAndMinutes(footer.daytimeSleep)}</div>
          </div>
        ),
        Cell: (item) => defaultCellValue(item),
        size: 5,
      },
      {
        accessorKey: 'totalSleep',
        header: t('analytics:sleepAnalytics:tableHeaders.totalSleep'),
        Header: ({}) => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:tableHeaders.totalSleep')}
          </div>
        ),
        Footer: () => (
          <div className="header-cell-name">
            <div>{secToHoursAndMinutes(footer.totalSleep)}</div>
          </div>
        ),
        Cell: (item) => defaultCellValue(item),
        size: 10,
      },
      {
        accessorKey: 'totalAwakening',
        header: t('analytics:sleepAnalytics:tableHeaders.totalAwakening'),
        Header: ({}) => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:tableHeaders.totalAwakening')}
          </div>
        ),
        Footer: () => (
          <div className="header-cell-name">
            {footer.totalAwakening.toString().trim()
              ? footer.totalAwakening
              : '-'}
          </div>
        ),
        Cell: (item) => (
          <div>
            {' '}
            {item.renderedCellValue?.toString().trim()
              ? item.renderedCellValue
              : '-'}
          </div>
        ),
        size: 1,
      },
      {
        accessorKey: 'daytimeNaps',
        header: t('analytics:sleepAnalytics:tableHeaders.daytimeNaps'),
        Header: ({}) => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:tableHeaders.daytimeNaps')}
          </div>
        ),
        Footer: () => (
          <div className="header-cell-name">
            {footer.daytimeNaps.toString().trim() ? footer.daytimeNaps : '-'}
          </div>
        ),
        Cell: (item) => (
          <div>
            {' '}
            {item.renderedCellValue?.toString().trim()
              ? item.renderedCellValue
              : '-'}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: 'nightWaking',
        header: t('analytics:sleepAnalytics:tableHeaders.nightWaking'),
        Header: ({}) => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:tableHeaders.nightWaking')}
          </div>
        ),
        Footer: () => (
          <div className="header-cell-name">
            {footer.nightWaking.toString().trim() ? footer.nightWaking : '-'}
          </div>
        ),
        Cell: (item) => (
          <div>
            {' '}
            {item.renderedCellValue?.toString().trim()
              ? item.renderedCellValue
              : '-'}
          </div>
        ),
        size: 10,
      },
      {
        accessorKey: 'lull',
        header: t('analytics:sleepAnalytics:tableHeaders.lull'),
        Header: ({}) => (
          <div className="header-cell-name">
            {t('analytics:sleepAnalytics:tableHeaders.lull')}
          </div>
        ),
        Footer: () => (
          <div className="header-cell-name">
            <div>{secToHoursAndMinutes(footer.lull)}</div>
          </div>
        ),
        Cell: (item) => defaultCellValue(item),
        size: 10,
      },
    ],
    [footer]
  );

  const table: MRT_TableInstance<ISleepTableData> = useMantineReactTable({
    columns,
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableStickyFooter: true,
    mantineTableHeadCellProps: {
      align: 'center',
      style: {
        backgroundColor: 'rgba(235, 246, 255, 1)',
        color: '#000000',
        padding: '8px 8px',
        lineHeight: '9px',
        fontSize: '12px',
        fontWeight: 300,
        fontFamily: 'Nunito',
        alignContent: 'center',
        boxShadow: 'none',
      },
    },
    mantineTableProps: {
      withColumnBorders: true,
      striped: true,
    },
    mantineTableBodyCellProps: {
      style: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '11.7px',
        fontFamily: 'Nunito',
        color: '#323B4B',
        textAlign: 'center',
      },
    },
    mantineTableFooterCellProps: {
      style: {
        backgroundColor: 'rgba(235, 246, 255, 1)',
        color: '#000000',
        padding: '8px 8px',
        lineHeight: '9px',
        fontSize: '12px',
        fontWeight: 700,
        fontFamily: 'Nunito',
        alignContent: 'center',
        boxShadow: 'none',
        textAlign: 'center',
        border: '0.0625rem solid #dee2e6',
      },
    },
  });

  //using MRT_Table instead of MantineReactTable if we do not want any of the toolbar features
  return <MRT_Table table={table} />;
};

export default SleepTable;

const defaultCellValue = (item: any) => {
  return <div>{secToHoursAndMinutes(item.renderedCellValue)}</div>;
};
