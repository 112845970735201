import React from 'react';
import { reduxForm } from 'redux-form';
import { FORMS } from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import * as i18n from 'i18next';
import ReminderIcon from '../common/svgComponents/ReminderIcon';
import DiaryIcon from '../common/svgComponents/DiaryIcon';
import SharingIcon from '../common/svgComponents/SharingIcon';
import TimerIcon from '../common/svgComponents/TimerIcon';
import ReadingIcon from '../common/svgComponents/ReadingIcon';
import Dots from '../common/Dots';
import { scrollToTop } from '../../utils/commonUtils';

class InfoScreen extends React.Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    const { handleSubmit, currentPage } = this.props;

    return (
      <form
        onSubmit={handleSubmit}
        className="flexContainerColumn flexContainerCentered fixed-padding-register noMargin"
      >
        <Row className="missionRow">
          <p className="infoParagraph">
            {i18n.t(
              'userRegisterScreenActions:registerScreen:registerInfoScreen.missionText'
            )}
          </p>
        </Row>
        <Row className="flexContainerCentered noPadding noMargin">
          <Col xs={10} sm={12} md={10} className="featuresRow noPadding">
            <Col xs={2} sm={2} md={2} className="noPadding">
              <ReminderIcon width={58} height={58} />
            </Col>
            <Col xs={10} sm={10} md={10} style={{ marginLeft: 8 }}>
              <p>
                {i18n.t(
                  'userRegisterScreenActions:registerScreen:registerInfoScreen.reminderText'
                )}
              </p>
            </Col>
          </Col>
          <Col xs={10} sm={12} md={10} className="featuresRow noPadding">
            <Col xs={2} sm={2} md={2} className="noPadding">
              <DiaryIcon width={58} height={58} />
            </Col>
            <Col xs={10} sm={10} md={10} style={{ marginLeft: 8 }}>
              <p>
                {i18n.t(
                  'userRegisterScreenActions:registerScreen:registerInfoScreen.diaryText'
                )}
              </p>
            </Col>
          </Col>
          <Col xs={10} sm={12} md={10} className="featuresRow noPadding">
            <Col xs={2} sm={2} md={2} className="noPadding">
              <SharingIcon width={58} height={58} />
            </Col>
            <Col xs={10} sm={10} md={10} style={{ marginLeft: 8 }}>
              <p>
                {i18n.t(
                  'userRegisterScreenActions:registerScreen:registerInfoScreen.shareDiaryText'
                )}
              </p>
            </Col>
          </Col>
          <Col xs={10} sm={12} md={10} className="featuresRow noPadding">
            <Col xs={2} sm={2} md={2} className="noPadding">
              <TimerIcon width={58} height={58} />
            </Col>
            <Col xs={10} sm={10} md={10} style={{ marginLeft: 8 }}>
              <p>
                {i18n.t(
                  'userRegisterScreenActions:registerScreen:registerInfoScreen.timerText'
                )}
              </p>
            </Col>
          </Col>
          <Col xs={10} sm={12} md={10} className="featuresRow noPadding">
            <Col xs={2} sm={2} md={2} className="noPadding">
              <ReadingIcon width={58} height={58} />
            </Col>
            <Col xs={10} sm={10} md={10} style={{ marginLeft: 8 }}>
              <p>
                {i18n.t(
                  'userRegisterScreenActions:registerScreen:registerInfoScreen.readingText'
                )}
              </p>
            </Col>
          </Col>
        </Row>
        <Col xs={10} sm={10} md={10} lg={10} className="paymentRow noPadding">
          <p className="infoParagraph">
            {i18n.t(
              'userRegisterScreenActions:registerScreen:registerInfoScreen.paymentInfo'
            )}
          </p>
        </Col>
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={8}
          className="flexContainerColumn centred"
        >
          <Button className="greenBtn header next uppercase">
            {i18n.t('buttons:next')}
          </Button>
        </Col>
        <Dots page={currentPage} />
      </form>
    );
  }
}

const registerForm = reduxForm({
  form: FORMS.REGISTER_FORM,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: formUtils.validateSignUp,
})(InfoScreen);

export default connect(null)(registerForm);
