import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODE_KEY } from '../../../utils/constants';

const FeedingFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div id="growth-footer">
      {localStorage.getItem(LANGUAGE_CODE_KEY) === 'bg' ? (
        <div id="view-more">
          <p>{t('analytics:feedingAnalytics:footer.is-the-milk-enough')}</p>
          <a
            target="_blank"
            href="https://www.namama.bg/category/hranene-i-zdrave/"
            rel="noreferrer"
          >
            {t('analytics:shared.view-more')}
          </a>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FeedingFooter;
