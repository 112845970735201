import i18next, * as i18n from 'i18next';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container } from 'reactstrap';
import LogoDMT from './common/svgComponents/LogoDMT';
import welcomeGIF from '../images/welcomeGif.gif';
import LanguageSelector from './LanguageSelector';
import { getLocalStorageLanguage } from '../utils/commonUtils';
import navigationMethods from '../utils/navigationUtils';

class WelcomeScreen extends Component {
  constructor(props) {
    super(props);

    const currentLanguage = getLocalStorageLanguage();

    this.state = {
      language: currentLanguage.code,
    };
  }

  componentDidMount() {
    i18next.changeLanguage(this.state.language);
  }

  handleLanguageChange = (newLanguage) => {
    this.setState({ language: newLanguage });
  };

  render() {
    return (
      <Container className="themed-container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <img id="welcome-screen-gif" src={welcomeGIF} />
        </div>
        <Col sm={12} md={4} lg={4} className="centred noPadding">
          <div className="flexContainerCentered"></div>
          <div className="flexContainerCentered">
            <LogoDMT />
          </div>
          <div className="centredText">
            <p
              lang="bg"
              className="header noMargin mainTextColor"
              style={{
                fontFamily: 'montserrat-m',
                fontSize: 29,
                lineHeight: 1.18,
                fontWeight: 100,
              }}
            >
              {i18n.t('welcomeScreen:headerText.headerTextFirstRow')}
            </p>
            <p
              lang="bg"
              className="header mainTextColor"
              style={{
                fontFamily: 'montserrat-m',
                fontSize: 29,
                lineHeight: 1.18,
                fontWeight: 100,
                marginBottom: '0px',
              }}
            >
              {i18n.t('welcomeScreen:headerText.headerTextSecondRow')}
            </p>
            <img
              src={navigationMethods.getSystemImageUrl(
                'home-screen-bottom-logo.svg'
              )}
            />
          </div>
          <div className="flexContainerCentered">
            <p
              lang="bg"
              className="header mainGreenColor welcomeMsg"
              style={{
                fontFamily: 'montserrat-m',
              }}
              onClick={() => {
                navigationMethods.openUrlInNewTab(
                  i18n.t('welcomeScreen:headerText.learnMoreLink')
                );
              }}
            >
              {i18n.t('welcomeScreen:headerText.learnMoreText')}
            </p>
          </div>
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="flexContainerColumn centred"
          >
            <Link
              to={'/register'}
              className="greenBtn header flexContainerCentered uppercase"
            >
              {i18n.t('buttons:register')}
            </Link>
            <Link
              to="/login"
              className="btn greenBtnOutline header flexContainerCentered uppercase"
            >
              {i18n.t('buttons:login')}
            </Link>
            <div
              className="flexContainerColumn flexContainerCentered"
              style={{ marginBottom: '6.5rem', height: '0' }}
            >
              <LanguageSelector
                language={this.state.language}
                setLanguage={this.handleLanguageChange}
              />
            </div>
            <div className="flexContainerCentered">
              <p
                lang="bg"
                className="header mainGreenColor"
                style={{
                  fontFamily: 'montserrat-m',
                  textAlign: 'center',
                }}
              >
                {i18n.t('welcomeScreen:headerText.welcomeMsg')}
              </p>
            </div>
          </Col>
        </Col>
      </Container>
    );
  }
}

export default connect(null, {})(WelcomeScreen);
