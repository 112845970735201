const Diary = ({ width, height }) => {
  const viewBox = '0 0 28.35 28.35';

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <title>икона_анализи</title>
      <g id="Layer_2" data-name="Layer 2">
        <path
          d="M24.17,22.8H5.78c3.4-6.27,8.86-7.42,18.55-7.42a.45.45,0,1,0,0-.9c-9.75,0-15.61,1.25-19.22,7.69V22a11.91,11.91,0,0,1,4.68-8.4,1.65,1.65,0,0,0,.85.24,1.62,1.62,0,0,0,1.62-1.62s0-.06,0-.1A19.51,19.51,0,0,1,15.19,11a1.62,1.62,0,0,0,2.92-.64c1.06-.17,2.19-.29,3.37-.36a1.63,1.63,0,1,0-.19-.89c-1.14.08-2.24.19-3.27.35a1.62,1.62,0,0,0-3.13.6s0,.06,0,.09a18.61,18.61,0,0,0-3,1.09,1.62,1.62,0,0,0-2.94,1,1.47,1.47,0,0,0,.17.69,12.08,12.08,0,0,0-3.88,5c1.24-8.38,7.63-12.63,19-12.63a.45.45,0,0,0,0-.9C13.84,4.34,7.4,7.84,5.11,14.73V4.21a.74.74,0,1,0-1.48,0V24.46a.74.74,0,0,0,.74.74.79.79,0,0,0,.23,0,1.72,1.72,0,0,0,.24,0H24.17a1.2,1.2,0,0,0,0-2.4Z"
          fill="#87ba3b" />
      </g>
    </svg>
  );
};

export default Diary;