import React from 'react';
import PropTypes from 'prop-types';
import * as i18n from 'i18next';

DiaperHeader.propTypes = {
  quantityText: PropTypes.string,
  beforeText: PropTypes.string,
  subType: PropTypes.string,
};

function DiaperHeader({ quantityText, beforeText, subType }) {
  return (
    <>
      <p className="mb-0">{i18n.t('trackers:trackingScreen:header.title')}</p>
      <p className="mb-0">
        <strong className="">{quantityText}</strong>
      </p>
      <span>{beforeText}</span>
    </>
  );
}

export default DiaperHeader;
