import React, { Fragment } from 'react';
import * as i18n from 'i18next';
import {
  getActiveColor,
  getColor,
  isNoStickers,
  isReadOnlyRoute,
  isWorriesTag,
  parseDate,
} from '../../utils/commonUtils';
import navigationUtils from '../../utils/navigationUtils';
import {
  BIRTH_STICKER_NAME,
  LANGUAGE_CODE_KEY,
  RELATIVE_PAGE_PATHS,
} from '../../utils/constants';
import RoundBulb from '../common/svgComponents/RoundBulb';
import RoundStar from '../common/svgComponents/RoundStar';
import MenuDots from '../common/MenuDots';
import MonthNumber from '../common/svgComponents/GoalsAndTagsIcons/MonthNumber';
import { connect } from 'react-redux';
import {
  clearReducers,
  closeModal,
  deleteAddedSticker,
  getJournal,
  loadGoalsFromLocalStorage,
  showDeleteModal,
} from '../../actions';
import { isMobile } from 'mobile-device-detect';

class JournalItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      borderColor: getActiveColor('borderColor'),
      backgroundColor: getActiveColor('backgroundColor'),
    };
  }

  renderPicture = (goal, item) => {
    if (item.photo) {
      return (
        <div
          onClick={() => {
            this.onEditSticker(item);
          }}
          className="stickersContainer photo-container-holder"
          style={{ borderColor: getColor(goal.borderColor) }}
        >
          <img
            style={{ height: '100%' }}
            alt="thumbnail"
            src={navigationUtils.getUserImageUrl(item.photoThumbnail)}
            className="journal-picture"
          />
        </div>
      );
    }
  };

  onEditSticker = (currentItem) => {
    if (!isReadOnlyRoute()) {
      navigationUtils.navigate(
        RELATIVE_PAGE_PATHS.EDIT_STICKER + currentItem.id
      );
    } else {
      var urlEncodedUserId = encodeURIComponent(this.props.userId);
      navigationUtils.navigate(
        RELATIVE_PAGE_PATHS.STICKER_READ_ONLY + currentItem.id,
        'user-id=' + urlEncodedUserId
      );
    }
  };

  onDeleteButtonPress = (currentItem) => {
    this.props.showDeleteModal({
      message: i18n.t('stickers:journalScreen:confirmDelete'),
      onDelete: () => {
        this.onDeleteSticker(currentItem);
      },
    });
  };

  onDeleteSticker = (currentItem) => {
    const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
    this.props.deleteAddedSticker(currentItem.id, languageCode);
    this.props.closeModal();
  };

  renderItemMenu = (currentItem) => {
    if (this.props.activeItemMenu === currentItem.id) {
      return (
        <ul className="itemMenu">
          <li
            onClick={() => {
              this.onDeleteButtonPress(currentItem);
            }}
          >
            {i18n.t('stickers:journalScreen:delete')}
          </li>
          <li
            onClick={() => {
              this.onEditSticker(currentItem);
            }}
          >
            {i18n.t('stickers:journalScreen:edit')}
          </li>
        </ul>
      );
    }
  };

  renderTextNote = (text, goal) => {
    if (!text) return null;
    return (
      <div className="userTextPreview-outer">
        <div
          style={{
            borderColor: getColor(goal.borderColor),
          }}
          className="userTextPreview"
        >
          <div className="userTextPreview-inner">{text}</div>
        </div>
      </div>
    );
  };

  renderJournalItem = (item) => {
    const goal = item.stickerTemplate.area.tag.goal;
    const journalItemClass = item.photo
      ? 'journalItem'
      : 'journalItem noPicture';

    const dateClass = isMobile ? 'mobileDate' : 'date';

    const isWorries = isWorriesTag(item.stickerTemplate.area.tag.name);
    const menuDots =
      this.props.user &&
      navigationUtils.getRoute() !== RELATIVE_PAGE_PATHS.JOURNAL_READ_ONLY ? (
        <MenuDots
          onClick={() => {
            this.props.onDotsClick(item);
          }}
          backgroundColor={'#' + goal.borderColor}
        />
      ) : null;

    const indicator =
      item.hasOccurredAfterDeadline || isWorries ? (
        <RoundBulb color={getColor(goal.borderColor)} />
      ) : (
        <RoundStar color={getColor(goal.borderColor)} />
      );
    return (
      <div className={journalItemClass} key={item.id}>
        <p className={dateClass}>{parseDate(item.entered)}</p>
        <div
          className="content"
          style={{ backgroundColor: getColor(goal.backgroundColor) }}
        >
          {menuDots}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
            className="stickerContainerWrapper"
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                onClick={() => {
                  this.onEditSticker(item);
                }}
                className="stickersContainer"
                style={{
                  borderColor: getColor(goal.borderColor),
                }}
              >
                <img
                  style={{ padding: 0 }}
                  alt="sticker template"
                  src={navigationUtils.getSystemImageUrl(
                    item.stickerTemplate.image
                  )}
                />
                <div className="stickerTextBox">
                  {i18n.t(`stickerTranslations:${item.stickerTemplate.name}`)}
                </div>
              </div>
              {this.renderPicture(goal, item)}
            </div>
            {this.renderTextNote(item.userText, goal)}
          </div>
          <div
            className="icon"
            style={{
              backgroundColor: getColor(goal.backgroundColor),
            }}
          >
            {indicator}
          </div>

          {this.renderItemMenu(item)}
        </div>
      </div>
    );
  };

  renderJournalItems = (month) => {
    if (month.length > 0) {
      return month.map((item) => this.renderJournalItem(item));
    }
  };

  renderJournal = () => {
    const journal = [...this.props.journal];
    const { borderColor } = this.state;

    if (isNoStickers(journal) && !this.props.selectedTag) {
      return (
        <p className="noContent" style={{ color: borderColor }}>
          {i18n.t('stickers:journalScreen:noAddedStickers')}
        </p>
      );
    }

    if (isNoStickers(journal) && this.props.selectedTag) {
      if (isReadOnlyRoute()) {
        return (
          <p className="noContent" style={{ color: '#87ba3b', width: 262 }}>
            {i18n.t('stickers:journalScreen:noAddedStickersForTagReadOnly')}
          </p>
        ); // 262px is the width of dropdown
      } else {
        return (
          <p className="noContent" style={{ color: '#87ba3b', width: 262 }}>
            {i18n.t('stickers:journalScreen:noAddedStickersForTag')}
          </p>
        ); // 262px is the width of dropdown
      }
    }

    return journal.map((month, index) => {
      let monthColor = '#87ba3b';
      if (month.length > 0) {
        let afterOneYearOld = false;
        // let monthNumbers = [1,12,11,10,9,8,7,6,5,4,3,2,1,0];
        let monthNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1];
        if (index === 13) {
          afterOneYearOld = true;
        }

        console.log('index', index);
        if (index === 0) {
          const birthStickerIdx = month.findIndex(
            (el) => el?.stickerTemplate?.name == BIRTH_STICKER_NAME
          );
          console.log('birthStickerIdx', birthStickerIdx);
          if (birthStickerIdx >= 0) {
            console.log('hereeeeeeeeeeeeeeeeeeeeeee');
            const birthSticker = month[birthStickerIdx];
            month.splice(birthStickerIdx, 1);
            month.unshift(birthSticker);
          }
        }
        return (
          <div className="journal-items" key={index}>
            <div className="month">
              <MonthNumber
                number={monthNumbers[index]}
                fill={monthColor}
                afterOneYearOld={afterOneYearOld}
              />
            </div>
            {this.renderJournalItems(month)}
          </div>
        );
      }
      return null;
    });
  };

  render() {
    return <Fragment>{this.renderJournal()}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    selectedTag: state.selectedTag,
    journal: state.journal,
    user: state.authentication.userData,
    goals: state.goals,
  };
};

export default connect(mapStateToProps, {
  getJournal,
  loadGoalsFromLocalStorage,
  clearReducers,
  deleteAddedSticker,
  showDeleteModal,
  closeModal,
})(JournalItems);
