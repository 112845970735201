import navigationMethods from '../../../utils/navigationUtils';

const LogoSmall = () => {
  return (
    <img
      alt={'icon'}
      src={navigationMethods.getSystemImageUrl('navbar-icon-52x52.svg')}
      width={'35px'}
      height={'35px'}
    />
  );
};

export default LogoSmall;
