import * as i18n from 'i18next';
import { getFormValues, reset } from 'redux-form';
import { showErrorModal } from '..';
import appUtils from '../../utils/appUtils';
import {
  defaultServerValidationErrorCallback,
  isShareButtonDisabled,
} from '../../utils/commonUtils';
import {
  DOWNLOADED_JOURNAL_FILE_NAME,
  FORMS,
  RELATIVE_PAGE_PATHS,
  REQUEST_RESPONSE_CODE,
} from '../../utils/constants';
import navigationUtils from '../../utils/navigationUtils';
import { getAxiosWithToken } from '../../utils/webApi';
import {
  DELETE_ADDED_STICKER_SUCCESS,
  EXPIRED_STICKERS_EXIST,
  GET_EXPIRED_STICKERS,
  GET_USER_DETAILS,
  LOAD_ADDED_STICKER_SUCCESS,
  LOAD_JOURNAL_SUCCESS,
  SELECT_STICKER_SUCCESS,
} from '../actionTypes';
import {
  showShareModal,
  showSuccessModal,
  startLoader,
  stopLoader,
} from '../commonActions';

export const addStickerToJournal =
  (stickerTemplateId, entered, userText, languageCode) => async (dispatch) => {
    entered = appUtils.convertDateToBackendFormat(entered);

    dispatch(startLoader());

    const response = await getAxiosWithToken().post('journal/add-sticker', {
      stickerTemplateId,
      entered,
      userText,
      languageCode,
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
      const isShareAvailable = !isShareButtonDisabled(returnedData.data);
      const message = isShareAvailable
        ? i18n.t(
            'stickers:addStickerScreen:successfulAddedStickerAllowingShare'
          )
        : i18n.t('stickers:addStickerScreen:successfulAddedSticker');
      dispatch({
        type: LOAD_ADDED_STICKER_SUCCESS,
        payload: returnedData.data,
      });
      if (isShareAvailable) {
        dispatch(showShareModal(message));
      } else {
        dispatch(showSuccessModal(message));
      }
      // Another check if there are expired stickers not added to
      // the journal needs to be done every time a sticker
      // is added or deleted
      dispatch(checkIfExpiredStickersExist());

      navigationUtils.navigate(RELATIVE_PAGE_PATHS.JOURNAL);
    }
  };

export const addStickerWithPhotoToJournal =
  (stickerTemplateId, entered, userText, languageCode) =>
  async (dispatch, getState) => {
    let formData = new FormData();
    const formsState = getFormValues(FORMS.STICKER_FORM)(getState());
    const photo = formsState.uploadPhoto[0];

    entered = appUtils.convertDateToBackendFormat(entered);

    formData.append(
      'sticker-data',
      JSON.stringify({ stickerTemplateId, entered, userText })
    );
    formData.append('photo-file', photo);
    formData.append('language-code', languageCode);

    dispatch(startLoader());

    const response = await getAxiosWithToken(true).post(
      'journal/add-sticker-with-photo',
      formData
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
      const isShareAvailable = !isShareButtonDisabled(returnedData.data);
      const message = isShareAvailable
        ? i18n.t(
            'stickers:addStickerScreen:successfulAddedStickerAllowingShare'
          )
        : i18n.t('stickers:addStickerScreen:successfulAddedSticker');
      dispatch({
        type: LOAD_ADDED_STICKER_SUCCESS,
        payload: returnedData.data,
      });
      if (isShareAvailable) {
        dispatch(showShareModal(message));
      } else {
        dispatch(showSuccessModal(message));
      }
      // Another check if there are expired stickers not added to
      // the journal needs to be done every time a sticker
      // is added or deleted
      dispatch(checkIfExpiredStickersExist());

      navigationUtils.navigate(RELATIVE_PAGE_PATHS.JOURNAL);
    } else if (returnedData.code === 406) {
      dispatch(reset(FORMS.STICKER_FORM));
    }
  };

export const addStickerImage =
  (event, id, languageCode) => async (dispatch) => {
    event.preventDefault();

    let formData = new FormData();

    let stickerId = { id };
    let file = event.target.files[0];

    formData.append('sticker-data', JSON.stringify(stickerId));
    formData.append('photo-file', file);
    formData.append('language-code', languageCode);

    dispatch(startLoader());

    const response = await getAxiosWithToken(true).post(
      `/journal/upload-sticker-photo/${id}`,
      formData
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
      dispatch({
        type: LOAD_ADDED_STICKER_SUCCESS,
        payload: returnedData.data,
      });
    }
  };

export const getJournal = (tagId) => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().post('journal/list-stickers', {
    tagId,
  });

  dispatch(stopLoader());

  const returnedData = response.data;
  if (returnedData.success) {
    dispatch({ type: LOAD_JOURNAL_SUCCESS, payload: returnedData.data });
  }
};

export const checkIfExpiredStickersExist = () => async (dispatch) => {
  const response = await getAxiosWithToken().get(
    'journal/expired-stickers-exist'
  );

  const returnedData = response.data;
  if (returnedData.success) {
    dispatch({ type: EXPIRED_STICKERS_EXIST, payload: returnedData.data });
  }
};

export const loadSticker = (id, languageCode) => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().post(
    `/sticker/get-sticker/${id}`,
    { id, languageCode }
  );

  dispatch(stopLoader());

  const returnedData = response.data;
  if (returnedData.success) {
    dispatch({ type: SELECT_STICKER_SUCCESS, payload: returnedData.data });
  }
};

export const loadExpiredStickers = (tagId) => async (dispatch) => {
  var requestBody = {};
  if (tagId) {
    requestBody = { tagId };
  }

  dispatch(startLoader());

  const response = await getAxiosWithToken().post(
    'journal/list-expired-stickers',
    requestBody
  );

  dispatch(stopLoader());

  const returnedData = response.data;
  if (returnedData.success) {
    dispatch({ type: GET_EXPIRED_STICKERS, payload: returnedData.data });
  }
};

export const getAddedSticker = (id) => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken(
    null,
    defaultServerValidationErrorCallback
  ).post(`journal/get-sticker/${id}`, { id });

  dispatch(stopLoader());

  const returnedData = response.data;
  if (returnedData.success) {
    dispatch({
      type: LOAD_ADDED_STICKER_SUCCESS,
      payload: returnedData.data,
    });
  }
};

export const getReadOnlyAddedSticker =
  (encryptedUserId, id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken(
      null,
      defaultServerValidationErrorCallback
    ).post(`journal/get-read-only-sticker/${id}`, { encryptedUserId, id });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
      dispatch({
        type: LOAD_ADDED_STICKER_SUCCESS,
        payload: returnedData.data,
      });
    }
  };

export const getReadOnlyJournal =
  (encryptedUserId, tagId, languageCode) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
      'journal/list-read-only-stickers',
      { encryptedUserId, tagId, languageCode }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
      dispatch({
        type: LOAD_JOURNAL_SUCCESS,
        payload: returnedData.data,
      });
    }
  };

export const getReadOnlyJournalUser =
  (encryptedUserId, token) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
      'journal/get-read-only-journal-info',
      { encryptedUserId, token }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
      dispatch({ type: GET_USER_DETAILS, payload: returnedData.data });
    }
  };

export const ExtractTokenFromUrl = (url) => {
  const tokenQueryParam = 'token=';
  const tokenIndex = url.indexOf(tokenQueryParam);

  if (tokenIndex === -1) {
    return null;
  }

  let encodedToken = url.substring(tokenIndex + tokenQueryParam.length);

  const ampersandIndex = encodedToken.indexOf('&');
  if (ampersandIndex !== -1) {
    encodedToken = encodedToken.substring(0, ampersandIndex);
  }

  return encodedToken;
};

export const updateAddedSticker =
  (id, data, languageCode) => async (dispatch) => {
    data.entered = appUtils.convertDateToBackendFormat(data.entered);

    dispatch(startLoader());

    const response = await getAxiosWithToken().put(
      `journal/update-sticker/${id}`,
      { id, ...data, languageCode }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
      const isShareAvailable = !isShareButtonDisabled(returnedData.data);
      const message = isShareAvailable
        ? i18n.t(
            'stickers:addStickerScreen:successfullyUpdatedStickerAllowingShare'
          )
        : i18n.t('stickers:addStickerScreen:successfullyUpdatedSticker');
      dispatch({
        type: LOAD_ADDED_STICKER_SUCCESS,
        payload: returnedData.data,
      });
      if (isShareAvailable) {
        dispatch(showShareModal(message));
      } else {
        dispatch(showSuccessModal(message));
      }

      navigationUtils.navigate(RELATIVE_PAGE_PATHS.JOURNAL);
    }
  };

export const deleteAddedSticker = (id, languageCode) => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().delete(
    `journal/delete-sticker/${id}`,
    { data: { id, languageCode } }
  );

  dispatch(stopLoader());

  const returnedData = response.data;
  if (returnedData.success) {
    dispatch(checkIfExpiredStickersExist());
    dispatch({ type: DELETE_ADDED_STICKER_SUCCESS, payload: id });
  }
};

export const deleteStickerPhoto = (stickerId, callback) => async (dispatch) => {
  dispatch(startLoader());

  const response = await getAxiosWithToken().delete(
    `journal/delete-sticker-photo/${stickerId}`,
    { data: { stickerId } }
  );

  dispatch(stopLoader());

  const returnedData = response.data;
  if (returnedData.success) {
    callback();
  }
};
