import React from 'react';
import ReactLoading from 'react-loading';
import { RootStateOrAny, connect } from 'react-redux';
import { COLOR_SCHEME } from '../../utils/constants';

const Loader: React.FC<{ loader: boolean }> = ({ loader }) => {
  return loader ? (
    <div className="spinner-modal">
      <ReactLoading
        type="spinningBubbles"
        color={COLOR_SCHEME.GREEN}
        height="auto"
        width={150}
      />
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state: RootStateOrAny) => {
  return { loader: state.loader };
};

export default connect(mapStateToProps)(Loader);
