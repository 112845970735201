import {
  getAxiosWithToken,
  getAxiosWithTokenNoErrorHandling,
} from '../utils/webApi';
import { SAVE_PAID_FUNCTIONALITY_CREDENTIALS } from './actionTypes';
import { setPaidFunctionalityCredentialsInLocalStorage } from './authenticationActions';
import {
  showSuccessModal,
  startLoader,
  stopLoader,
  closeModal,
} from './commonActions';
import * as i18n from 'i18next';

export const getPaidFunctionalityCredentials =
  (data, languageCode) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
      'user/validate-credentials-for-unlocking-paid-stickers',
      { ...data, languageCode }
    );

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
      setPaidFunctionalityCredentialsInLocalStorage(data);

      dispatch({
        type: SAVE_PAID_FUNCTIONALITY_CREDENTIALS,
        payload: data,
      });
    }
  };

export const unlockPaidFunctionality = (data) => async (dispatch) => {
  dispatch(startLoader());

  const userEmails = data.userEmails;
  const credentials = data.paidFunctionalityCredentials;
  const response = await getAxiosWithTokenNoErrorHandling().post(
    'user/unlock-paid-stickers',
    { credentials, userEmails }
  );

  dispatch(stopLoader());

  if (response && response.data.userFriendlyMessage) {
    dispatch(showSuccessModal(response.data.userFriendlyMessage));
  }
  if (response && response.data.success) {
    dispatch(showSuccessModal(i18n.t('adminPanel:successfulUnlock')));
  }
};

export const lockPaidFunctionality = (data) => async (dispatch) => {
  dispatch(startLoader());

  const userEmails = data.userEmails;
  const credentials = data.paidFunctionalityCredentials;
  const response = await getAxiosWithTokenNoErrorHandling().post(
    'user/lock-paid-stickers',
    { credentials, userEmails }
  );

  dispatch(stopLoader());

  if (response && response.data.userFriendlyMessage) {
    dispatch(showSuccessModal(response.data.userFriendlyMessage));
  } else if (response && response.data.success) {
    dispatch(showSuccessModal(i18n.t('adminPanel:successfulLock')));
  }
};

export const deleteUserProfiles = (data) => async (dispatch) => {
  dispatch(closeModal());
  dispatch(startLoader());

  const userEmails = data.userEmails;
  const credentials = data.paidFunctionalityCredentials;
  const response = await getAxiosWithTokenNoErrorHandling().post(
    'user/delete-user-profiles-by-staff',
    { credentials, userEmails }
  );

  dispatch(stopLoader());

  if (response && response.data.userFriendlyMessage) {
    dispatch(showSuccessModal(response.data.userFriendlyMessage));
  } else if (response && response.data.success) {
    dispatch(showSuccessModal(i18n.t('adminPanel:successfulDelete')));
  }
};
