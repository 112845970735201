import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Container, Col, Button } from 'reactstrap';
import * as i18n from 'i18next';

import LocalNavigationComponent from '../LocalNavigationComponent';
import Password from '../common/svgComponents/Password';
import { Fields } from '../common/Fields';
import { formUtils } from '../../utils/formUtils';
import appUtils from '../../utils/appUtils';
import { resetPassword, navigateToLoginPage } from '../../actions';
import { LANGUAGE_CODE_KEY } from '../../utils/constants';

class ResetPasswordScreen extends Component {
  state = {
    resetPasswordToken: null,
  };

  componentDidMount = () => {
    var token = appUtils.getQueryStringAfterFirstEqualsCharacter(
      this.props.location.search
    );
    this.setState({ resetPasswordToken: token });
  };

  render = () => {
    return (
      <Container className="noPadding">
        <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
          <LocalNavigationComponent
            header={i18n.t(
              'userRegisterScreenActions:resetPasswordScreen:title'
            )}
            previousPage={this.props.navigateToLoginPage}
          />
          <form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            className="flexContainerColumn flexContainerCentered fix-padding"
          >
            <p style={{ marginTop: 30 }} />
            <div
              xs={10}
              sm={10}
              md={10}
              lg={8}
              className="flexContainerCentered"
            >
              <Password width={166} height={168} />
            </div>
            <div className="flexContainerColumn flexContainerCentered">
              {Fields.commonFields.password()}
              {Fields.commonFields.repeatPassword()}
              <span style={{ fontSize: 12, color: '#9f9f9f' }}>
                {i18n.t('shared:labels.passwordLength')}
              </span>
            </div>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={8}
              className="flexContainerColumn centred"
              style={{ margin: '24px 0px 12px 0px' }}
            >
              <Button className="greenBtn header next uppercase">
                {i18n.t('buttons:save')}
              </Button>
            </Col>
          </form>
        </Col>
      </Container>
    );
  };

  onSubmit = (formValues) => {
    const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
    return this.props.resetPassword(
      formValues.password,
      this.state.resetPasswordToken,
      languageCode
    );
  };
}

const requestPasswordResetForm = reduxForm({
  form: 'requestPasswordResetForm',
  validate: formUtils.validateResetPassword,
})(ResetPasswordScreen);

export default connect(null, { resetPassword, navigateToLoginPage })(
  requestPasswordResetForm
);
