import * as i18n from 'i18next';
import { defaultServerValidationErrorCallback } from '../utils/commonUtils';
import { RELATIVE_PAGE_PATHS } from '../utils/constants';
import navigationMethods from '../utils/navigationUtils';
import { getAxiosWithToken } from '../utils/webApi';
import {
    LOAD_CONTACTS,
    LOAD_CONTACT,
    SET_JOURNAL_TOKEN_STATUS_SUCCESS
} from './actionTypes';
import {
    closeModal, showSuccessModal,
    startLoader,
    stopLoader
} from './commonActions';
export const addContact = (data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post('contact/add', data);

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.CONTACTS);
        dispatch(
            showSuccessModal(
                i18n.t('contactsScreen:successfulAddedContact')
            )
        );
    }
};

export const getContacts = () => async (dispatch) => {
    dispatch(startLoader());
    const response = await getAxiosWithToken().get('contact/get-all');
    dispatch(stopLoader());

    const returnedData = response.data;
    
    if (returnedData.success) {
        dispatch({ type: LOAD_CONTACTS, payload: returnedData.data });
    }
}

export const getContact = (id) => async (dispatch) => {
    dispatch(startLoader());
    const response = await getAxiosWithToken( null, defaultServerValidationErrorCallback).post(`contact/${id}`, { id });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: LOAD_CONTACT, payload: returnedData.data });
        return returnedData.data;
    }
};

export const updateContact = (id, data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().put(`contact/${id}`, {
        id,
        ...data,
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.CONTACTS);
        dispatch(
            showSuccessModal(
                i18n.t('contactsScreen:successfulUpdatedContact')
            )
        );
    }
};

export const deleteContact = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().delete(`contact/${id}`, {
        data: { id },
    });

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.CONTACTS);
    }
};

export const handleShareJournalClickContact = (id) => async (dispatch) => {
    dispatch(startLoader());

    try {
        const response = await getAxiosWithToken().patch(`contact/sharing-journal/${id}`, {
            isJournalShared: true,
        });

        if (response.status === 200) {
            dispatch({
                type: SET_JOURNAL_TOKEN_STATUS_SUCCESS,
                payload: { id, isJournalShared: true },
            });
        } else {
            console.error('Error updating journal status:', response);
            throw new Error(`Failed to update journal status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error updating journal status:', error.message);
    } finally {
        dispatch(stopLoader());
    }
};


export const shareJournalThroughEmailContact = (email) => async (dispatch) => {
    dispatch(closeModal());
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        'journal/send-email-with-journal-url-contact',
        { email }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch(
            showSuccessModal(
                i18n.t('contactsScreen:successfulSharedJournal')
            )
        );
    }
};

export const handleStopSharingJournalContact = (id) => async (dispatch) => {
    dispatch(startLoader());

    try {
        const response = await getAxiosWithToken().patch(`contact/not-sharing-journal/${id}`, {
            isJournalShared: false,
        });

        if (response.status === 200) {
            dispatch({
                type: SET_JOURNAL_TOKEN_STATUS_SUCCESS,
                payload: { id, isJournalShared: false },
            });
        } else {
            console.error('Error updating journal status:', response);
            throw new Error(`Failed to update journal status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error updating journal status:', error.message);
    } finally {
        dispatch(
            showSuccessModal(
                i18n.t('contactsScreen:successfulStoppedSharingJournal')
            )
        );
        dispatch(stopLoader());
    }
};
