import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAxiosWithToken } from '../../utils/webApi';
import { startLoader, stopLoader } from '../../actions/commonActions';
import DOMPurify from 'dompurify';
import { LANGUAGE_CODE_KEY } from '../../utils/constants';

const ContactPolicies: React.FC<string> = () => {
  const [contactPolicies, setContactPolicies] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  const getTermsAndConditions = async () => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().get('policies/all');
    const languageBasedPolicies = response.data.data.filter(
      (policies) =>
        policies.languageCode === localStorage.getItem(LANGUAGE_CODE_KEY)
    );

    dispatch(stopLoader());

    if (response.status === 200) {
      setContactPolicies(DOMPurify.sanitize(languageBasedPolicies[0].contacts));
    }
  };
  return <div dangerouslySetInnerHTML={{ __html: contactPolicies }} />;
};

export default ContactPolicies;
