import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Route, Switch, } from 'react-router-dom';
import SubscriptionPlans from './SubscriptionPlans';
import { CheckoutForm, Return } from './Checkout';
import { STRIPE_PUBLIC_KEY } from '../../utils/constants';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import * as i18n from 'i18next';

const StripeRouter = () => {

  const publicKey = STRIPE_PUBLIC_KEY;

  if (!publicKey) {
    console.error('Missing or incorrect stripe public key! Cannot navigate to stripe related pages.');
    return <div>{i18n.t('payments:errorLoadingPlans')}</div>
  }

  const stripePromise = loadStripe(publicKey);

  return (
    <Elements stripe={stripePromise}>
      <Switch>
        <Route
          exact
          path={RELATIVE_PAGE_PATHS.SUBSCRIPTION_PLANS}
          component={SubscriptionPlans}
        />
        <Route
          exact
          path={RELATIVE_PAGE_PATHS.CHECKOUT}
          component={CheckoutForm}
        />
        <Route
          exact
          path={RELATIVE_PAGE_PATHS.RETURN}
          component={Return}
        />
      </Switch>
    </Elements>
  );
};

export default StripeRouter;