import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import appUtils from '../../utils/appUtils';
import { activateProfile } from '../../actions';
import { LANGUAGE_CODE_KEY } from '../../utils/constants';

class ActivateProfileScreen extends Component {
  componentDidMount = () => {
    var token = appUtils.getQueryStringAfterFirstEqualsCharacter(
      this.props.location.search
    );
    const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
    this.props.activateProfile(token, languageCode);
  };

  render = () => {
    // This component (for activating user profiles) doesn't have UI. It just needs to send the
    // activation request to backend
    return <Fragment />;
  };
}

export default connect(null, { activateProfile })(ActivateProfileScreen);
