import {
  RELATIVE_LINKS_PROD,
  RELATIVE_PAGE_PATHS,
  TRACKERS_SUB_TYPE,
  TRACKER_SUBTYPES,
} from '../../../utils/constants';
import navigationUtils from '../../../utils/navigationUtils';
import navigationMethods from '../../../utils/navigationUtils';
import { useTranslation } from 'react-i18next';

interface ITableEmptyState {
  trackerType?: string;
}

const TableEmptyState: React.FC<ITableEmptyState> = ({ trackerType }) => {
  const { t } = useTranslation();

  const renderTrackerIcons = () => {
    switch (trackerType) {
      case TRACKERS_SUB_TYPE.SLEEP:
        return (
          <img
            alt={'icon'}
            src={navigationMethods.getSystemImageUrl('sleep.svg')}
            width={'55px'}
            height={'55px'}
            style={{ cursor: 'pointer', margin: '20px 0' }}
            onClick={() => {
              navigationUtils.navigate(RELATIVE_PAGE_PATHS.SLEEP);
            }}
          />
        );
      case TRACKER_SUBTYPES.BOTTLE_FORMULA:
        return <FeedingEmptyStateIcons />;
      default:
        return null;
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <p className="empty-state-text">
        {t('analytics:sleepAnalytics:emptyStateTitle')}
      </p>
      <img
        src={navigationMethods.getSystemImageUrl('empty_state_analytics.svg')}
        alt="Empty-state"
        style={{ display: 'block', margin: 'auto' }}
      />
      {renderTrackerIcons()}
    </div>
  );
};

export default TableEmptyState;

const FeedingEmptyStateIcons = () => {
  return (
    <div>
      <img
        alt={'icon'}
        src={navigationMethods.getSystemImageUrl('tracker-weight.svg')}
        width={'55px'}
        height={'55px'}
        style={{ cursor: 'pointer', margin: '20px 0' }}
        onClick={() => {
          navigationUtils.navigate(RELATIVE_PAGE_PATHS.WEIGHT_TRACKER);
        }}
      />
      <img
        alt={'icon'}
        src={navigationMethods.getSystemImageUrl('tracker-poo-pampers.svg')}
        width={'55px'}
        height={'55px'}
        style={{ cursor: 'pointer', margin: '20px 0' }}
        onClick={() => {
          navigationUtils.navigate(
            `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.POO_DIAPER}`
          );
        }}
      />
      <img
        alt={'icon'}
        src={navigationMethods.getSystemImageUrl('tracker-wet-pampers.svg')}
        width={'55px'}
        height={'55px'}
        style={{ cursor: 'pointer', margin: '20px 0' }}
        onClick={() => {
          navigationUtils.navigate(
            `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.WET_DIAPER}`
          );
        }}
      />
      <img
        alt={'icon'}
        src={navigationMethods.getSystemImageUrl('tracker-breastfeeding.svg')}
        width={'55px'}
        height={'55px'}
        style={{ cursor: 'pointer', margin: '20px 0' }}
        onClick={() => {
          navigationUtils.navigate(RELATIVE_PAGE_PATHS.BREAST_FEEDING);
        }}
      />
      <img
        alt={'icon'}
        src={navigationMethods.getSystemImageUrl('tracker-formula-bottle.svg')}
        width={'55px'}
        height={'55px'}
        style={{ cursor: 'pointer', margin: '20px 0' }}
        onClick={() => {
          navigationUtils.navigate(
            `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.BOTTLE_FORMULA}`
          );
        }}
      />
      <img
        alt={'icon'}
        src={navigationMethods.getSystemImageUrl(
          'tracker-breast-milk-bottle.svg'
        )}
        width={'55px'}
        height={'55px'}
        style={{ cursor: 'pointer', margin: '20px 0' }}
        onClick={() => {
          navigationUtils.navigate(
            `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.BOTTLE_BREAST_MILK}`
          );
        }}
      />
    </div>
  );
};
