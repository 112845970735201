export const SLEEP_CELL_SIZE = 30;
export const MIN_IN_HOUR = 60;
export const SLEEP_PX_PER_MIN = SLEEP_CELL_SIZE / MIN_IN_HOUR;
export const CELL_NUMBER = 25;
export const ONE_EXTRA_ROW = 1;
export const BORDER_SIZE = 2;

export const SLEEP_RECORD_COLORS = {
  DAY: { color: 'rgba(123, 186, 221, 1)' },
  NIGHT: { color: 'rgba(59, 144, 186, 1)' },
  LULL: { color: 'rgba(255, 174, 156, 1)' },
  AWAKE: { color: '#fff8d8' },
};

export const SLEEP_CALENDAR_BORDERS = '4px solid rgba(235, 246, 255, 1)';
export const HOVER_SLEEP_MARGIN = 15;


export enum TrackerTypes {
  weight = 'weight',
  bottleFormula = 'bottle-formula',
  bottleBreastMilk = 'bottle-breast-milk',
  bottleWater = 'bottle-water',
  breastFeeding = 'breast-feeding',
  wetDiaper = 'wet-diaper',
  pooDiaper = 'poo-diaper',
}