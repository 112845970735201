import React from 'react';

const Diary = ({ width, height }) => {
  const viewBox = '0 0 28.35 28.35';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}>
      <title>икона_дневник_нова</title>
      <g id="Layer_2" data-name="Layer 2">
        <path
          d="M24.9,5.4a2.42,2.42,0,0,0-2.5-2.11H6.81A2.42,2.42,0,0,0,4.26,5.85v.77H3.43a2.51,2.51,0,0,0-.65.08,2,2,0,0,0,.5,3.93h1v1h-1a2,2,0,0,0-.56,3.89,2.72,2.72,0,0,0,.75.11h.77v1H3.32a1.93,1.93,0,0,0-1.53.75,1.91,1.91,0,0,0-.26,2.08A1.92,1.92,0,0,0,3.3,20.63h1v.11c0,.29,0,.58,0,.86a2.37,2.37,0,0,0,1.46,2.15,3.52,3.52,0,0,0,.61.18h0a2.58,2.58,0,0,0,2.77,2.11c3.34,0,6.74,0,10,0h5.29A2.42,2.42,0,0,0,27,23.49V8A2.41,2.41,0,0,0,24.9,5.4ZM7.93,24H22.32a2.42,2.42,0,0,0,2.61-2.61V7a.88.88,0,0,1,.56.77,1.27,1.27,0,0,1,0,.2V23.43c0,.77-.33,1.1-1.1,1.1H8.87A.94.94,0,0,1,7.93,24Zm-2.16-3.5A2,2,0,0,0,7,18.61a2,2,0,0,0-1.24-1.86v-.23c0-.34,0-.69,0-1.06A1.92,1.92,0,0,0,7,13.64a2,2,0,0,0-1.23-1.88c0-.47,0-.88,0-1.28A1.94,1.94,0,0,0,7,8.62,2,2,0,0,0,5.77,6.76c0-.18,0-.36,0-.53V5.82a.91.91,0,0,1,1-1H22.37a.92.92,0,0,1,1,1V21.42a.92.92,0,0,1-1,1H6.82a.93.93,0,0,1-1-1.05v-.28C5.78,20.91,5.78,20.69,5.77,20.47ZM4.18,14.12H3.36a.5.5,0,0,1-.5-.49.49.49,0,0,1,.5-.49H5a.5.5,0,0,1,.51.49.51.51,0,0,1-.52.49h-.8ZM5.5,8.63A.5.5,0,0,1,5,9.12q-.81,0-1.62,0h0a.49.49,0,0,1-.51-.49.5.5,0,0,1,.52-.49H5A.51.51,0,0,1,5.5,8.63ZM3.36,19.12a.49.49,0,1,1,0-1H5a.5.5,0,0,1,.51.49.5.5,0,0,1-.52.49H3.36Z"
          fill="#87ba3b" />
        <path
          d="M11.3,16.08l.09-.09-.09.09,0,0a16.92,16.92,0,0,0,3.08,2.21,1,1,0,0,0,.51.15,1,1,0,0,0,.5-.16l.65-.43.1-.06c.49-.33,1.05-.69,1.57-1.08a6.9,6.9,0,0,0,2.17-2.45,3.05,3.05,0,0,0-4.51-3.83,2.67,2.67,0,0,0-.34.29l-.15.13-.21-.19a3.18,3.18,0,0,0-2.56-.77,2.92,2.92,0,0,0-1.76,1c-1.08,1.18-1.06,2.59.06,4.31A7.38,7.38,0,0,0,11.3,16.08Zm5.59-4.63a1.53,1.53,0,0,1,1.84,1.49,1.92,1.92,0,0,1-.49,1.21,9.86,9.86,0,0,1-2.5,2.2,1,1,0,0,1-.17.1,2.93,2.93,0,0,0-.34.24c-.2.17-.33.2-.66,0a12.33,12.33,0,0,1-3-2.52,1.78,1.78,0,0,1-.48-1.37,1.48,1.48,0,0,1,1-1.28,1.56,1.56,0,0,1,.55-.1,1.52,1.52,0,0,1,1.05.45l.1.1.06.08a1.44,1.44,0,0,0,1,.47A1.42,1.42,0,0,0,16,12,1.59,1.59,0,0,1,16.89,11.45Z"
          fill="#87ba3b" />
      </g>
    </svg>
  );
};

export default Diary;

