import * as i18n from 'i18next';
import moment from './moment';
import {
  MULTI_VALUE_MEASUREMENT_SUBTYPES,
  TRACKERS_SUB_TYPE,
  TRACKERS_TYPE,
} from './constants';
import { formatDuration } from './timeUtils';

const formatMeasurement = (
  quantity,
  symbol,
  type,
  subType,
  additionalInfo,
  isTotalHistory
) => {
  let _quantity = quantity;

  if (type && type === TRACKERS_TYPE.CHANGE_DIAPERS && subType)
    _quantity = i18n.t(
      `trackers:trackingScreen:${type}.${subType}.${quantity}`
    );

  if (
    subType === TRACKERS_SUB_TYPE.BREAST_FEEDING ||
    subType === TRACKERS_SUB_TYPE.SLEEP ||
    subType === TRACKERS_SUB_TYPE.CRY
  ) {
    let typeOfAdditionalInfo;

    switch (subType) {
      case TRACKERS_SUB_TYPE.CRY:
        typeOfAdditionalInfo =
          ' ' + i18n.t('trackers:trackingScreen:tracking:CRYING');
        break;
      case TRACKERS_SUB_TYPE.SLEEP:
        typeOfAdditionalInfo =
          ' ' + i18n.t('trackers:sleepTrackerScreen:sleep');
        break;
      default:
        typeOfAdditionalInfo = '';
        break;
    }
    if (additionalInfo === 'LULL') typeOfAdditionalInfo = '';

    const result = i18n.t(`trackers:trackingScreen:${type}.${subType}`, {
      quantity: formatDuration(moment.duration(quantity, 'seconds')),
      additionalInfo:
        i18n.t(`trackers:trackingScreen:tracking.${additionalInfo}`) +
        typeOfAdditionalInfo,
    });
    return result;
  } else if (
    Object.values(MULTI_VALUE_MEASUREMENT_SUBTYPES).includes(subType)
  ) {
    const { symbol, product, categoryName } = JSON.parse(additionalInfo);

    if (subType === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING) {
      return constructFeedingHistory(
        JSON.parse(additionalInfo),
        subType,
        isTotalHistory
      );
    }

    let translatedProduct = i18n.t(
      `trackers:trackingScreen:${subType}.${product}`
    );

    const result = `${
      translatedProduct.includes(subType) ? product : translatedProduct
    }${symbol != null ? ', ' + quantity : ' '}
    ${
      symbol != null
        ? i18n.t(`trackers:trackingScreen:units.${symbol}`) +
          `${!isTotalHistory ? ', ' : ''}`
        : ''
    } 
    ${
      !isTotalHistory
        ? i18n.t(`trackers:trackingScreen:${categoryName}.label`)
        : ''
    }`;

    return result;
  }
  if (type === TRACKERS_TYPE.TIME_VALUE) {
    if (isTotalHistory) {
      return (
        quantity !== 0 &&
        `${_quantity}${i18n.t(`trackers:trackingScreen:symbols.${symbol}`)}`
      );
    } else {
      return quantity !== 0
        ? `${i18n.t(
            `trackers:trackingScreen:trackers.${subType}`
          )}, ${_quantity}${i18n.t(
            `trackers:trackingScreen:symbols.${symbol}`
          )}`
        : `${i18n.t(`trackers:trackingScreen:trackers.${subType}`)}`;
    }
  }

  return symbol
    ? `${_quantity}${i18n.t(`trackers:trackingScreen:symbols.${symbol}`)} `
    : `${_quantity} `;
};

const constructFeedingHistory = (additionalInfo, subType, isTotalHistory) => {
  const { product, productForm } = additionalInfo;
  let result = '';

  if (productForm) {
    result += i18n
      .t(`trackers:trackingScreen:foodForm.${productForm}`)
      .toLowerCase();
  }
  if (productForm && product) {
    result += ` ${i18n.t('trackers:trackingHistory:with')} `;
  }
  if (product) {
    let productTranslation = i18n.t(
      `trackers:trackingScreen:${subType}.${product}`
    );
    result += `${i18n
      .t(`trackers:trackingScreen:${subType}.added-new-product`)
      .toLowerCase()}: 
        ${productTranslation.includes(subType) ? product : productTranslation}`;
  }
  return result;
};

export default formatMeasurement;
