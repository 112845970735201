import React from 'react';
import { IProductDialog } from '../IProductDialogInterface.interface';
import { MODAL_DIALOG_STATE } from '../utils';
import AddProductDialog from './AddProductDialog';
import EditProductDialog from './EditProductDialog';
import DeleteProductDialog from './DeleteProductDialog';

const ProductDialog: React.FC<IProductDialog> = (props) => {
  switch (props.modalDialogState) {
    case MODAL_DIALOG_STATE.ADD:
      return <AddProductDialog {...props} />;
    case MODAL_DIALOG_STATE.EDIT:
      return <EditProductDialog {...props} />;
    case MODAL_DIALOG_STATE.DELETE:
      return <DeleteProductDialog {...props} />;
    case MODAL_DIALOG_STATE.CLOSED:
      return <></>;
    default:
      return <></>;
  }
};

export default ProductDialog;
