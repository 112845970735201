import { useEffect, useRef, type SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { centerTextHorizontally } from '../../../utils/svgUtils';

export const SleepTrackerButtonStartDayIcon = (
  props: SVGProps<SVGSVGElement>
) => {
  const { t } = useTranslation();
  const textRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    const svgWidth = 150.24; // Assuming fixed SVG width based on viewBox
    centerTextHorizontally(textRef, svgWidth);
  }, [t]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 150.2 150.2"
      {...props}
    >
      <path
        fill="#FFF"
        d="M30.8 19h90c6.1 0 11 4.9 11 11v90c0 6.1-4.9 11-11 11h-90c-6.1 0-11-4.9-11-11V30c0-6.1 4.9-11 11-11z"
      />
      <path
        fill="#87BA3B"
        d="M120.8 131h-90c-6.1 0-11-4.9-11-11V30c0-6.1 4.9-11 11-11h90c6.1 0 11 4.9 11 11v90c0 6.1-5 11-11 11zm-90-110c-5 0-9 4-9 9v90c0 5 4 9 9 9h90c5 0 9-4 9-9V30c0-5-4-9-9-9h-90z"
      />
      <path
        fill="#87BA3B"
        d="M89 60.1c1.9 1.2 2.5 3.6 1.3 5.5-.3.5-.8 1-1.3 1.3l-15.4 9.4c-1.9 1.2-4.4.6-5.5-1.3-.4-.6-.6-1.4-.6-2.1V54.1c0-2.2 1.8-4 4-4 .7 0 1.5.2 2.1.6L89 60.1z"
      />
      <text
        ref={textRef}
        x="0"
        y="105"
        fontWeight="bold"
        fontFamily="Nunito"
        letterSpacing="0.5px"
        fill="#87BA3B"
      >
        {t('trackers:trackingScreen:tracking.DAY')}
      </text>
    </svg>
  );
};
