import { Button } from '@mui/material';
import * as i18n from 'i18next';
import { orderBy } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkForNewVersion } from '../../../actions';
import { getAllTrackers } from '../../../actions/trackerActions';
import {
  addTrackingRecord,
  getTrackingRecordsByType,
  updateTrackingRecord,
} from '../../../actions/trackingRecordsActions';
import {
  RELATIVE_PAGE_PATHS,
  TRACKER_SUBTYPES,
  TRACKERS_SUB_TYPE,
} from '../../../utils/constants';

import { useHistory, useParams } from 'react-router';
import navigationMethods from '../../../utils/navigationUtils';
import TrackerHistory from '../TrackerHistory';
import { TrackingEditDialog } from '../tracking/TrackingEditDialog';
import { TrackingTimer } from '../tracking/TrackingTimer';
import TrackerRecordNote from '../TrackerRecordNote';
import { MODAL_TYPE } from '../../../models/modalTypes';
import { TIME_OF_THE_DAY } from '../tracking/models';
import { getTrackerId } from '../../../utils/commonUtils';

export const SleepTracker = () => {
  const [modal, setModal] = useState(MODAL_TYPE.DEFAULT);
  const tracker = useSelector(trackerIdSelector);
  const childBirthDate = useSelector(
    (state) => state.authentication.userData.childDateOfBirth
  );
  const records = useSelector((state) =>
    orderBy(
      state.trackingRecords.filter((x) => x.subTypeId === tracker.id),
      (x) => new Date(x.date),
      ['desc']
    )
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { recordId } = useParams();

  useEffect(() => {
    dispatch(getAllTrackers());
    const trackerId = getTrackerId(TRACKER_SUBTYPES.SLEEP);
    dispatch(getTrackingRecordsByType(trackerId, 0));
    dispatch(checkForNewVersion());
  }, [dispatch]);

  const recordToEdit = useMemo(() => {
    if (!records || !recordId) {
      return null;
    }
    return records.find((x) => x.id + '' === recordId);
  }, [recordId, records]);

  const renderLastRecordDetails = () => {
    const lastRecord = records[0];

    if (!lastRecord) {
      return (
        <div className="text">
          <p>{i18n.t('trackers:sleepTrackerScreen:lastRecord')}</p>
          <span style={{ marginBottom: '16px' }}>
            {i18n.t('trackers:sleepTrackerScreen:noRecordYet')}
          </span>
          <div className="analytics-link" style={{ margin: 0 }}>
            <a
              href="/analytics?type=sleep"
              style={{ textDecoration: 'underline' }}
            >
              {i18n.t('trackers:trackingScreen:analyticsLink')}
            </a>
          </div>
        </div>
      );
    }

    const side = i18n.t(
      `trackers:trackingScreen:tracking.${lastRecord.additionalInfo}`
    );
    const beforeTime = moment.duration(
      moment(new Date()).diff(lastRecord.date)
    );
    const days = Math.floor(beforeTime.asDays());
    return (
      <div className="text">
        <p>{i18n.t('trackers:sleepTrackerScreen:lastRecord')}</p>
        <p className="mb-0">
          <strong className="uppercase">{side} </strong>
          {i18n.t('trackers:sleepTrackerScreen:sleep')}
        </p>
        <span>
          {i18n.t('trackers:sleepTrackerScreen:sleepTime', {
            hours: beforeTime.hours(),
            minutes: beforeTime.minutes(),
            days: days
              ? i18n.t('trackers:sleepTrackerScreen:days', { days })
              : '',
          })}
        </span>
        <div className="analytics-link" style={{ margin: 0 }}>
          <a
            href="/analytics?type=sleep"
            style={{ textDecoration: 'underline' }}
          >
            {i18n.t('trackers:trackingScreen:analyticsLink')}
          </a>
        </div>
      </div>
    );
  };

  const renderLastRecord = () => {
    return (
      <div className="details">
        <img
          alt="SleepIcon"
          src={navigationMethods.getSystemImageUrl(tracker.image)}
          width={96}
          height={96}
          style={{
            backgroundColor: 'white',
            borderRadius: 8,
            border: '2px solid #87BA3B',
          }}
        />
        {renderLastRecordDetails()}
      </div>
    );
  };

  const closeRecord = () => {
    setModal(MODAL_TYPE.DEFAULT);
    history.replace(`${RELATIVE_PAGE_PATHS.SLEEP}`);
  };

  const save = (trackerId, startTime, duration, timeOfTheDay) => {
    dispatch(addTrackingRecord(trackerId, startTime, duration, timeOfTheDay));
  };

  const update = (id, startTime, duration, timeOfTheDay, notes) => {
    dispatch(
      updateTrackingRecord(id, startTime, duration, timeOfTheDay, notes)
    );
  };

  const saveRecord = (data) => {
    const { startTime, duration, additionalInfo } = data;

    if (recordToEdit) {
      update(
        recordToEdit.id,
        startTime,
        duration,
        additionalInfo,
        recordToEdit.notes
      );
      history.replace(`${RELATIVE_PAGE_PATHS.SLEEP}`);
      return;
    }

    save(tracker.id, startTime, duration, additionalInfo);
  };

  const editRecord = (id) => {
    setModal(MODAL_TYPE.EDIT);
    history.push(`${RELATIVE_PAGE_PATHS.SLEEP}/${id}`);
  };

  const openNote = (id) => {
    setModal(MODAL_TYPE.NOTE);
    history.push(`${RELATIVE_PAGE_PATHS.SLEEP}/${id}`);
  };

  const saveNote = (note) => {
    if (note) {
      dispatch(
        updateTrackingRecord(
          recordToEdit.id,
          recordToEdit.date,
          recordToEdit.quantity,
          recordToEdit.additionalInfo,
          note
        )
      );
      history.replace(`${RELATIVE_PAGE_PATHS.SLEEP}`);
    }
  };

  const deleteNote = () => {
    dispatch(
      updateTrackingRecord(
        recordToEdit.id,
        recordToEdit.date,
        recordToEdit.quantity,
        recordToEdit.additionalInfo,
        ''
      )
    );
    history.replace(`${RELATIVE_PAGE_PATHS.SLEEP}`);
  };

  const cancelNote = () => {
    setModal(MODAL_TYPE.DEFAULT);
    history.replace(RELATIVE_PAGE_PATHS.SLEEP);
  };

  return (
    <>
      <div className="flexContainerColumn flexContainerCentered">
        {renderLastRecord()}
        <TrackingTimer onSave={saveRecord} tracker={TRACKERS_SUB_TYPE.SLEEP} />
        <div className="pb-3 d-flex justify-content-center">
          <Button
            type="submit"
            variant="outlined"
            onClick={() => setModal(MODAL_TYPE.EDIT)}
            disabled={modal !== MODAL_TYPE.DEFAULT}
          >
            {i18n.t('trackers:sleepTrackerScreen:buttons.add')}
          </Button>
        </div>
        <TrackerHistory
          historyRecords={records}
          requiresSpecialEditing={true}
          trackerName={TRACKER_SUBTYPES.SLEEP}
          onEdit={editRecord}
          onAdd={openNote}
          setModal={setModal}
        />
      </div>

      {modal === MODAL_TYPE.EDIT && (
        <TrackingEditDialog
          trackerType={TRACKERS_SUB_TYPE.SLEEP}
          startTime={recordToEdit?.date}
          quantity={recordToEdit?.quantity}
          additionalInfo={recordToEdit?.additionalInfo || TIME_OF_THE_DAY.DAY}
          onClose={closeRecord}
          onSave={saveRecord}
          isEdit={!!recordToEdit}
        />
      )}
      {modal === MODAL_TYPE.NOTE && (
        <TrackerRecordNote
          currentNote={recordToEdit.notes}
          onSave={saveNote}
          onCancel={cancelNote}
          onDelete={deleteNote}
        />
      )}
    </>
  );
};

const trackerIdSelector = (state) => {
  const tracker = (state.trackers || []).find(
    (x) => x.name === TRACKERS_SUB_TYPE.SLEEP
  );
  return tracker ? tracker : -1;
};
