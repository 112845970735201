import React, { createContext, useState, ReactNode } from 'react';
import moment from 'moment';

interface IDatesContext {
  startDate: Date;
  endDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const DatesContext = createContext<IDatesContext | undefined>(undefined);

export const DateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [startDate, setStartDate] = useState<Date>(moment().startOf('day').subtract(4, 'days').toDate());
  const [endDate, setEndDate] = useState<Date>(moment().endOf('day').toDate());

  return (
    <DatesContext.Provider value={{ startDate, endDate, setStartDate, setEndDate }}>
      {children}
    </DatesContext.Provider>
  );
};