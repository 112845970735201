import { Button, Stack } from '@mui/material';
import * as i18n from 'i18next';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import TrackerHistory from './TrackerHistory';
import TrackerInput from './TrackerInput';
import { TrackerDateTime } from './shared/TrackerDateTime';
import {
  MULTI_VALUE_MEASUREMENT_SUBTYPES,
  TRACKERS_TYPE,
  QUANTITY_DEFAULT_VALUES,
} from '../../utils/constants';
import { MODAL_TYPE } from '../../models/modalTypes';
import { CLEAR_CHECKBOX } from '../../actions/actionTypes';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const TrackerForm = ({
  tracker,
  records,
  record,
  onSave,
  onCancel,
  onOpenNote,
  modalType,
}) => {
  const lastRecord = record || records[0] || {};
  const { quantity } = lastRecord;
  const isMultiSelect = Object.values(
    MULTI_VALUE_MEASUREMENT_SUBTYPES
  ).includes(tracker.name);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { typeId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = useCallback(
    async (
      {
        date,
        quantity,
        productQuantity,
        id,
        product,
        symbol,
        totalQuantity,
        totalSymbol,
        totalProduct,
        productForm,
      },
      form
    ) => {
      let newRecord = {
        id,
        subTypeId: tracker.id,
        quantity,
        date: moment(date).toISOString(),
      };

      if (isMultiSelect) {
        let productNameKey = product ? product.split(':')[0] : '';
        let categoryNameKey = product ? product.split(':')[1] : '';
        newRecord = {
          id,
          subTypeId: tracker.id,
          quantity: productQuantity,
          date: moment(date).toISOString(),
          additionalInfo: JSON.stringify({
            product: productNameKey,
            symbol: symbol,
            categoryName: categoryNameKey,
            totalQuantity: totalQuantity !== undefined && totalQuantity,
            totalSymbol: totalSymbol !== undefined && totalSymbol,
            totalProduct: totalProduct !== undefined && totalProduct,
            productForm: productForm !== undefined && productForm,
          }),
        };
      }

      dispatch({ type: CLEAR_CHECKBOX });
      onSave && (await onSave(newRecord));
      form.reset();
    },
    [tracker.id, onSave]
  );
  const quantities = [0, 0, quantity || 120, 0, quantity || 1500];

  const additionalInfoMultiSelect =
    isMultiSelect && record ? JSON.parse(record.additionalInfo) : null;

  const initialValues = {
    id: record && record.id,
    quantity:
      quantity ||
      QUANTITY_DEFAULT_VALUES[tracker.name] ||
      (record && record.quantity) ||
      quantities[tracker.type.id],
    date: moment(record && record.date).format('YYYY-MM-DD HH:mm'),
    symbol: additionalInfoMultiSelect && additionalInfoMultiSelect.symbol,
    product:
      additionalInfoMultiSelect &&
      `${additionalInfoMultiSelect.product}:${additionalInfoMultiSelect.categoryName}`,
    productQuantity:
      (additionalInfoMultiSelect && record && record.quantity) ||
      quantities[tracker.type.id],
    // date: moment(record && record.date).format('YYYY-MM-DD'),
    // time: moment(record && record.date).format('HH:mm'),
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };
  const requiredField = t('trackers:trackingScreen:validations.requiredField');
  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (
            tracker.type.name === TRACKERS_TYPE.CHANGE_DIAPERS ||
            tracker.type.name === TRACKERS_TYPE.TIME_VALUE
          ) {
          } else if (
            tracker.name === MULTI_VALUE_MEASUREMENT_SUBTYPES.MEDICINE
          ) {
            if (values.symbol && !values.productQuantity) {
              errors.productQuantity = requiredField;
            }
            if (!values.product) {
              errors.product = requiredField;
            }
          } else if (
            tracker.name === MULTI_VALUE_MEASUREMENT_SUBTYPES.FEEDING
          ) {
          } else {
            if (!values.quantity) {
              errors.quantity = requiredField;
            } else if (Number(values.quantity) <= 0) {
              errors.quantity = t(
                'trackers:trackingScreen:validations.valueMustbeMoreThanZero'
              );
            }
          }

          if (!values.date) {
            errors.date = requiredField;
          }
          if (moment(values.date).isAfter(moment())) {
            errors.date = t(
              'trackers:trackingScreen:validations.dateCannotBeInTheFuture'
            );
          }
          return errors;
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="tracker-form">
            <TrackerInput name="quantity" tracker={tracker} />
            <Stack direction="row" spacing={2} paddingY={4} width={'100%'}>
              <TrackerDateTime name="date" />
              {/* <Field name="date">
                {({ input }) => (
                  <TextField
                    fullWidth
                    id="date"
                    label="Дата"
                    type="date"
                    value={input.value}
                    onChange={input.onChange}
                    // sx={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: moment().format('YYYY-MM-DD') }}
                  />
                )}
              </Field>
              <Field name="time">
                {({ input, meta }) => {
                  console.log(input.name, meta.error, meta.touched);
                  return (
                    <TextField
                      fullWidth
                      id="time"
                      label="Час"
                      type="time"
                      value={input.value}
                      onChange={input.onChange}
                      // sx={{ width: 150 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched && meta.error}
                    />
                  );
                }}
              </Field> */}
            </Stack>
            <Stack spacing={2} display="flex" alignItems="center" paddingY={4}>
              <Button type="submit" variant="contained">
                {typeof record === 'object' && modalType !== MODAL_TYPE.NOTE
                  ? i18n.t('buttons:saveChanges')
                  : i18n.t('buttons:add')}
              </Button>
              {typeof record === 'object' && modalType !== MODAL_TYPE.NOTE && (
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleCancel}
                  color="error"
                >
                  {i18n.t('buttons:cancel')}
                </Button>
              )}
            </Stack>
          </form>
        )}
      </Form>
      <TrackerHistory
        historyRecords={records}
        trackerName={typeId}
        onAdd={onOpenNote}
      />
    </>
  );
};

export default TrackerForm;
