import ReactLoading from 'react-loading';
import { COLOR_SCHEME } from '../../utils/constants';

const ManualLoader: React.FC = () => {
  return (
    <div className="spinner-modal">
      <ReactLoading
        type="spinningBubbles"
        color={COLOR_SCHEME.GREEN}
        height="auto"
        width={150}
      />
    </div>
  );
};

export default ManualLoader;
