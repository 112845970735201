export const executeAddHomeAndroid = (deferredPromptItem) => {
        const a2hsBtn = document.querySelector('.add-button');
        const mainPWAcontainer = document.getElementById('main-pwa-container');
        const closeA2hsBtn = document.getElementById('close-a2hs-Btn');
        const isAndroidDevice = /(android)/i.test(navigator.userAgent);

        let deferredPrompt;
         if (isAndroidDevice) {
                    mainPWAcontainer.style.display = 'flex';
                    deferredPrompt = deferredPromptItem;
                    // Update UI to notify the user they can add to home screen
                    a2hsBtn.addEventListener('click', (e) => {
                        // hide our user interface that shows our A2HS button
                        mainPWAcontainer.style.display = 'none';
                        // Show the prompt
                        deferredPrompt.prompt();
                    });
            }
            
            // Event for closing Add To Home Screen banner
            closeA2hsBtn.addEventListener('click', (e) => {
            mainPWAcontainer.style.display = 'none';
            deferredPromptItem = null;
        });

    }

    export const executeAddHomeIOS = (isiPadDevice) => {
        const mainPWAcontainer = document.getElementById('main-pwa-container');
        const a2hsBtn = document.querySelector('.add-button');
        const pwaiOSText = document.getElementById('pwa-ios-text');
        const pwaContainer = document.getElementById('pwa-container');
        const closeA2hsBtn = document.getElementById('close-a2hs-Btn');

        mainPWAcontainer.style.display = 'flex';
        a2hsBtn.style.display = 'none';
        pwaiOSText.style.display = 'block';

        if (isiPadDevice) {
            pwaContainer.style.width = '50%';
        }
       // Event for closing Add To Home Screen banner
       closeA2hsBtn.addEventListener('click', (e) => {
       mainPWAcontainer.style.display = 'none';
       });
            
    }