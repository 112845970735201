import React from 'react';
import * as i18n from 'i18next';
import { Button, Col, Container } from 'reactstrap';
import navigationMethods from '../../utils/navigationUtils';
import {
    ALLOWED_SPECIALISTS_PER_USER,
    RELATIVE_PAGE_PATHS,
} from '../../utils/constants';
import { connect } from 'react-redux';
import MenuItem from '../common/MenuItem';
import { checkForNewVersion } from '../../actions';
import { getContacts } from '../../actions/contactActions';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

class ContactsScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDescriptionBoxShown: true,
        };
    }

    componentDidMount() {
        this.props.getContacts();
        this.props.checkForNewVersion();
    }

    renderContacts = () => {
        const contacts = [...this.props.contacts];
        return contacts.map((contact, index) => {
            return (
                <MenuItem
                    onClick={() => {
                        navigationMethods.navigate(
                            RELATIVE_PAGE_PATHS.CONTACTS + contact.id
                        );
                    }}
                    title={contact.name}
                    key={index}
                />
            );
        });
    };

    renderSectionTitle = (title) => {
        return (
            <div className="section-title">
                <p className="header mainGreenColor header-small">{i18n.t('contactsScreen:connections')}</p>
                <hr />
            </div>
        );
    };

    renderAddContactsButton = () => {
        const contacts = [...this.props.contacts];
        if (this.props.contacts.length < ALLOWED_SPECIALISTS_PER_USER) {
            return (
                <Col
                    xs={10}
                    sm={10}
                    md={10}
                    lg={8}
                    className="flexContainerColumn centred"
                >
                    <Button
                        onClick={() => {
                            navigationMethods.navigate(RELATIVE_PAGE_PATHS.ADD_CONTACT);
                        }}
                        className="greenBtn header next uppercase"
                    >
                        {i18n.t('contactsScreen:buttons.addContact')}
                    </Button>
                </Col>
            );
        }
    };

    render() {
        const contacts = [...this.props.contacts];
        return (
            <Container className="noPadding">
                <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
                    <div className="flexContainerColumn flexContainerCentered profile-form fix-padding mb-5">
                        <div className="menu-items breastfeeding">
                            <Typography
                                variant="body2"
                                className="details"
                                sx={{
                                    marginBottom: 2,
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                }}
                            >
                               <b> {i18n.t('contactsScreen:boldedHeader')}</b>
                                <br /> 
                                {i18n.t('contactsScreen:contactDescription')} 
                            </Typography>
                            {this.renderSectionTitle(i18n.t('contactsScreen:contact'))}
                            {this.renderContacts()}
                        </div>
                        {this.renderAddContactsButton()}
                        <Box marginTop={2}></Box>
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contacts: state.contacts.contacts || new Array(0),
    };
};

export default connect(mapStateToProps, { getContacts, checkForNewVersion })(ContactsScreen);
