import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as i18n from 'i18next';
import { Container, Col, Button, NavLink } from 'reactstrap';

import LocalNavigationComponent from '../LocalNavigationComponent';
import { Fields } from '../common/Fields';
import { formUtils } from '../../utils/formUtils';
import { login } from '../../actions';
import { LANGUAGE_CODE_KEY, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import { navigateToHomeScreen } from '../../actions/index';
import Family from '../common/svgComponents/Family';
import { Link } from 'react-router-dom';

class LoginScreen extends Component {
  render = () => {
    return (
      <Container className="noPadding">
        <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
          <LocalNavigationComponent
            header={i18n.t('userRegisterScreenActions:loginScreen:title')}
            previousPage={this.props.navigateToHomeScreen}
          />
          <form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            className="flexContainerColumn flexContainerCentered fix-padding"
          >
            <p style={{ marginTop: 30 }} />
            <div
              xs={10}
              sm={10}
              md={10}
              lg={8}
              className="flexContainerCentered"
            >
              <Family width={166} height={168} />
            </div>
            <div
              className="flexContainerColumn flexContainerCentered"
              style={{ margin: '24px 0px' }}
            >
              {Fields.commonFields.email()}
              {Fields.commonFields.password()}
            </div>
            <NavLink
              href={RELATIVE_PAGE_PATHS.REQUEST_PASSWORD_RESET}
              style={{ fontSize: 12, color: '#87ba3b' }}
            >
              {i18n.t(
                'userRegisterScreenActions:loginScreen:labels.forgottenPassword'
              )}
            </NavLink>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={8}
              className="flexContainerColumn centred"
              style={{ margin: '24px 0px 12px 0px' }}
            >
              <Button className="greenBtn header next uppercase">
                {i18n.t('userRegisterScreenActions:loginScreen:submitButton')}
              </Button>
            </Col>
            <p style={{ fontSize: 12 }}>
              {i18n.t('userRegisterScreenActions:loginScreen:labels.noProfile')}
              &nbsp;
              <Link
                to={RELATIVE_PAGE_PATHS.REGISTER}
                style={{ color: '#87ba3b' }}
              >
                {i18n.t(
                  'userRegisterScreenActions:loginScreen:labels.createProfile'
                )}
              </Link>
            </p>
          </form>
        </Col>
      </Container>
    );
  };

  onSubmit = (formValues) => {
    const { email, password } = formValues;
    const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
    this.props.login(email, password, languageCode);
  };
}

const loginForm = reduxForm({
  form: 'loginForm',
  validate: formUtils.validateLogin,
})(LoginScreen);

export default connect(null, { login, navigateToHomeScreen })(loginForm);
