import { useTranslation } from 'react-i18next';

const AddToJournalTitle = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginTop: '40px',
        width: '295px',
        height: '58px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        borderRadius: '12px',
        backgroundColor: 'rgb(244, 252, 229)',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          zIndex: 1,
          fontFamily: 'Nunito-Regular',
          color: '#87ba3b',
          fontWeight: 'bolder',
          lineHeight: '1.5',
        }}
      >
        {t('goalsAndTagsScreen:addToJournal')}
      </div>
      <div
        style={{
          width: '59px',
          height: '59px',
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgb(244, 252, 229)',
          borderRadius: '100%',
        }}
      >
        <svg
          style={{ scale: '0.7' }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 43.455 44.693"
        >
          <g
            id="Кръгче_със_звезда_за_дневник"
            data-name="Кръгче със звезда за дневник"
            transform="translate(-166.045 -848.5)"
          >
            <path
              id="Subtraction_42"
              data-name="Subtraction 42"
              d="M257.7,35a16.79,16.79,0,0,1-11.809-4.779,16.036,16.036,0,0,1-2.042-20.655,16.622,16.622,0,0,1,7.341-5.911,16.114,16.114,0,0,0-6.214,12.7,16.53,16.53,0,0,0,16.7,16.316,16.933,16.933,0,0,0,6.509-1.286,16.748,16.748,0,0,1-4.828,2.656A17,17,0,0,1,257.7,35Z"
              transform="translate(2.002 1048.934) rotate(-48)"
              fill="rgb(135, 186, 59)"
              opacity="0.52"
            />
            <g
              transform="matrix(1, 0, 0, 1, 166.04, 848.5)"
              filter="url(#Ellipse_1000)"
            >
              <circle
                id="Ellipse_1000-2"
                data-name="Ellipse 1000"
                cx="16.5"
                cy="16.5"
                r="16.5"
                transform="translate(5.96 3.5)"
                fill="#fff"
              />
            </g>
            <g
              id="Group_1548"
              data-name="Group 1548"
              transform="translate(44.12 844.798)"
            >
              <path
                id="Icon_awesome-star"
                data-name="Icon awesome-star"
                d="M143.869,18.712l-1.685,3.416-3.769.549a.826.826,0,0,0-.456,1.409l2.726,2.656-.645,3.753a.825.825,0,0,0,1.2.869l3.371-1.772,3.367,1.773a.825.825,0,0,0,1.2-.869l-.641-3.754,2.726-2.657a.826.826,0,0,0-.456-1.409l-3.768-.549-1.685-3.416a.826.826,0,0,0-1.48,0Z"
                transform="translate(-0.226 -0.299)"
                fill="rgb(135, 186, 59)"
              />
              <g
                id="Group_1678"
                data-name="Group 1678"
                transform="translate(133.129 12.202)"
              >
                <g
                  id="Line_2"
                  data-name="Line 2"
                  transform="translate(17.535 4.077)"
                >
                  <path
                    id="Path_1736"
                    data-name="Path 1736"
                    d="M152.194,18.785a.618.618,0,0,1-.343-1.133l1.6-1.058a.618.618,0,0,1,.684,1.03l-1.6,1.058A.608.608,0,0,1,152.194,18.785Z"
                    transform="translate(-151.574 -16.491)"
                    fill="rgb(135, 186, 59)"
                  />
                </g>
                <g id="Line_6" data-name="Line 6" transform="translate(10.636)">
                  <path
                    id="Path_1737"
                    data-name="Path 1737"
                    d="M144.935,15.426h-.009a.618.618,0,0,1-.609-.626l.027-1.988a.654.654,0,0,1,.626-.609.619.619,0,0,1,.61.626l-.028,1.988A.619.619,0,0,1,144.935,15.426Z"
                    transform="translate(-144.317 -12.202)"
                    fill="rgb(135, 186, 59)"
                  />
                </g>
                <g
                  id="Line_4"
                  data-name="Line 4"
                  transform="translate(2.509 4.077)"
                >
                  <path
                    id="Path_1738"
                    data-name="Path 1738"
                    d="M138,19.235a.614.614,0,0,1-.421-.166l-1.619-1.507a.618.618,0,1,1,.842-.905l1.619,1.508a.618.618,0,0,1-.421,1.07Z"
                    transform="translate(-135.768 -16.491)"
                    fill="rgb(135, 186, 59)"
                  />
                </g>
                <g
                  id="Line_3"
                  data-name="Line 3"
                  transform="translate(20.318 13.025)"
                >
                  <path
                    id="Path_1739"
                    data-name="Path 1739"
                    d="M157.069,27.6a.609.609,0,0,1-.143-.017l-1.949-.461a.617.617,0,1,1,.284-1.2l1.949.461a.618.618,0,0,1-.142,1.219Z"
                    transform="translate(-154.502 -25.903)"
                    fill="rgb(135, 186, 59)"
                  />
                </g>
                <g
                  id="Line_5"
                  data-name="Line 5"
                  transform="translate(0 13.136)"
                >
                  <path
                    id="Path_1740"
                    data-name="Path 1740"
                    d="M133.746,27.6a.618.618,0,0,1-.121-1.223l1.734-.348a.618.618,0,0,1,.243,1.211l-1.734.348A.6.6,0,0,1,133.746,27.6Z"
                    transform="translate(-133.129 -26.02)"
                    fill="rgb(135, 186, 59)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default AddToJournalTitle;
