import moment from 'moment';
import { DEFAULT_LOCALE_CODE } from './constants';

moment.defineLocale('bg', {
  parentLocale: 'en',
  relativeTime: {
    future: 'след %s',
    past: 'преди %s',
    ss: '%dсек',
    s: '%dсек',
    m: '%dм',
    mm: '%dм',
    h: '%dч',
    hh: '%dч',
    d: '%dд',
    dd: '%dд',
    w: 'седмица',
    ww: '%dседмици',
    M: 'месец',
    MM: '%dмесеца',
    y: 'година',
    yy: '%dг',
  },
});

moment.defineLocale('ro', {
  parentLocale: 'en',
  relativeTime: {
    future: 'dupa %s',
    past: '%s în urmă',
    ss: '%dsecunde',
    s: '%dsecunde',
    m: '%dm',
    mm: '%dm',
    h: '%dore',
    hh: '%dore',
    d: '%dzi',
    dd: '%dzi',
    w: 'săptămână',
    ww: '%dsăptămâni',
    M: 'lună',
    MM: '%dluni',
    y: 'an',
    yy: '%dan',
  },
});

moment.defineLocale('de', {
  parentLocale: 'en',
  relativeTime: {
    future: 'nach %s',
    past: 'vor %s',
    ss: '%dsek',
    s: '%dsek',
    m: '%dm',
    mm: '%dm',
    h: '%dStunden',
    hh: '%dStunden',
    d: '%dTag',
    dd: '%dTag',
    w: 'Woche',
    ww: '%dWochen',
    M: 'Monat',
    MM: '%dMonate',
    y: 'Jahr',
    yy: '%dJahr',
  },
});

moment.defineLocale('ru', {
  parentLocale: 'en',
  relativeTime: {
    future: 'через %s',
    past: '%s назад',
    ss: '%dсек',
    s: '%dсек',
    m: '%dм',
    mm: '%dм',
    h: '%dч',
    hh: '%dч',
    d: '%dдень',
    dd: '%dдня',
    w: 'недели',
    ww: '%dнедели',
    M: 'месяц',
    MM: '%dмесяцы',
    y: 'год',
    yy: '%dг',
  },
});

// Set new thresholds
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('ss', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);
moment.relativeTimeThreshold('M', 12);

moment.locale(DEFAULT_LOCALE_CODE);

export default moment;
