import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MODAL_DIALOG_STATE } from '../utils';

interface IEditComponent {
  customProductId: number | undefined;
  setModalDialogState: Dispatch<SetStateAction<string>>;
}

const EditComponent: React.FC<IEditComponent> = ({
  customProductId,
  setModalDialogState,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleDelete = async () => {
    setModalDialogState(MODAL_DIALOG_STATE.DELETE);
    handleClose();
  };

  const handleEdit = async () => {
    setModalDialogState(MODAL_DIALOG_STATE.EDIT);
    handleClose();
  };

  return (
    <>
      {customProductId && (
        <div>
          <IconButton
            aria-label="delete"
            onClick={handleClick}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              dense: true,
            }}
          >
            <MenuItem disabled={false} onClick={() => handleEdit()}>
              {t('trackers:trackingHistoryItem:buttons.edit')}
            </MenuItem>
            <MenuItem disabled={false} onClick={() => handleDelete()}>
              {t('trackers:trackingHistoryItem:buttons.delete')}
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
};

export default EditComponent;
