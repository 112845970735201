import { RefObject } from 'react';

interface TextElement extends SVGTextElement {
  getBBox(): SVGRect;
}

export const centerTextHorizontally = (
  textRef: RefObject<TextElement>,
  svgWidth: number
) => {
  if (textRef.current) {
    const textWidth = textRef.current.getBBox().width;
    const newX = (svgWidth - textWidth) / 2;
    textRef.current.setAttribute('x', newX.toString());
  }
};