import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GrowthCurves from './GrowthCurves';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTrackingRecords } from '../../actions/trackingRecordsActions';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import React from 'react';
import {
  RELATIVE_PAGE_PATHS,
  USER_DATA_IN_LOCAL_STORAGE,
} from '../../utils/constants';
import SleepAnalytics from './SleepAnalytics';
import { ITrackerRecord } from '../../interfaces/ITrackerRecord.interface';
import { IUser } from '../../interfaces/IUser.interface';
import FeedingAnalytics from './FeedingAnalytics';
import { DateProvider } from './components/DateProvider';
import ManualLoader from '../common/ManualLoader';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function getQueryType(index: number) {
  switch (index) {
    case 0:
      return 'growth';
    case 1:
      return 'feeding';
    case 2:
      return 'sleep';
    default:
      return '';
  }
}

function getQueryTab(type: string) {
  switch (type) {
    case 'growth':
      return 0;
    case 'feeding':
      return 1;
    case 'sleep':
      return 2;
    default:
      return 0;
  }
}

const AnalyticsPage = () => {
  const [tab, setTab] = useState(0);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [isTrackerDataLoaded, setIsTrackerDataLoaded] = useState(false);
  //@ts-ignore
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const trackerRecords: ITrackerRecord[] = useSelector(
    (state: any) => state.trackingRecords
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    history.push(
      `${RELATIVE_PAGE_PATHS.ANALYTICS}?type=${getQueryType(newValue)}`
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (!initialLoad) {
      // wait fo the getAllTrackingRecords to finish
      const fetchData = async () => {
        await dispatch(getAllTrackingRecords(signal));
        setIsTrackerDataLoaded(true);
      };

      fetchData();
      setInitialLoad(true);

      const user = localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE);
      if (!user) return;

      const currentUser: IUser = JSON.parse(user);
      setCurrentUser(currentUser);
    }

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    setTab(getQueryTab(type || ''));
  }, []);

  if (!isTrackerDataLoaded || loader) {
    return <ManualLoader />;
  }
  return (
    <div
      style={{
        marginBottom: '85px',
        width: '-webkit-fill-available',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box id="tabs-sticky">
          <Tabs
            id="tabs-wrapper"
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab
              label={t('analytics:shared.growth')}
              className="tab-section"
              {...a11yProps(0)}
            />
            <Tab
              label={t('analytics:shared:feeding')}
              className="tab-section"
              {...a11yProps(1)}
            />
            <Tab
              label={t('analytics:shared:sleep')}
              className="tab-section"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
          <GrowthCurves
            trackerRecords={trackerRecords}
            currentUser={currentUser}
          />
        </CustomTabPanel>
        <DateProvider>
          <CustomTabPanel value={tab} index={1}>
            <FeedingAnalytics
              trackerRecords={trackerRecords}
              currentUser={currentUser}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={2}>
            <SleepAnalytics
              trackerRecords={trackerRecords}
              currentUser={currentUser}
            />
          </CustomTabPanel>
        </DateProvider>
      </Box>
    </div>
  );
};
export default AnalyticsPage;

interface TabPanelProps {
  children?: JSX.Element;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && { ...children }}
    </div>
  );
}
