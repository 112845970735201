import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '@mui/x-date-pickers';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { LANGUAGE_CODE_KEY } from '../../../utils/constants';
import { DatesContext } from './DateProvider';
import moment from 'moment';
interface IDatePicker {}

const DatePickerCalendar: React.FC<IDatePicker> = () => {
  const [dynamicZ, setDynamicZ] = useState<number>(1);
  const { startDate, endDate, setStartDate, setEndDate } =
    useContext(DatesContext);
  const { t } = useTranslation();
  const [language, setLanguage] = useState<string>();

  const isAndroidDevice = /(android)/i.test(navigator.userAgent);

  const isDateAllowed = (date) => {
    const momentDate = moment(date);
    const today = moment();

    if (startDate && endDate) {
      return momentDate.isBefore(today);
    } else if (startDate && !endDate) {
      const lastAllowedDate = moment(startDate).add(31, 'days');
      return (
        momentDate.isSameOrAfter(startDate) &&
        momentDate.isSameOrBefore(
          Math.min(today.valueOf(), lastAllowedDate.valueOf())
        )
      );
    }
    return false;
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const language = localStorage.getItem(LANGUAGE_CODE_KEY);
    setLanguage(language);
  }, []);

  return (
    <div id="calendar" style={{ zIndex: dynamicZ }}>
      <Stack
        direction={{ xs: 'row', sm: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <DatePicker
          locale={language}
          selected={startDate}
          onChange={onChange}
          onCalendarOpen={() => setDynamicZ(999)}
          onCalendarClose={() => setDynamicZ(1)}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          showPopperArrow={false}
          dateFormat="dd.MM.yyyy"
          popperPlacement={isAndroidDevice ? 'bottom' : 'top'}
          filterDate={isDateAllowed}
          customInput={
            <TextField
              fullWidth
              id="dateRange"
              style={{ background: 'white' }}
              label={t('analytics:sleepAnalytics:rangeDate')}
              type="text"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon sx={{ color: '#87ba3b' }} />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
      </Stack>
    </div>
  );
};

export default DatePickerCalendar;
