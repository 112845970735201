export interface TrackingButtonProps {
  tracker: string;
  selectedSide?: string;
  isActive: boolean;
  onClick?: (value: string) => void;
}

export interface LeftRightButtonProps {
  disabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
}

export const BUTTON_SIDES = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BOTH: 'BOTH',
};

export const BREASTS = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BOTH: 'BOTH',
};

export const TIME_OF_THE_DAY = {
  DAY: 'DAY',
  NIGHT: 'NIGHT',
  LULL: 'LULL',
} as const;

export const CRY_TYPES = {
  CRY_DAY: 'CRY_DAY',
  CRY_NIGHT: 'CRY_NIGHT',
};

export type timeOfTheDayType =
  typeof TIME_OF_THE_DAY[keyof typeof TIME_OF_THE_DAY];
